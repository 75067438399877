var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import { pushToDataLayer } from 'core/services/GTM';
import Http from 'core/services/HttpClient';
import { CHANGE_EVENT, buildEventName } from 'site/VControllers/Products/ProductVariants';
let ChangeableProductCard = class ChangeableProductCard extends Controller {
    constructor() {
        super(...arguments);
        this.responseStore = {};
        this.handleChange = async ({ detail }) => {
            this.el.classList.add('__loading');
            const { url, html, title, gtmPayload } = await this.getData(detail.id);
            document.title = title;
            requestAnimationFrame(() => {
                this.el.innerHTML = html;
                connect(this.el);
                this.el.scrollIntoView();
                this.el.classList.remove('__loading');
            });
            pushToDataLayer(gtmPayload);
            window.history.pushState(null, '', (new URL(url)).pathname);
        };
    }
    get nativeListeners() {
        return [
            { selector: 'document', event: buildEventName(CHANGE_EVENT, this.props.viewType), handler: this.handleChange }
        ];
    }
    async getData(id) {
        const cachedData = this.responseStore[id];
        if (cachedData)
            return cachedData;
        const { data } = await Http.get(`${window.location.origin}/products/${id}/show_variant.json`);
        this.responseStore[id] = data;
        return data;
    }
};
ChangeableProductCard = __decorate([
    register('ChangeableProductCard')
], ChangeableProductCard);
export { ChangeableProductCard };
