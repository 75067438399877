var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { register, Controller } from 'core/services/Controller';
import { getSocial } from 'core/services/ShareService';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.props = {
            target: 'blank',
            ...this.props
        };
        this.handleShare = () => {
            let social = getSocial(this.props.type, this.props);
            let openWindow = (...args) => window.open(social.shareUrl, ...args);
            if (social.openTarget === 'window')
                openWindow('displayWindow', 'width=700,height=400,left=200,top=100,location=no,directories=no,status=no,toolbar=no,menubar=no');
            else
                openWindow();
        };
    }
    init() {
        this.bindEvent('click', this.handleShare);
    }
};
default_1 = __decorate([
    register('BtnShare')
], default_1);
export default default_1;
