var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
import HttpRequestBatcher from 'core/services/HttpRequestBatcher';
import waitPageLoaded from 'core/utils/waitPageLoaded';
const URL_MAP = {
    Photo: (photoId, type) => `/front_api/photos/${photoId}/reactions/${type}`
};
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            await waitPageLoaded();
            const [{ ReactionsWidget }, { fetchReactions }] = await Promise.all([
                // @ts-ignore
                import(/* webpackChunkName: 'common__c-Reactions'*/ 'site/widgets/ReactionsWidget'),
                // @ts-ignore
                import(/* webpackChunkName: 'common__c-Reactions'*/ 'core/services/HttpRequestBatcher/batchers')
            ]);
            const { objectId, objectType } = this.props;
            const reactions = await HttpRequestBatcher.request(fetchReactions, { id: objectId, type: objectType });
            ReactionsWidget($(this.el), {
                props: {
                    reactions
                },
                callbacks: {
                    CALLBACK_REACT: (props) => {
                        return Http.post(URL_MAP[objectType](objectId, props.type));
                    }
                }
            });
        };
    }
};
default_1 = __decorate([
    register('Reactions')
], default_1);
export default default_1;
