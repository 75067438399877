import { Component } from 'preact';
import h from '../../cssScope';
export class AutoDeliveryDate extends Component {
    constructor() {
        super(...arguments);
        this.formatPhone = (phone) => {
            return phone ? phone.replace(/[^0-9]/g, '') : '';
        };
    }
    render() {
        const { orderId, autoDeliveryDate, inmyroomPhone } = this.context.store.getState();
        return (h("div", { className: 'sb-Page sb-ThankYou' },
            h("div", { className: 'sb-ThankYou_icon' }),
            h("p", { className: 'sb-ThankYou_mainInfo' },
                "\u0414\u043E\u0441\u0442\u0430\u0432\u043A\u0430 \u0437\u0430\u043A\u0430\u0437\u0430 \u2116",
                orderId,
                " \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0430 \u043D\u0430 ",
                h("strong", null, autoDeliveryDate),
                "."),
            h("p", { className: 'sb-ThankYou_additionalInfo' }, "\u0412 \u0434\u0435\u043D\u044C \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438 \u0432\u0430\u043C \u043F\u0440\u0438\u0434\u0435\u0442 \u0441\u043C\u0441 \u0441 \u043D\u043E\u043C\u0435\u0440\u043E\u043C \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430 \u043A\u0443\u0440\u044C\u0435\u0440\u0430 \u0438 \u043A\u043E\u0434\u043E\u043C \u0434\u043B\u044F \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0442\u043E\u0432\u0430\u0440\u043E\u0432."),
            h("p", { className: 'sb-ThankYou_additionalInfo' },
                "\u0415\u0441\u043B\u0438 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0434\u0430\u0442\u0443 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438, \u043F\u043E\u0437\u0432\u043E\u043D\u0438\u0442\u0435 \u043F\u043E \u043D\u043E\u043C\u0435\u0440\u0443 ",
                h("a", { href: `tel:${this.formatPhone(inmyroomPhone)}` }, inmyroomPhone),
                ".")));
    }
}
