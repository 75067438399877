import { h } from 'preact';
import { BaseSuggestions } from './BaseSuggestions';
import { HighlightWords } from './HighlightWords';
export class AddressSuggestions extends BaseSuggestions {
    constructor() {
        super(...arguments);
        this.loadSuggestionsUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
        this.getLoadSuggestionsData = () => {
            const { count, filterFromBound, filterToBound, filterLocations, filterLocationsBoost, filterLanguage, filterRestrictValue, settlementQuery } = this.props;
            let { query } = this.state;
            if (settlementQuery) {
                query = [settlementQuery, query].join(', ');
            }
            const requestPayload = {
                query,
                count: count || 10
            };
            if (filterFromBound && filterToBound) {
                requestPayload.from_bound = { value: filterFromBound };
                requestPayload.to_bound = { value: filterToBound };
            }
            if (filterLanguage) {
                requestPayload.language = filterLanguage;
            }
            if (filterLocations) {
                requestPayload.locations = filterLocations;
            }
            if (filterLocationsBoost) {
                requestPayload.locations_boost = filterLocationsBoost;
            }
            if (filterRestrictValue) {
                requestPayload.restrict_value = true;
            }
            return requestPayload;
        };
        this.getSuggestionText = (suggestion) => {
            if (!this.props.settlementQuery)
                return suggestion.value;
            const { streetWithType, houseType, house, blockType, block } = suggestion.data;
            const houseWithType = houseType ? `${houseType} ${house}` : null;
            const blockWithType = blockType ? `${blockType} ${block}` : null;
            const suggestionText = [streetWithType, houseWithType, blockWithType].filter(Boolean).join(', ');
            return suggestionText;
        };
        this.renderOption = (suggestion) => {
            const { renderOption, highlightClassName } = this.props;
            const { query } = this.state;
            return renderOption ? (renderOption(suggestion, query)) : (h(HighlightWords, { highlightClassName: highlightClassName || 'react-dadata--highlighted', words: this.getHighlightWords(), tagName: 'mark', text: this.getSuggestionText(suggestion) }));
        };
    }
}
