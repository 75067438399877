var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { VideoPlayer } from 'core/services/VideoPlayer';
import { VisibilityObserver } from 'core/services/VisibilityObserver';
var VideoState;
(function (VideoState) {
    VideoState[VideoState["Play"] = 0] = "Play";
    VideoState[VideoState["Pause"] = 1] = "Pause";
})(VideoState || (VideoState = {}));
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.videoState = VideoState.Pause;
        this.observer = new VisibilityObserver();
    }
    init() {
        this.observer.observe(this.el, {
            onVisible: () => this.playVideo(),
            onUnvisible: () => this.pauseVideo()
        });
    }
    destroy() {
        var _a;
        this.observer.unobserve(this.el);
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.destroy();
    }
    pauseVideo() {
        var _a;
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.pause();
        this.videoState = VideoState.Pause;
    }
    playVideo() {
        var _a;
        if (VideoState.Play)
            return;
        this.initPlayer();
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.play();
        this.videoState = VideoState.Play;
    }
    initPlayer() {
        if (!this.player)
            this.player = new VideoPlayer(this.props);
    }
};
default_1 = __decorate([
    register('AutoplayVideo')
], default_1);
export default default_1;
