export default function rAFThrottle(callback, threshold) {
    let ticked = true;
    let lastTimestamp = Date.now();
    return (...args) => {
        if (!ticked) {
            return;
        }
        if (threshold && Date.now() - lastTimestamp < threshold) {
            return;
        }
        window.requestAnimationFrame(() => {
            ticked = true;
            lastTimestamp = Date.now();
            callback(...args);
        });
        ticked = false;
    };
}
