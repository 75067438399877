import { Component, createRef } from 'preact';
import classNames from 'classnames';
import h from '../../cssScope';
import { DeliveryPeriod } from '../DeliveryPeriod';
import { COMMIT_SET_DATE, COMMIT_SET_PERIOD } from '../../store/commits/deliveryDate';
export class DeliveryDate extends Component {
    constructor() {
        super(...arguments);
        this.radioRef = createRef();
        this.handleDateClick = () => {
            var _a;
            const { periods, label } = this.props;
            const { chosenPeriod } = this.context.store.getState();
            const date = (_a = this.radioRef.current) === null || _a === void 0 ? void 0 : _a.value;
            this.context.store.commit(COMMIT_SET_DATE, { date, label });
            if (!chosenPeriod) {
                const firstPeriod = periods[0];
                const period = firstPeriod[0];
                const from = firstPeriod[1][0];
                const to = firstPeriod[1][1];
                this.context.store.commit(COMMIT_SET_PERIOD, { period, from, to });
            }
        };
    }
    render() {
        const { date, label, periods, isDateActive } = this.props;
        const { chosenPeriod } = this.context.store.getState();
        return (h("label", { className: classNames('sb-DeliveryDate ui-Input __radio', { '__active': isDateActive }), onClick: this.handleDateClick },
            h("input", { ref: this.radioRef, type: 'radio', value: date, name: 'deliveryDate', checked: isDateActive }),
            h("div", { className: 'ui-Input_indicator' }),
            h("span", { className: 'sb-DeliveryDate_text' }, label),
            h("div", { className: 'sb-DeliveryDate_periods' }, periods.map(([period, [from, to]]) => (h(DeliveryPeriod, { isPeriodActive: isDateActive && (chosenPeriod === null || chosenPeriod === void 0 ? void 0 : chosenPeriod.period) === period, period: period, from: from, to: to }))))));
    }
}
