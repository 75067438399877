import { h } from 'preact';
import { currencyFormat, parseCurrency, currencyFormatWithCaretPosition } from 'core/utils/number/format';
import { Text } from './Text';
const CURRENCY_SIGN_SIZE = 2;
export function CurrencyText(props) {
    let { min, max, placeholder, value, onChange = () => { } } = props;
    const isInRange = (value) => {
        return value > min && value < max;
    };
    const handleChange = (e) => {
        const target = e.currentTarget;
        onChange(e, parseCurrency(target.value));
    };
    const handleKeyDown = (e) => {
        const target = e.currentTarget;
        const isLeft = e.keyCode === 39;
        const isEnd = e.keyCode === 40;
        const isCurrencyRange = target.selectionEnd >= target.value.length - CURRENCY_SIGN_SIZE;
        if (isCurrencyRange && isLeft || isEnd) {
            e.preventDefault();
        }
    };
    const handleKeyUp = (e) => {
        const target = e.currentTarget;
        const value = currencyFormatWithCaretPosition(target);
        if (isInRange(value)) {
            onChange(e, value);
        }
    };
    const handleClick = (e) => {
        const target = e.currentTarget;
        const selection = target.selectionEnd >= target.value.length - CURRENCY_SIGN_SIZE
            ? target.value.length - CURRENCY_SIGN_SIZE
            : target.selectionEnd;
        target.selectionEnd = selection;
    };
    placeholder = placeholder && currencyFormat(placeholder);
    value = value && currencyFormat(value);
    return (h(Text, Object.assign({}, props, { type: 'text', value: value, placeholder: placeholder, onClick: handleClick, onKeyUp: handleKeyUp, onChange: handleChange, onKeyDown: handleKeyDown })));
}
