var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { LazyBlock } from 'core/services/LazyBlock';
import { LazyPhoto } from 'core/services/LazyPhoto';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            await LazyBlock.waitNative(this.el);
            await this.initSliderWidget();
            this.findElements('img').forEach(el => LazyPhoto.load(el));
        };
        this.destroy = () => {
            if (!this.wSlider)
                return;
            this.destroySliderWidget();
        };
        this.initSliderWidget = async () => {
            const { SliderWidget } = await import(
            /* webpackChunkName: 'common__w-Slider2' */
            'site/widgets/SliderWidget');
            this.wSlider = SliderWidget($(this.el), {
                slidesToShow: 8,
                arrows: true
            });
            this.wSlider.init();
        };
        this.destroySliderWidget = () => {
            var _a;
            (_a = this.wSlider) === null || _a === void 0 ? void 0 : _a.destroy();
            this.wSlider = null;
        };
    }
};
default_1 = __decorate([
    register('BrandSlider')
], default_1);
export default default_1;
