var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
const TOGGLE_BUTTON = '%{HeaderAllCategories/toggler}';
const HOVER_ITEM_EL = 'HeaderAllCategories/item';
const HOVER_ITEM = `%{${HOVER_ITEM_EL}}`;
const OPEN_CONTAINER_MOD = '__hasOverlay';
const OPEN_BODY_MOD = 'has-overlay';
const OPEN_CATALOG_MOD = '__open';
const CONTENT_CLASS_NAME = 's-Header-Subnav_b-ChildCategories';
const ACTIVE_MOD = '__active';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.open = false;
        this.handleToggle = () => {
            this.open ? this.handleClose() : this.handleOpen();
        };
        this.handleOpen = () => {
            if (this.open)
                return;
            requestAnimationFrame(() => {
                document.body.classList.add(OPEN_BODY_MOD);
                this.container.classList.add(OPEN_CONTAINER_MOD);
                this.pageWrapper.classList.add(OPEN_CATALOG_MOD);
                this.el.classList.add(OPEN_CATALOG_MOD);
            });
            this.open = true;
        };
        this.handleClose = () => {
            if (!this.open)
                return;
            requestAnimationFrame(() => {
                document.body.classList.remove(OPEN_BODY_MOD);
                this.container.classList.remove(OPEN_CONTAINER_MOD);
                this.pageWrapper.classList.remove(OPEN_CATALOG_MOD);
                this.el.classList.remove(OPEN_CATALOG_MOD);
            });
            this.open = false;
        };
        this.handleHover = (e) => {
            const el = e.target;
            this.setActiveCategory(parseInt(el.dataset.id), el);
        };
    }
    init() {
        this.container = document.getElementsByClassName('s-Header-Subnav_b-Page')[0];
        this.pageWrapper = document.getElementsByClassName('s-Header-Subnav')[0];
        this
            .bindEvent(`click on ${TOGGLE_BUTTON}`, this.handleToggle)
            .bindEvent(`click on document`, this.handleClose)
            .bindEvent(`mouseenter on ${HOVER_ITEM}`, this.handleHover)
            .on('click', (e) => e.stopPropagation());
        this.setActiveCategory(this.props.activeId, document.querySelector(`[data-id="${this.props.activeId}"]`));
    }
    setActiveCategory(categoryId, el) {
        var _a, _b;
        if (this.activeCategoryId === categoryId)
            return;
        this.activeCategoryId = categoryId;
        this.findElements(`%{${HOVER_ITEM_EL}}.${ACTIVE_MOD}`, document).forEach(el => el.classList.remove(ACTIVE_MOD));
        el.classList.add(ACTIVE_MOD);
        document.querySelectorAll(`.${CONTENT_CLASS_NAME}.${ACTIVE_MOD}`).forEach(el => el.classList.remove(ACTIVE_MOD));
        (_b = (_a = document.querySelector(`.${CONTENT_CLASS_NAME}[data-category-id="${categoryId}"]`)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add(ACTIVE_MOD);
    }
};
default_1 = __decorate([
    register('HeaderAllCategories')
], default_1);
export default default_1;
