var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import Store from 'core/services/Store';
import { COMMIT_ADD } from './commits/add';
import { COMMIT_DELETE } from './commits/delete';
import { COMMIT_CHANGE } from './commits/change';
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.store = Store({
            props: {
                initialState: {
                    fields: this.props.fields
                }
            },
            callbacks: {
                CALLBACK_AFTER_COMMIT: () => this.renderer.render()
            }
        });
        this.handleAdd = () => this.store.commit(COMMIT_ADD);
        this.handleDelete = (index) => () => this.store.commit(COMMIT_DELETE, index);
        this.handleChange = (fieldName, index) => (e) => this.store.commit(COMMIT_CHANGE, {
            value: e.currentTarget.value,
            fieldName,
            index
        });
    }
    get years() {
        if (this._years)
            return this._years;
        let currentYear = (new Date()).getFullYear();
        return this._years = [...Array(20).keys()].map(val => currentYear - val);
    }
    render() {
        let { fields } = this.store.getState();
        return (h("div", { class: 'pb-education' },
            h("div", null, fields.map(({ name, year }, index) => (h("div", { class: 'pb-education_fields g-Row', key: index },
                h("div", { class: 'g-Col__6' },
                    h("div", { className: 'ui-Input __text __labelFloated' },
                        h("label", { className: 'ui-Input_label' }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435"),
                        h("input", { onChange: this.handleChange('name', index), name: `user[${this.props.fieldName}][][name]`, class: 'ui-Input_input', value: name }))),
                h("div", { class: 'g-Col__6' },
                    h("div", { className: 'ui-Input __select __text __labelFloated pb-education_year' },
                        h("label", { className: 'ui-Input_label' }, "\u0413\u043E\u0434 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F"),
                        h("select", { onChange: this.handleChange('year', index), name: `user[${this.props.fieldName}][][year]`, class: 'ui-Input_input', value: year }, this.years.map(y => (h("option", { selected: y === year }, y)))),
                        h("div", { class: 'pb-education_delete', onClick: this.handleDelete(index) }))))))),
            h("div", { class: 'g-Row' },
                h("div", { className: 'g-Col__12' },
                    h("div", { class: 'pb-education_add', onClick: this.handleAdd }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")))));
    }
};
default_1 = __decorate([
    register('ProfiEducationField')
], default_1);
export default default_1;
