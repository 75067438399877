import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import { FormNoticeService } from 'core/services/FormNoticeService';
import Tooltip from 'core/components/UI/Tooltip';
export class Select extends Component {
    render() {
        const { options, label, class: _class, className, required = false, notice, tooltip, ...selectProps } = this.props;
        return (h("div", { className: htmlClassNames('ui-Input', '__select', '__text', '__labelFloated', className, { [`__${notice === null || notice === void 0 ? void 0 : notice.type}`]: !!notice }) },
            notice && (h("div", { className: 'ui-Input_notice' }, notice.message)),
            label && (h("label", { className: 'ui-Input_label' },
                label,
                required && (h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*")))),
            h("select", Object.assign({}, selectProps, { required: required, className: 'ui-Input_input' }), options.map((option) => (h("option", { value: option.value }, option.label)))),
            tooltip && (h("div", { className: 'ui-Input_tooltip' },
                "?",
                h(Tooltip, { label: tooltip })))));
    }
}
export const buildError = (field) => {
    const { errors } = FormNoticeService.notices;
    return errors[field] ? {
        type: 'error',
        message: errors[field].join(', ')
    } : undefined;
};
