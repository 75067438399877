import { h, Component } from 'preact';
import Category from './Category';
import { COMMIT_TOGGLE_CATEGORY } from './commits/togglService';
export default class extends Component {
    constructor() {
        super(...arguments);
        this.handleCheck = (categoryId) => () => {
            this.context.store.commit(COMMIT_TOGGLE_CATEGORY, categoryId);
        };
    }
    render() {
        return (h("div", { className: 'b-ProfiCategoriesForm_block __categories pb-Group' },
            h("div", { className: 'pb-Group_title' }, "\u0423\u0441\u043B\u0443\u0433\u0438"),
            h("div", { className: 'b-ProfiCategoriesForm_categories' },
                h("div", { className: 'g-Row b-ProfiCategoriesForm_header' },
                    h("div", { className: 'g-Col__6' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0438"),
                    h("div", { className: 'g-Col__6' }, "\u0426\u0435\u043D\u0430 \u0437\u0430 \u0443\u0441\u043B\u0443\u0433\u0443")),
                this.props.categories.map(category => (h(Category, Object.assign({}, category, { key: category.id, onCheck: this.handleCheck(category.id) })))))));
    }
}
