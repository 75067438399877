import { eventEmitter } from 'site/VControllers/AuthModal';
if (!Env.isLogged) {
    document.addEventListener('click', (e) => {
        const target = e.target;
        const closestTarget = target === null || target === void 0 ? void 0 : target.closest('[data-needs-auth]');
        if (!closestTarget || closestTarget.dataset.needsAuth === undefined)
            return;
        e.preventDefault();
        e.stopPropagation();
        openAuthModal();
    });
}
const openAuthModal = () => {
    eventEmitter.emit('open');
};
export const needsAuth = (cb) => (...args) => {
    return Env.isLogged ? cb(...args) : openAuthModal();
};
