export const parseSelector = (selector) => selector
    .replace(/%{([^}]+)}/g, '[data-el~="$1"]');
// Replacing jQuery method
// http://youmightnotneedjquery.com/#index
export const index = (indexingEl) => {
    // if (!indexingEl) return -1
    let el = indexingEl;
    let i = -1;
    while (el) {
        el = el.previousElementSibling;
        i++;
    }
    return i;
};
