var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import EventEmitter from 'eventemitter3';
import { VController, register } from 'core/services/Controller';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { createVDOMRenderer } from 'core/services/VDOM';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
export const eventEmitter = new EventEmitter();
const AsyncModal = asyncComponent(() => import(/* webpackChunkName: "site_c-RegistrationModal" */ './components/AuthModalWrap'), 'AuthModalWrap');
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.isOpened = false;
        this.handleOpen = () => {
            if (this.isOpened)
                return;
            this.isOpened = true;
            this.open();
        };
        this.handleClose = () => {
            this.isOpened = false;
        };
        this.renderer = createVDOMRenderer(this.el, () => {
            const { isOpened, ...props } = this.props;
            return (h(AsyncModal, Object.assign({}, props, { isOpened: this.isOpened, onClose: this.handleClose })));
        });
    }
    init() {
        this.isOpened = this.props.isOpened;
        if (this.isOpened)
            this.open();
        eventEmitter.on('open', this.handleOpen);
    }
    open() {
        loadComponentsStyles('Auth').finally(() => this.renderer.render());
    }
    destroy() {
        eventEmitter.off('open', this.handleOpen);
        this.renderer.destroy();
    }
};
default_1 = __decorate([
    register('AuthModal')
], default_1);
export default default_1;
