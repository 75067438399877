import { Component } from 'preact';

export function createVDOMComponent(componentFn) {
  function preactComponent() {
    Component.apply(this, arguments);

    this.constructor.displayName = componentFn.name;

    let refs = {};

    let self = {
      setState:    this.setState.bind(this),
      forceUpdate: this.forceUpdate.bind(this),
      getContext:  () => this.context,
      getDOMNode:  () => this.base,
      getProps:    () => this.props,
      getState:    () => this.state,
      setRef:      name => el => refs[name] = el,
      getRef:      name => refs[name]
    }

    let {
      render,
      getInitialState,
      getChildContext,
      componentWillMount,
      componentDidMount,
      shouldComponentUpdate,
      componentWillReceiveProps,
      componentWillUpdate,
      componentDidUpdate,
      componentWillUnmount,
    } = componentFn(self);

    this.render                    = render;
    this.getChildContext           = getChildContext;
    this.componentWillMount        = componentWillMount;
    this.componentDidMount         = componentDidMount;
    this.shouldComponentUpdate     = shouldComponentUpdate;
    this.componentWillUpdate       = componentWillUpdate;
    this.componentDidUpdate        = componentDidUpdate;
    this.componentWillUnmount      = componentWillUnmount;
    this.componentWillReceiveProps = componentWillReceiveProps;

    if (getInitialState) {
      this.state = getInitialState();
    }
  }

  preactComponent.prototype = Object.create(Component.prototype);
  preactComponent.prototype.constructor = preactComponent;

  return preactComponent;
}
