var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleClick = async (e) => {
            e.preventDefault();
            let { RequestInfoModal } = await import(
            /* webpackChunkName: 'common__m-RequestInfo'*/
            //@ts-ignore
            'site/modals/RequestInfoModal');
            let modal = RequestInfoModal({
                callbacks: {
                    CALLBACK_SUBMIT: async ({ firstName, question, email, phone }) => {
                        await Http.post('/products/request_info', {
                            data: {
                                info_request: {
                                    first_name: firstName,
                                    question,
                                    email,
                                    phone,
                                    referer: window.location.href
                                }
                            }
                        });
                        $(this.el).trigger('BtnRequestInfo:sended', {
                            name: firstName,
                            email,
                            phone
                        });
                        modal.destroy();
                    },
                    CALLBACK_CANCEL: () => {
                        modal.destroy();
                    }
                }
            });
        };
    }
    init() {
        this.bindEvent('click', this.handleClick);
    }
};
default_1 = __decorate([
    register('BtnRequestInfo')
], default_1);
export default default_1;
