import { COMMIT_CHANGE_LOADING } from './changeLoading';
import { COMMIT_REFRESH_CHECKOUT } from './refreshCheckout';
export function COMMIT_REMOVE_PROMOCODE(state) {
    return { ...state, promocode: null };
}
export function COMMIT_ADD_PROMOCODE(state, promocode, data) {
    return { ...COMMIT_REFRESH_CHECKOUT(COMMIT_CHANGE_LOADING(state, false), data), promocode };
}
export function COMMIT_MANUAL_REMOVE_PROMOCODE(state, data) {
    return COMMIT_REMOVE_PROMOCODE(COMMIT_REFRESH_CHECKOUT(COMMIT_CHANGE_LOADING(state, false), data));
}
