var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h, render } from 'preact';
import { VController, register } from 'core/services/Controller';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
import { scopeName } from './cssScope';
import { showProductCart } from 'site/VControllers/SinglePageCheckout/v2/FrontInit';
import { OPERATION_CHANGE_ITEM_QUANTITY, OPERATION_REMOVE_ITEM } from 'site/VControllers/components/Checkout/operations/cartItems';
let InstallmentBuy = class InstallmentBuy extends VController {
    constructor() {
        super(...arguments);
        this.modalRoot = document.getElementById('modal-root');
        this.quantity = 1;
        this.handleInitCheckoutContext = async () => {
            const { default: checkoutContext } = await import(
            /* webpackChunkName: 'common__c-checkoutContext' */
            'site/VControllers/components/Checkout/context');
            this.checkoutContext = checkoutContext;
            await this.checkoutContext.init();
            this.checkoutContextUuid = await this.checkoutContext.subscribe(this.handleCheckoutContextUpdate);
            let quantity = this.product && this.product.quantity !== 0 ? this.product.quantity : this.quantity;
            this.updateQuantityFromString(quantity);
        };
        this.handleCheckoutContextUpdate = (state) => {
            const product = state.items.find(({ productId }) => productId === Number(this.props.product.id));
            if (product) {
                this.updateQuantityFromString(product.quantity);
                return;
            }
            this.updateQuantityFromString(this.quantity);
        };
        this.handleSelectQuantityChange = (e) => {
            const target = e.target;
            this.updateQuantityFromString(Number(target.value));
        };
        this.renderModal = async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await loadComponentsStyles(scopeName);
            const { Modal } = await import(
            /* webpackChunkName: "site_c-InstallmentBuy" */
            './components/Modal');
            this.prevModalEl = render((h(Modal, { product: this.props.product, hintText: this.hintText, checkoutContext: this.checkoutContext, onClose: this.handleClose })), this.modalRoot);
        };
        this.handleChangeQuantity = ({ quantity }) => {
            const checkoutContextStore = this.checkoutContext.getContext().store;
            checkoutContextStore.operate(OPERATION_CHANGE_ITEM_QUANTITY, { productId: this.props.product.id, quantity });
        };
        this.handleRemove = () => {
            const checkoutContextStore = this.checkoutContext.getContext().store;
            checkoutContextStore.operate(OPERATION_REMOVE_ITEM, { productId: this.props.product.id });
        };
        this.handleClose = async () => {
            if (Env.version === 'desktop') {
                this.destroyModal();
                return;
            }
            const { App: ProductAddedToCartModalV2 } = await import(
            /* webpackChunkName: 'common__c-AddToCart' */
            'site/modals/ProductAddedToCartModal/v2/App');
            await loadComponentsStyles('ProductAddedToCartModalV2');
            this.prevModalEl = render((h(ProductAddedToCartModalV2, { checkoutContext: this.checkoutContext, productId: Number(this.props.product.id), onCancel: this.destroyModal, onApply: showProductCart, onRemove: this.handleRemove, onChangeQuantity: this.handleChangeQuantity })), this.modalRoot, this.prevModalEl);
        };
        this.destroyModal = () => {
            render(null, this.modalRoot, this.prevModalEl);
            document.body.classList.remove('has-modal-open');
        };
    }
    get hintText() {
        const { price } = this.props;
        const currentPrice = Math.round(price * this.quantity);
        return `Рассрочка Т-Банк за ${currentPrice} ₽ в месяц`;
    }
    get product() {
        return this.checkoutContext
            .getContext().store
            .getState().items
            .find(({ productId }) => productId === Number(this.props.product.id));
    }
    async init() {
        const selectQuantityContainer = document.querySelector(`[data-el="Product/${this.props.product.id}/selectQuantity"]`);
        if (selectQuantityContainer) {
            const select = selectQuantityContainer.querySelector('select');
            if (select) {
                this.updateQuantityFromString(Number(select.value));
                select.addEventListener('change', this.handleSelectQuantityChange);
            }
        }
        await this.handleInitCheckoutContext();
        this.el.addEventListener('click', this.renderModal);
    }
    destroy() {
        this.destroyModal();
        this.el.removeEventListener('click', this.renderModal);
        this.checkoutContext.unsubscribe(this.checkoutContextUuid);
    }
    updateQuantityFromString(value) {
        this.quantity = value;
        this.updateHintText();
    }
    updateHintText() {
        this.el.innerText = this.hintText;
    }
};
InstallmentBuy = __decorate([
    register(scopeName)
], InstallmentBuy);
export default InstallmentBuy;
