let scriptLoaded = false;
export class YoutubePlayer {
    constructor(props) {
        this.props = props;
        this.playCalled = false;
        this.isReady = false;
        this.loadScript();
        document.addEventListener('YouTubeIframeAPIReady', () => this.handleYoutubeLoaded());
    }
    play() {
        var _a;
        if (!this.isReady) {
            this.playCalled = true;
            return;
        }
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.playVideo();
    }
    pause() {
        var _a;
        if (!this.isReady)
            return;
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.pauseVideo();
    }
    destroy() {
        document.removeEventListener('YouTubeIframeAPIReady', this.handleYoutubeLoaded);
        this.player = null;
    }
    loadScript() {
        if (scriptLoaded)
            return;
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        if (firstScriptTag.parentNode) {
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            scriptLoaded = true;
        }
    }
    handleYoutubeLoaded() {
        this.player = new YT.Player(this.props.videoId, {
            events: {
                onReady: event => this.handlePlayerReady(event)
            }
        });
    }
    handlePlayerReady(event) {
        this.isReady = true;
        if (this.playCalled)
            event.target.playVideo();
    }
}
