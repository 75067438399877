let KeyboardService = (() => {
  let __pressed     = [];
  let __subscribers = [];

  let onKeyDown = e => {
    if (e.repeat) return;

    __pressed = [...__pressed, e.key];

    publish();
  }

  let onKeyUp = e => {
    __pressed = __pressed.filter(key => e.key !== key);
  }

  let publish = () => {
    __subscribers
      .filter(({ keys }) => (
        keys.every(key => __pressed.includes(key))
      ))
      .forEach(({ callback }) => callback());
  }

  let subscribe = (keys, callback) => {
    __subscribers = [...__subscribers, { keys, callback }];

    bindEvents();
  }

  let unsubscribe = callback => {
    __subscribers = __subscribers.filter(({ callback: subscriber }) => subscriber !== callback);

    if (__subscribers.length === 0) {
      unbindEvents();
    }
  }

  let bindEvents = () => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup',   onKeyUp);
  }

  let unbindEvents = () => {
    window.removeEventListener('keydown', onKeyDown);
    window.removeEventListener('keyup',   onKeyUp);
  }

  return { subscribe, unsubscribe };
})();

export default KeyboardService;
