var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import WayPointService from 'core/services/WayPointService';
import { ScrollDirectionWatcher } from 'core/services/ScrollDirectionWatcher';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
let default_1 = class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.watcherIds = null;
        this.$relativeEl = $(this.props.relativeEl);
        this.setVisibility = (visibility) => requestAnimationFrame(() => {
            this.el.classList.toggle('__isHidden', !visibility);
            this.el.classList.toggle('__isVisible', visibility);
        });
        this.handleClose = (e) => {
            e.preventDefault();
            this.setVisibility(false);
            WayPointService.unwatch(this.watcherIds);
            $.cookie('isPopupBannerHidden', true, { expires: 2 });
        };
        this.connectWayPointWatcher = () => {
            this.watcherIds = WayPointService.watch([
                {
                    getPoint: () => {
                        let { top = 0 } = this.$relativeEl.offset();
                        return top;
                    },
                    onDown: () => this.setVisibility(true),
                    onUp: () => this.setVisibility(false)
                }
            ]);
        };
        this.disconnectWayPointWatcher = () => {
            WayPointService.unwatch(this.watcherIds);
        };
        this.initScrollWatcher = () => {
            this._scrollWatcher = new ScrollDirectionWatcher({
                onScrollUp: this.handleUp,
                onScrollDown: this.handleDown
            });
        };
        this.handleUp = () => {
            this.el.classList.add('__headerStickyActive');
        };
        this.handleDown = () => {
            this.el.classList.remove('__headerStickyActive');
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{PopupBanner/close}', event: 'click', handler: this.handleClose }
        ];
    }
    async init() {
        this.addNativeListeners();
        await loadComponentsStyles('PopupBanner');
        this.connectWayPointWatcher();
        this.initScrollWatcher();
    }
    destroy() {
        this.disconnectWayPointWatcher();
        if (this._scrollWatcher) {
            this._scrollWatcher.destroy();
        }
    }
};
default_1.shouldInit = (el) => {
    const isActive = !$.cookie('isPopupBannerHidden');
    if (!isActive)
        el.style.display = 'none';
    return isActive;
};
default_1 = __decorate([
    register('PopupBanner')
], default_1);
export default default_1;
