import { asyncLoadStyles } from 'core/utils/asyncLoadAssets'

$(document).on('click', '[data-photo-link]', async function(e) {
  e.preventDefault()

  const { PhotoModal, PhotoListCursor } = await import(
    /* webpackChunkName: 'common__s-PhotoModal'*/
    'core/services/PhotoModal'
  )

  const stylesBundleUrl = e.target.dataset.bundleUrl
  if (stylesBundleUrl) await asyncLoadStyles(stylesBundleUrl)

  if (e.metaKey || e.ctrlKey) return // support ctrl + click

  const photoId = $(this).data('photoLink')

  PhotoModal
    .open(new PhotoListCursor({ list: () => [photoId] })
      .moveTo(photoId))
})
