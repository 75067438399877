export default function initYmapScript(yandexMapApiKey, onInit) {
    if (document.querySelector('#ymaps-script')) {
        onInit();
        return;
    }
    const script = document.createElement('script');
    script.id = 'ymaps-script';
    script.src = `https://api-maps.yandex.ru/2.1?apikey=${yandexMapApiKey}&load=package.full&lang=ru_RU`;
    script.onload = onInit;
    document.head.appendChild(script);
}
