var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import delay from 'core/utils/delay';
import scrollWindowTo from 'core/utils/scrollWindowTo';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.photoList = null;
        this.init = async () => {
            var _a;
            await this.photoListServiceInit();
            (_a = this.photoList) === null || _a === void 0 ? void 0 : _a.connect();
        };
        this.destroy = () => {
            var _a;
            (_a = this.photoList) === null || _a === void 0 ? void 0 : _a.disconnect();
            this.photoList = null;
        };
        this.photoListServiceInit = async () => {
            const { PhotoList, UrlPlugin, ItemsPlugin, FiltersPlugin, LoadMorePlugin } = await import(
            /* webpackChunkName: 'common__c-PhotoList' */
            // @ts-ignore
            'core/services/InfinityList/lists/PhotoList');
            const cols = this.findElements('%{c-PhotoList/items} [data-col]');
            this.photoList = PhotoList({
                loadUrl: this.props.loadUrl,
                initialState: {
                    url: document.location.href,
                    items: this.props.items,
                    filters: this.props.filters,
                    loadMore: this.props.loadMore,
                    scrollId: this.props.scrollId
                },
                plugins: [
                    UrlPlugin(),
                    ItemsPlugin({
                        getSlotsForItems(items) {
                            return items.map((items, i) => ({ $slot: $(cols).eq(i), items }));
                        }
                    }),
                    LoadMorePlugin({
                        $slot: $(this.el)
                    }),
                    FiltersPlugin({
                        filters: $(this.el).find('[data-filter]').get().map((el) => ({
                            ...$(el).data('filter'), $slot: $(el)
                        }))
                    })
                ],
                callbacks: {
                    onScrollToTop: () => {
                        const point = $(this.el).offset().top;
                        return scrollWindowTo(point, 800).then(() => delay(300));
                    },
                    onBeforeLoad: () => {
                        this.el.classList.add('is-loading');
                    },
                    onAfterLoad: ({ meta: { titleText, countText } }) => {
                        this.el.classList.remove('is-loading');
                        const photoListTitle = this.findElement('%{c-PhotoList/title}');
                        if (photoListTitle) {
                            photoListTitle.textContent = titleText;
                        }
                        const photoListCount = this.findElement('%{c-PhotoList/count}');
                        if (photoListCount) {
                            photoListCount.textContent = countText;
                        }
                    }
                }
            });
        };
    }
};
default_1 = __decorate([
    register('PhotoList')
], default_1);
export default default_1;
