export let openTarget = 'window';
export function shareUrl({ url, title, image, description }) {
    let params = {
        redirect_uri: `${document.location.origin}/autoclose`,
        app_id: Env.social.FB,
        display: 'popup',
        link: url,
        // Conditional object properties
        ...description && ({ description }),
        ...title && ({ title }),
        ...image && ({ image })
    };
    return `https://www.facebook.com/dialog/feed?${$.param(params)}`;
}
