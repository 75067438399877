import Http from 'core/services/Http';

export async function fetchProductsAd(requests) {
  let photoIds = requests.map(({ photoId }) => photoId)

  let result = await Http.post('/front_api/posts/products_ad.json', {
    data: { photo_ids: photoIds }
  });

  return request => result[request.photoId];
}
