import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import { isVideo, isImage } from '../../utils/fileHelpers';
import { truncateFilename } from 'core/utils/truncateFilename';
export class FilePreview extends Component {
    constructor() {
        super(...arguments);
        this.handleRemove = () => {
            const { file, onRemove, disabled = false } = this.props;
            if (!disabled)
                return onRemove === null || onRemove === void 0 ? void 0 : onRemove(file.id);
        };
        this.buildPreview = () => {
            const { file } = this.props;
            const isVideo = this.isVideo;
            const isImage = this.isImage;
            if (isVideo) {
                return h("video", { src: file.src });
            }
            else if (isImage) {
                return h("img", { src: file.src, alt: file.file.name });
            }
            else
                return h("label", null, truncateFilename(file.file.name));
        };
    }
    get isVideo() {
        return isVideo(this.props.file.file);
    }
    get isImage() {
        return isImage(this.props.file.file);
    }
    get getClasses() {
        const { loading = false } = this.props;
        const isVideo = this.isVideo;
        const isImage = this.isImage;
        const isDefautl = !isVideo && !isImage;
        return htmlClassNames('b-FileUploader_filePreview', { '__video': isVideo, '__default': isDefautl, '__loading': loading });
    }
    render() {
        return (h("div", { className: this.getClasses, title: this.props.file.file.name, onClick: this.handleRemove() }, this.buildPreview()));
    }
}
