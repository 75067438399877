/*** IMPORTS FROM imports-loader ***/
(function() {
var define = false;
var require = false;
var module = false;
var exports = false;

(function($) {
  function rewriteSelector(context, name, pos) {
    var original = context[name];

    if (!original) return;

    context[name] = function() {
      arguments[pos] = arguments[pos]
        .replace(/%\{([^\}]+)\}/g, '[data-el~="$1"]');

      return original.apply(context, arguments);
    };

    $.extend(context[name], original);
  };

  rewriteSelector($, 'find', 0);
  rewriteSelector($, 'multiFilter', 0);
  rewriteSelector($.find, 'matchesSelector', 1);
  rewriteSelector($.find, 'matches', 0);
})(jQuery);

}.call(window));