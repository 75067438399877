import { h, Component } from 'preact';
import { SuggestedProductModal } from 'site/modals/SuggestedProductModal';
import { showProductCart } from 'site/VControllers/SinglePageCheckout/v2/FrontInit';
export class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            originalProductAttrs: null,
            notSaleProductAttrs: null,
            isModalOpen: false
        };
        this.componentDidMount = async () => {
            document.addEventListener('showSuggestedProductModal', this.handleOpenSuggestedProductModal);
        };
        this.componentWillUnmount = () => {
            document.removeEventListener('showSuggestedProductModal', this.handleOpenSuggestedProductModal);
        };
        this.handleOpenSuggestedProductModal = (e) => {
            if (!e.detail.modal)
                return;
            const { modal: { originalProductAttrs, notSaleProductAttrs } } = e.detail;
            this.setState({ isModalOpen: true, originalProductAttrs, notSaleProductAttrs });
        };
        this.handleCloseSuggestedProductModal = () => {
            this.setState({ isModalOpen: false });
        };
    }
    render() {
        const { originalProductAttrs, notSaleProductAttrs, isModalOpen } = this.state;
        return (h("div", null, isModalOpen && (h(SuggestedProductModal, { originalProduct: originalProductAttrs, suggestedProduct: notSaleProductAttrs, onCancel: this.handleCloseSuggestedProductModal, onApply: showProductCart }))));
    }
}
