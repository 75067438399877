var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h, render } from 'preact';
import { Controller, register } from 'core/services/Controller';
import NoticeService from 'core/services/NoticeService';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
const App = asyncComponent(() => import(/* webpackChunkName: "site_c-AddProductReview" */ './App'), 'App');
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.parentContainer = this.findClosest('.s-ProductCard_b-Reviews');
        this.renderContainer = this.findElement('%{c-BtnAddProductReviewV2/newReviewContainer}', this.parentContainer);
        this.isActive = false;
        this.handleCreateReview = () => {
            if (this.props.orderReview.isProductReviewed) {
                NoticeService.error('Вы уже оставляли отзыв на товар');
                return;
            }
            if (this.isActive) {
                this.handleCloseReview();
                return;
            }
            this.reviewComponent = render((h(App, { productReview: this.props.productReview, onClose: this.handleCloseReview, contentTypes: this.props.contentTypes })), this.renderContainer);
            this.renderContainer.append(this.reviewComponent);
            this.isActive = true;
        };
        this.handleCloseReview = () => {
            this.renderContainer.innerHTML = '';
            this.isActive = false;
        };
    }
    get nativeListeners() {
        return [
            { event: 'click', handler: this.handleCreateReview }
        ];
    }
};
default_1 = __decorate([
    register('BtnAddProductReviewV2')
], default_1);
export default default_1;
