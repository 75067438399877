import isTouchDevice from 'core/utils/features/isTouchDevice'

if (!window.atob) {
  document.location.href = '/outdatebrowser'

  throw new Error('outdatedbrowser')
}

if (Env.name === 'development') {
  let page = document.body.className.match(/p-([^\s]+)/)

  if (page) console.warn('Page: ' + page[0])
}

if (isTouchDevice()) document.documentElement.classList.add('touch-device')
else document.documentElement.classList.add('no-touch-device')
