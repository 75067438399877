var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import { documentDispatch } from 'core/utils/events';
import rAFThrottle from 'core/utils/rAFThrottle';
let isLoading = false;
const loadMore = (url) => {
    return $.ajax({ url }).then(data => {
        const $data = $(data);
        let $content = $data.find('#main').children();
        if ($content.length === 0) {
            $content = $content.end().end().children();
        }
        isLoading = false;
        if ($('#infinite-scroll').length === 0) {
            history.pushState({}, '', url);
        }
        return $content;
    });
};
let LegacyInfinityScroll = class LegacyInfinityScroll extends Controller {
    constructor() {
        super(...arguments);
        this.$index = $('#index');
        this.point = 0;
        this.init = () => {
            this.update();
            document.addEventListener('loadMore.loaded', this.update);
            window.addEventListener('scroll', this.handleScroll, { passive: true });
        };
        this.destroy = () => {
            document.removeEventListener('loadMore.loaded', this.update);
            window.removeEventListener('scroll', this.handleScroll);
        };
        this.handleScroll = rAFThrottle(() => {
            if (window.pageYOffset > this.point && !isLoading) {
                isLoading = true;
                $('#load-more').trigger('click');
            }
        }, 120);
        this.update = () => {
            this.$index = $('#index');
            this.point = this.$index.offset().top + this.$index.outerHeight() - (3 * window.innerHeight);
        };
    }
};
LegacyInfinityScroll = __decorate([
    register('LegacyInfinityScroll')
], LegacyInfinityScroll);
export { LegacyInfinityScroll };
let LegacyLoadMore = class LegacyLoadMore extends Controller {
    constructor() {
        super(...arguments);
        this.handleLoadMore = () => {
            this.el.classList.add('is-loading');
            const url = $(this.el).next('.b-Pagination').find('.__isCurrent').next().find('a').attr('href');
            loadMore(url).then($content => {
                const $index = $content.find('#index').children();
                $('#index').append($index);
                $('#paginator').replaceWith($content.find('#paginator'));
                connect();
                documentDispatch('loadMore.loaded');
            });
        };
    }
    get nativeListeners() {
        return [
            { event: 'click', handler: this.handleLoadMore }
        ];
    }
};
LegacyLoadMore = __decorate([
    register('LegacyLoadMore')
], LegacyLoadMore);
export { LegacyLoadMore };
