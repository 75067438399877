export class VisibilityObserver {
    constructor() {
        this.targets = new Map();
        this.observe = (target, { onVisible, onUnvisible }) => {
            this.observer.observe(target);
            this.targets.set(target, {
                onVisible,
                onUnvisible
            });
        };
        this.unobserve = (target) => {
            this.observer.unobserve(target);
            this.targets.delete(target);
        };
        this.handleVisible = (target) => {
            var _a;
            (_a = this.targets.get(target)) === null || _a === void 0 ? void 0 : _a.onVisible();
        };
        this.handleUnvisible = (target) => {
            var _a, _b;
            (_b = (_a = this.targets.get(target)) === null || _a === void 0 ? void 0 : _a.onUnvisible) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
        this.processEntries = (entries) => {
            entries.forEach(entry => {
                entry.isIntersecting
                    ? this.handleVisible(entry.target)
                    : this.handleUnvisible(entry.target);
            });
        };
        this.observer = new IntersectionObserver(this.processEntries);
    }
}
