var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.onDestroy = (e) => {
            e.preventDefault();
            Http
                .delete(`/front_api/my/favorite_collections/${this.props.id}`)
                .then(() => this.el.remove());
        };
        this.onEdit = async (e) => {
            e.preventDefault();
            const [collection, { EditFavCollectionModal }] = await Promise.all([
                Http.get(`/front_api/my/favorite_collections/${this.props.id}/edit.json`),
                // @ts-ignore
                import(/* webpackChunkName: 'common__m-EditFavCollection' */ 'site/modals/EditFavCollectionModal')
            ]);
            EditFavCollectionModal({
                props: {
                    title: collection.title,
                    description: collection.description
                },
                callbacks: {
                    CALLBACK_SAVE: ({ title, description }) => {
                        return Http
                            .put(`/front_api/my/favorite_collections/${this.props.id}`, {
                            data: {
                                favorite_collection: { title, description }
                            }
                        })
                            .then(() => {
                            const favCollectionPreviewTitle = this.findElement('%{c-FavCollectionPreview/title}');
                            if (favCollectionPreviewTitle) {
                                favCollectionPreviewTitle.textContent = title;
                            }
                        });
                    }
                }
            });
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-FavCollectionPreview/edit}', event: 'click', handler: this.onEdit },
            { selector: '%{c-FavCollectionPreview/destroy}', event: 'click', handler: this.onDestroy }
        ];
    }
};
default_1 = __decorate([
    register('FavCollectionPreview')
], default_1);
export default default_1;
