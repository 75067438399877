const NAME_LIMIT = 20;
const LETTER_SPACE = 3;
export const truncateFilename = (name, options) => {
    const { limit = NAME_LIMIT, space = LETTER_SPACE } = options || {};
    const regexp = new RegExp(`^(.{${limit - space}}).*(.{${space}}\\..+)$`);
    const matchResult = name.match(regexp);
    if (!matchResult)
        return name;
    // @ts-ignore
    const [_, ...fileName] = matchResult;
    return fileName.join('...');
};
