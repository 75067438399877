var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { LazyBlock } from 'core/services/LazyBlock';
import { LazyPhoto } from 'core/services/LazyPhoto';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.slidesEl = this.findElement('%{ArticleGallery/slides}');
        this.photosEl = this.findElements('%{ArticleGallery/slide/image}');
    }
    async init() {
        await LazyBlock.wait($(this.el));
        if (this.photosEl.length === 0)
            return;
        if (this.photosEl.length > 1) {
            await this.initSliderWidget();
        }
        await LazyPhoto.load(this.photosEl[0]);
    }
    destroy() {
        this.destroySliderWidget();
    }
    async initSliderWidget() {
        let { SliderWidget } = await import(
        /* webpackChunkName: 'common__w-Slider2' */
        'site/widgets/SliderWidget');
        this.wSlider = SliderWidget($(this.slidesEl), {
            fade: true,
            counter: true,
            arrows: this.props.arrows,
            adaptiveHeight: this.props.adaptiveHeight,
            CALLBACK_BEFORE_SLIDE: ({ nextSlideIndex }) => {
                let photoEl = this.photosEl[nextSlideIndex];
                if (photoEl) {
                    LazyPhoto.load(photoEl);
                }
            }
        });
        this.wSlider.init();
    }
    destroySliderWidget() {
        if (this.wSlider) {
            this.wSlider.destroy();
            this.wSlider = null;
        }
    }
};
default_1 = __decorate([
    register('ArticleGallery')
], default_1);
export default default_1;
