import Store from 'core/services/Store';
export const initialState = {
    notices: {
        errors: {},
        info: {},
        warnings: {}
    }
};
export function createStore(initialState, onChangeState) {
    if (onChangeState) {
        return Store({
            props: { initialState },
            callbacks: {
                CALLBACK_AFTER_COMMIT: onChangeState
            }
        });
    }
    return Store({
        props: { initialState }
    });
}
