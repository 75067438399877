var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.tabSelector = '%{RecipeFiltersSwitcher/tab}';
        this.pageSelector = '%{RecipeFiltersSwitcher/page}';
        this.$tabEls = this.findElements(this.tabSelector);
        this.$pageEls = this.findElements(this.pageSelector);
        this.handleTabClick = (e) => {
            var _a, _b;
            this.changeActiveFilter((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.filter);
        };
    }
    init() {
        this.bindEvent(`click on ${this.tabSelector}`, this.handleTabClick);
    }
    changeActiveFilter(filterName) {
        [this.$tabEls, this.$pageEls].forEach(elements => {
            elements.forEach(el => {
                el.classList.toggle('__isActive', el.dataset.filter === filterName);
            });
        });
    }
};
default_1 = __decorate([
    register('RecipeFiltersSwitcher')
], default_1);
export default default_1;
