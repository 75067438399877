import { HttpClient } from 'core/services/HttpClient';
export function complete({ saveUrl, comment, clientRefused, chosenDate, chosenPeriod, orderId, token }) {
    var _a, _b;
    const params = {
        orderId,
        deliveryDate: (_a = chosenDate === null || chosenDate === void 0 ? void 0 : chosenDate.date) !== null && _a !== void 0 ? _a : null,
        period: (_b = chosenPeriod === null || chosenPeriod === void 0 ? void 0 : chosenPeriod.period) !== null && _b !== void 0 ? _b : null,
        clientRefused,
        comment,
        token
    };
    return HttpClient.post(saveUrl, params);
}
