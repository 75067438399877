import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import autocompleteTree from 'site/VDOMComponents/AutocompleteTree';
import { TextControl } from 'luxury_theme.mobile/UI/controls/TextControl';
// This version renders all elements without nested scroll
const AutocompleteTree = autocompleteTree();
export class LuxuryAutocomplete extends Component {
    constructor() {
        super(...arguments);
        this.componentDidMount = () => {
            this.props.onMount();
        };
        this.handleChange = (item) => {
            this.props.onChange(item, this.input);
        };
        this.handleFocusAutocomplete = () => {
            if (this.props.disabled)
                return;
            this.setState({ isLabelFloated: true }, this.props.onOpen);
        };
        this.handleBlurAutocomplete = () => {
            this.props.onClose();
            if (this.props.selectedValue.text || this.props.isLabelFixed)
                return;
            this.setState({ isLabelFloated: false });
        };
        this.handleInput = (value) => {
            this.props.onInput(value);
        };
        this.handleClear = () => {
            this.props.onInput('');
        };
        this.handleRef = (el) => {
            var _a, _b;
            this.input = el;
            (_b = (_a = this.props).onInputRef) === null || _b === void 0 ? void 0 : _b.call(_a, el !== null && el !== void 0 ? el : null);
        };
    }
    get listClassList() {
        const { buildClassName, isLoading, isOpen, items } = this.props;
        const isChildren = items.some(item => Boolean(item.children));
        return buildClassName('items __fixedList', {
            '__open': isOpen,
            '__loading': isLoading,
            '__withoutChildren': !isChildren
        });
    }
    render() {
        const { isRequired, inputValue, selectedValue, items, disabled = false, label, isOpen, buildClassName } = this.props;
        return (h("div", { className: htmlClassNames('control-Autocomplete', {
                '__dropOpen': isOpen
            }), onFocusCapture: this.handleFocusAutocomplete, onBlurCapture: this.handleBlurAutocomplete, tabIndex: 0 },
            h(TextControl, { type: 'text', name: 'autocomplete', label: label, required: isRequired, value: inputValue, disabled: disabled, inputRef: this.handleRef, onChange: this.handleInput, disableAutocomplete: true }),
            h(AutocompleteTree, { inputValue: inputValue, items: items, onClick: this.handleChange, onChildClick: this.handleChange, getLabel: (item) => item.text, isSelected: (item) => item.id === selectedValue.id, rootClassName: this.listClassList, notFoundJsx: items.length === 0 ? h("p", null, "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E") : undefined, headJSX: h("h5", { className: buildClassName('itemsTitle'), onClick: this.handleBlurAutocomplete }, "\u041F\u0440\u0435\u0434\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u044F") })));
    }
}
