export function asyncLoadStyles(assetUrl) {
    return new Promise(resolve => {
        if (document.querySelector(`[href="${assetUrl}"]`))
            return resolve(null);
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = assetUrl;
        link.onload = () => resolve(null);
        document.getElementsByTagName('HEAD')[0].appendChild(link);
    });
}
export function loadComponentsStyles(key) {
    return new Promise(resolve => {
        const stylesData = Env.componentStylesMapping[key];
        if (!stylesData)
            return resolve(null);
        const { url, class: bodyClass } = stylesData;
        if (!url && !document.body.classList.contains(bodyClass))
            document.body.classList.add(bodyClass);
        if (url)
            return asyncLoadStyles(url).finally(() => resolve(null));
        resolve(null);
    });
}
