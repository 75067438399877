import Store from 'core/services/Store';
import { PageName } from '../types';
export const initialState = {
    currentPage: PageName.DeliveryDate,
    isLoading: false,
    orderId: null,
    isDeliveryDateAutoUpdated: false,
    autoDeliveryDate: null,
    deliveryDates: [],
    saveUrl: null,
    chosenDate: null,
    chosenPeriod: null,
    comment: null,
    clientName: null,
    clientRefused: false,
    inmyroomPhone: null,
    token: null
};
export function createStore(onChangeState, initialState) {
    return Store({
        props: { initialState },
        callbacks: {
            CALLBACK_AFTER_COMMIT: onChangeState
        }
    });
}
