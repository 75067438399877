let __controllers = new Map();
let __initedControllers = new Array();
let initController = async (name, el, props) => {
    if (!__controllers.has(name)) {
        console.warn(`Legacy Controllers Core: Can't initialize "${name}" controller.`);
        return;
    }
    let Controller = __controllers.get(name);
    if (Controller.shouldInit(el, props)) {
        let controllerInstance = new Controller(el, props);
        el.dataset.controllerInited = 'true';
        await controllerInstance.init();
        __initedControllers.push(controllerInstance);
    }
};
let connect = (target = document.body) => {
    target = target !== null && target !== void 0 ? target : document.body;
    let children = target.querySelectorAll('[data-controller]:not([data-controller-inited])');
    [...children].forEach((el) => {
        var _a;
        let props = {};
        let name = el.dataset.controller;
        try {
            props = JSON.parse(el.dataset.props || '{}');
        }
        catch (error) {
            props = {};
            (_a = window.bugsnagClient) === null || _a === void 0 ? void 0 : _a.notify(error);
        }
        finally {
            initController(name, el, props);
        }
    });
};
let disconnect = (target = document.body) => {
    __initedControllers = __initedControllers.filter(controller => {
        if (target.contains(controller.el)) {
            controller.destroy();
            return true;
        }
        else {
            return false;
        }
    });
};
let register = (name) => {
    return (controller) => {
        __controllers.set(name, controller);
    };
};
export { connect, disconnect, register };
