import { COMMIT_CHANGE_LOADING } from './changeLoading';
import { COMMIT_CHANGE_ITEMS } from './cartItems';
export function COMMIT_OPEN_REACHED_LIMIT_WARNING(state, payload) {
    const { invalidItems, bestsellerProducts, ...otherData } = payload;
    return {
        ...COMMIT_CHANGE_LOADING(state, false),
        ...COMMIT_CHANGE_ITEMS(state, invalidItems),
        ...otherData,
        isReachedLimitWarningOpened: true,
        reachedLimitData: {
            invalidItems,
            bestsellerProducts
        }
    };
}
export function COMMIT_CLOSE_REACHED_LIMIT_WARNING(state) {
    return { ...state, isReachedLimitWarningOpened: false, reachedLimitData: null };
}
