export function buildScrollLock(scrollEl) {
    let enabled = false;
    let scrollPosition = 0;
    function addEnableStyle(styleKey, value) {
        scrollEl.style[styleKey] = value;
    }
    function removeEnableStyle(styleKey) {
        scrollEl.style.removeProperty(styleKey);
    }
    function enableMobile() {
        scrollPosition = window.pageYOffset;
        addEnableStyle('overflow', 'hidden');
        addEnableStyle('position', 'fixed');
        addEnableStyle('top', `-${scrollPosition}px`);
        addEnableStyle('width', '100%');
    }
    function disableMobile() {
        removeEnableStyle('overflow');
        removeEnableStyle('position');
        removeEnableStyle('top');
        removeEnableStyle('width');
        window.scrollTo(0, scrollPosition);
    }
    return {
        enable() {
            if (enabled)
                return;
            requestAnimationFrame(() => {
                Env.version === 'mobile' ? enableMobile() : document.body.classList.add('has-modal-open');
                enabled = true;
            });
        },
        disable() {
            if (!enabled)
                return;
            requestAnimationFrame(() => {
                Env.version === 'mobile' ? disableMobile() : document.body.classList.remove('has-modal-open');
                enabled = false;
            });
        }
    };
}
export default buildScrollLock(document.querySelector('body'));
