var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.openPhotoModal = async (photo) => {
            const { PhotoModal, PhotoListCursor } = await import(
            /* webpackChunkName: 'common__s-PhotoModal'*/
            // @ts-ignore
            'core/services/PhotoModal');
            const photoIds = this.findElements('[data-photo-id]').map(el => el.dataset.photoId);
            const photoId = photo.dataset.photoId;
            const photoListCursor = new PhotoListCursor({
                list: () => photoIds
            }).moveTo(photoId);
            PhotoModal.open(photoListCursor);
        };
        this.handlePhotoClick = (e) => {
            e.preventDefault();
            this.openPhotoModal(e.currentTarget);
        };
    }
    get nativeListeners() {
        return [
            { selector: '[data-photo-id]', event: 'click', handler: this.handlePhotoClick }
        ];
    }
};
default_1 = __decorate([
    register('PhotoLinkList')
], default_1);
export default default_1;
