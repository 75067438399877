import { HttpClient } from 'core/services/HttpClient';
export async function getToken() {
    const prodTokenUrl = 'https://xapi.ozon.ru/principal-auth-api/connect/token';
    const devTokenUrl = 'https://api-stg.ozonru.me/principal-auth-api/connect/token';
    const prodData = 'grant_type=client_credentials&client_id=ApiUserInmyroom1_d85fbb95-1b6a-4ee3-a4b0-4b3976fb759d&client_secret=3aUxxLdG7TGl69Y4TAImaZZdXVAnx2Z5dus2N7wOm5c=';
    const devData = 'grant_type=client_credentials&client_id=ApiTest_11111111-1111-1111-1111-111111111111&client_secret=SRYksX3PBPUYj73A6cNqbQYRSaYNpjSodIMeWoSCQ8U=';
    const tokenUrl = Env.name === 'production' ? prodTokenUrl : devTokenUrl;
    const payload = Env.name === 'production' ? prodData : devData;
    const { data: { accessToken } } = await HttpClient.post(tokenUrl, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: false
    });
    return accessToken;
}
export async function getCityNameByPickupId(id) {
    try {
        const { data: { cityName } } = await HttpClient.get('/ozon/get_city_name_by_pickup_id', { params: { id } });
        return cityName;
    }
    catch (error) {
        console.error(error.data.error);
    }
}
export async function getDeliveryPriceByAddress(address) {
    try {
        const { data: { price } } = await HttpClient.get('/ozon/calculate_delivery_price_by_address', { params: { address } });
        return price;
    }
    catch (error) {
        console.error(error.data.error);
    }
}
