var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleClose = () => {
            document.body.classList.remove('__importantNewsActive');
            this.el.remove();
            const date = new Date(Date.now() + 2.592e+9); // 30 дней
            document.cookie = `${this.props.bannerKey}=disabled; path=/; expires=${date.toUTCString()}`;
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-ImportantNews/close}', event: 'click', handler: this.handleClose }
        ];
    }
    init() {
        this.addNativeListeners();
        document.body.classList.add('__importantNewsActive');
    }
};
default_1 = __decorate([
    register('ImportantNews')
], default_1);
export default default_1;
