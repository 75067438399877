var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VController, register } from 'core/services/Controller';
import { Component, h } from 'preact';
import Http from 'core/services/HttpClient';
import DOMComponent from 'core/services/DOMComponent';
import { connect, disconnect } from 'core/services/Controller';
export class LazyHtmlComponent extends Component {
    async componentWillMount() {
        await this.loadInnerHtml();
        this.moveToParent();
        this.initComponents();
    }
    componentWillUnmount() {
        if (!this.props.isReloadComponents)
            return;
        DOMComponent.disconnect(this.props.parent);
        disconnect(this.props.parent);
    }
    get el() {
        return this.props.el;
    }
    async loadInnerHtml() {
        const { data } = await Http.get(this.props.path);
        this.el.innerHTML = data;
    }
    moveToParent() {
        const parent = this.el.parentNode;
        if (!parent)
            return;
        while (this.el.firstChild) {
            let currentEl = this.el.firstChild;
            if (currentEl.tagName === 'SCRIPT') {
                const newScript = this.createScriptEl(currentEl);
                currentEl.remove();
                currentEl = newScript;
            }
            parent.insertBefore(currentEl, this.el);
        }
        parent.removeChild(this.el);
    }
    createScriptEl(currentEl) {
        const newScript = document.createElement('script');
        newScript.setAttribute('src', currentEl.src);
        newScript.setAttribute('type', 'text/javascript');
        newScript.async = false;
        return newScript;
    }
    initComponents() {
        if (!this.props.isReloadComponents)
            return;
        DOMComponent.connect(this.props.parent);
        connect(this.props.parent);
    }
    render() {
        return null;
    }
}
let default_1 = class extends VController {
    render() {
        return h(LazyHtmlComponent, Object.assign({}, this.props, { el: this.el, parent: this.el.parentNode }));
    }
};
default_1 = __decorate([
    register('LazyHtml')
], default_1);
export default default_1;
