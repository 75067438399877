var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// *TODO: пока без axios, потому что ветка с нужным кодом висит в пр'е | Created at: 10.Sep.2019
import Http from 'core/services/Http';
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleDelete = () => {
            Http.delete(this.props.destroyUrl)
                .then(() => {
                $(document).trigger(`${this.props.namespace}.delete`);
                const deleteEl = this.findElement('%{PhotoUploader/delete}');
                if (deleteEl)
                    deleteEl.style.display = 'none';
            });
        };
        this.handleChange = async (e) => {
            let { PhotoCropperModal } = await import(
            /* webpackChunkName: 'common__m-PhotoCropper'*/
            'site/modals/PhotoCropperModal');
            const target = e.target;
            if (target === null)
                return;
            let modal = PhotoCropperModal({
                props: {
                    file: target.files[0],
                    aspectRatio: this.props.aspectRatio,
                    minCropBoxWidth: this.props.selectAreaSize
                },
                callbacks: {
                    CALLBACK_SAVE: file => {
                        Http.post(this.props.uploadUrl, { data: this.createFormData(file) })
                            .then(({ url }) => {
                            modal.destroy();
                            $(document).trigger(`${this.props.namespace}.upload`, [{ url }]);
                        });
                    },
                    CALLBACK_CANCEL: () => modal.destroy()
                }
            });
        };
        this.createFormData = (file) => {
            let formData = new FormData();
            formData.append('file', file);
            return formData;
        };
    }
    init() {
        this
            .bindEvent('click on %{PhotoUploader/delete}', this.handleDelete)
            .bindEvent('change on input', this.handleChange);
    }
};
default_1 = __decorate([
    register('PhotoUploader')
], default_1);
export default default_1;
