var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleShowClick = (e) => {
            e.preventDefault();
            const target = e.target;
            target.remove();
            const itemsEl = this.findElement('.b-FastLinks_items');
            itemsEl === null || itemsEl === void 0 ? void 0 : itemsEl.classList.add('__isShowing');
            const hiddenEl = this.findElement('%{FastLinks/hidden}');
            hiddenEl === null || hiddenEl === void 0 ? void 0 : hiddenEl.classList.remove('__isHidden');
        };
    }
    init() {
        this.bindEvent('click on %{FastLinks/show}', this.handleShowClick);
    }
};
default_1 = __decorate([
    register('FastLinks')
], default_1);
export default default_1;
