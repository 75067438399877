import { Component, createRef } from 'preact';
import Portal from 'preact-portal';
import classNames from 'classnames';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import h from './cssScope';
const App = asyncComponent(() => import(
/* webpackChunkName: "site_c-CartModalAside" */
'./App'));
export class AppWrapper extends Component {
    constructor() {
        super(...arguments);
        this.layoutRef = createRef();
        this.state = {
            open: false,
            loading: true
        };
        this.componentDidMount = () => {
            this.layoutRef.current.addEventListener('mouseover', this.handleClearTimeoutOnClose);
            this.layoutRef.current.addEventListener('mouseleave', this.handleClose);
            document.addEventListener('c-Checkout:addItemFromRecent', this.handleOpenWithAutoClose);
            document.addEventListener('c-Checkout:addProductsSet', this.handleOpenWithAutoClose);
        };
        this.componentWillUnmount = () => {
            document.removeEventListener('c-Checkout:addItemFromRecent', this.handleOpenWithAutoClose);
            document.removeEventListener('c-Checkout:addProductsSet', this.handleOpenWithAutoClose);
        };
        this.handleOpenWithAutoClose = () => {
            if (this.state.open)
                return;
            this.handleOpen();
            this.handleSetTimeoutOnClose();
        };
        this.handleSetTimeoutOnClose = () => {
            this.timerId = setTimeout(this.handleClose, 5000);
        };
        this.handleClearTimeoutOnClose = () => {
            clearTimeout(this.timerId);
        };
        this.handleOpen = () => {
            if (this.state.loading) {
                this.handleLoading();
            }
            this.setState({ open: true });
        };
        this.handleClose = () => {
            this.setState({ open: false });
        };
        this.handleLoading = async () => {
            const { default: checkoutContext } = await import(
            /* webpackChunkName: 'common__c-checkoutContext' */
            'site/VControllers/components/Checkout/context');
            this.checkoutContext = checkoutContext;
            await this.checkoutContext.init();
            this.setState({ loading: false });
        };
    }
    render() {
        const { open, loading } = this.state;
        const { cartPath } = this.props;
        return (h(Portal, { into: 'body' },
            h("div", { className: classNames('ScopeRoot', '__modalAside', { '__open': open }) },
                h("div", { className: 'sb-Layout', ref: this.layoutRef },
                    h("div", { className: 'sb-Layout_close', onClick: this.handleClose },
                        h("span", null)),
                    h("div", { className: classNames('sb-Layout_splash', { '__active': loading }) }),
                    !loading && (h(App, { initState: this.checkoutContext.getContext().store.getState(), cartPath: cartPath, layoutRef: this.layoutRef, callToActionsProps: { btnClass: '__large __black' } }))))));
    }
}
