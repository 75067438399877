var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
// @ts-ignore
import FavoriteListService from 'core/services/FavoriteListService';
import waitPageLoaded from 'core/utils/waitPageLoaded';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.__hasObjectInList = false;
        this.__objectEventKey = `${this.props.objectType}.${this.props.objectId}`;
        this.init = () => {
            if (Env.isLogged) {
                this.el.addEventListener('click', this.handleToggle);
                this.requestHaving();
            }
            this.fetchCount();
            FavoriteListService.on(`add.${this.__objectEventKey}`, this.handleAdded);
            FavoriteListService.on(`remove.${this.__objectEventKey}`, this.handleRemoved);
        };
        this.destroy = () => {
            FavoriteListService.off(`add.${this.__objectEventKey}`, this.handleAdded);
            FavoriteListService.off(`remove.${this.__objectEventKey}`, this.handleRemoved);
        };
        this.handleToggle = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const payload = {
                objectType: this.props.objectType,
                objectId: this.props.objectId,
                el: this.el
            };
            this.__hasObjectInList
                ? FavoriteListService.remove(payload)
                : FavoriteListService.add(payload);
        };
        this.handleRemoved = () => {
            this.setHaving(false);
            this.fetchCount();
        };
        this.handleAdded = () => {
            this.setHaving(true);
            this.fetchCount();
        };
        this.fetchCount = () => {
            if (this.props.hasCounter) {
                waitPageLoaded()
                    .then(() => FavoriteListService.fetchAddedCount({
                    objectType: this.props.objectType,
                    objectId: this.props.objectId
                }))
                    .then(count => {
                    const counter = this.findElement('%{c-BtnFavorite/counter}');
                    if (counter) {
                        counter.textContent = count <= 0 && !this.props.displayZero ? null : count;
                    }
                });
            }
        };
        this.requestHaving = () => {
            waitPageLoaded()
                .then(() => FavoriteListService.requestHaving({
                objectType: this.props.objectType,
                objectId: this.props.objectId
            }))
                .then(flag => this.setHaving(flag));
        };
        this.setHaving = (flag) => {
            this.__hasObjectInList = flag;
            if (!this.props.isAdmin) {
                this.el.classList.toggle('has-in-favorites', flag);
            }
        };
    }
};
default_1 = __decorate([
    register('BtnFavorite')
], default_1);
export default default_1;
