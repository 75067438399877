import { DeliveryInfoRequest } from './api';
import EventEmitter from 'eventemitter3';
import waitPageLoaded from 'core/utils/waitPageLoaded';
export var DeliveryInfoFetcher;
(function (DeliveryInfoFetcher) {
    let productIds = new Array();
    const eventEmitter = new EventEmitter();
    const handleFetch = () => {
        waitPageLoaded().then(fetchAsync);
    };
    const fetchAsync = async () => {
        const result = await DeliveryInfoRequest(productIds);
        productIds.forEach(productId => {
            let deliveryInfo = result[productId];
            deliveryInfo && eventEmitter.emit(`render.${productId}`, deliveryInfo);
        });
        productIds = [];
        eventEmitter.once('fetch', handleFetch);
    };
    eventEmitter.once('fetch', handleFetch);
    async function fetch(id) {
        productIds.push(id);
        eventEmitter.emit('fetch');
        return new Promise(resolve => {
            eventEmitter.on(`render.${id}`, resolve);
        });
    }
    DeliveryInfoFetcher.fetch = fetch;
})(DeliveryInfoFetcher || (DeliveryInfoFetcher = {}));
