// TODO: refactor to two separate files with real node as value source and reactive
import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import Http from 'core/services/HttpClient';
import { FixedAutocomplete } from '../templates/FixedAutocomplete';
import { LuxuryAutocomplete } from '../templates/LuxuryAutocomplete';
import { Autocomplete } from '../templates/Autocomplete';
export var TemplatesTypes;
(function (TemplatesTypes) {
    TemplatesTypes["Autocomplete"] = "Autocomplete";
    TemplatesTypes["FixedAutocomplete"] = "FixedAutocomplete";
    TemplatesTypes["LuxuryAutocomplete"] = "LuxuryAutocomplete";
})(TemplatesTypes || (TemplatesTypes = {}));
const TypesToTemplatesMap = {
    [TemplatesTypes.Autocomplete]: Autocomplete,
    [TemplatesTypes.FixedAutocomplete]: FixedAutocomplete,
    [TemplatesTypes.LuxuryAutocomplete]: LuxuryAutocomplete
};
export function baseAutocomplete(autocompleteUrl, initLabel, changeValueEvent, options) {
    const { extraStateBuilder = (_component) => ({}), extraQueryParams = (_component) => ({}) } = options || {};
    return (class extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                ...extraStateBuilder(this),
                currentValue: this.defaultValue,
                isOpened: false,
                searchValue: '',
                searchingItems: [],
                loading: false,
                itemsLoaded: false,
                typingTimeout: 0
            };
            this.buildClassName = (elementName, mods = {}) => {
                const { baseClassName = 'ui-Input' } = this.props;
                return htmlClassNames(`${baseClassName}_${elementName}`, mods);
            };
            this.handleChangeCurrentValue = (currentValue, input, hideCallback) => {
                var _a;
                const { id: newValue } = currentValue;
                const { inputEl, onChange = () => { }, preventCloseOnChange = () => false, preventChange = () => false } = this.props;
                if (preventChange(currentValue))
                    return;
                if (currentValue.action === 'improve') {
                    // to improve user input
                    return this.setState(state => { var _a; return ({ ...state, searchValue: `${(_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.searchText) !== null && _a !== void 0 ? _a : currentValue.text}, ` }); }, () => {
                        var _a, _b;
                        (_b = (_a = this.props).onImproveChange) === null || _b === void 0 ? void 0 : _b.call(_a, currentValue);
                        const inputEl = input !== null && input !== void 0 ? input : this.originInputEl;
                        inputEl === null || inputEl === void 0 ? void 0 : inputEl.focus();
                    });
                }
                const { currentValue: oldValue } = this.state;
                if (preventCloseOnChange(currentValue)) {
                    input === null || input === void 0 ? void 0 : input.focus();
                }
                else {
                    this.hideDropdown();
                    hideCallback === null || hideCallback === void 0 ? void 0 : hideCallback(); // to run template changed apply value
                }
                if (!inputEl)
                    return onChange(currentValue, oldValue, input);
                this.setState({ currentValue, searchValue: (_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.searchText) !== null && _a !== void 0 ? _a : currentValue.text }, () => {
                    inputEl.value = newValue ? newValue.toString() : '';
                    onChange(currentValue, oldValue, input);
                });
            };
            this.handleChangeValueEvent = ({ detail: { item, ...otherState } }) => {
                this.setState(otherState, () => this.handleChangeCurrentValue(item));
            };
            this.showDropdown = () => {
                if (this.finalBlurTimeout) {
                    clearTimeout(this.finalBlurTimeout);
                }
                if (this.props.withOnShowLoad)
                    this.loadItems();
                this.setState({ isOpened: true });
            };
            this.hideDropdown = () => {
                const { searchValue, currentValue } = this.state;
                const { defaultSearchValue, onFinalBlur } = this.props;
                this.setState({ isOpened: false });
                if (defaultSearchValue && searchValue === '') {
                    this.setState({ searchValue: defaultSearchValue });
                }
                if (onFinalBlur) {
                    if (this.finalBlurTimeout) {
                        clearTimeout(this.finalBlurTimeout);
                    }
                    this.finalBlurTimeout = setTimeout(() => onFinalBlur(currentValue), 100);
                }
            };
            this.handleFirstItemApply = (startItem) => {
                const recurse = (item) => {
                    if (item.children) {
                        recurse(item.children[0]);
                        return;
                    }
                    if (item.action === 'change') {
                        this.handleChangeCurrentValue(item);
                    }
                };
                recurse(startItem);
            };
            this.handleClose = () => {
                this.hideDropdown();
            };
            this.handleApply = () => {
                const { autoSelect } = this.props;
                const { searchingItems, currentValue } = this.state;
                if (autoSelect && searchingItems.length > 0 && !currentValue.id) {
                    this.handleFirstItemApply(searchingItems[0]);
                }
            };
            this.handleEnterKeyPressed = (e) => {
                if (e.key === 'Enter') {
                    this.handleApply();
                }
            };
            this.handleClear = () => {
                var _a;
                this.handleChangeCurrentValue({ id: undefined, text: '' });
                (_a = this.originInputEl) === null || _a === void 0 ? void 0 : _a.focus();
            };
            this.handleChangeSearch = (searchValue) => {
                var _a, _b;
                const { typingTimeout } = this.state;
                (_b = (_a = this.props).onChangeSearch) === null || _b === void 0 ? void 0 : _b.call(_a, searchValue);
                if (typingTimeout) {
                    clearTimeout(typingTimeout);
                }
                this.setState({
                    searchValue,
                    typingTimeout: setTimeout(this.loadItems, 700)
                });
            };
            this.fetchItems = () => Http.get(autocompleteUrl, { params: this.queryParams });
            this.loadItems = () => {
                if (!this.isSearchInput)
                    return;
                if (this.state.searchValue === '') {
                    this.setState({ searchingItems: [] });
                    return;
                }
                this.setState({ loading: true });
                const promise = this.props.fetchItems ? this.props.fetchItems(this.queryParams) : this.fetchItems();
                promise
                    .then(({ data }) => {
                    let searchingItems = data
                        .map(item => {
                        if (this.props.preventChange) {
                            return this.props.preventChange(item) ? ({ ...item, isInfo: true }) : item;
                        }
                        return item;
                    });
                    const { excludeItemsIds } = this.props;
                    if (excludeItemsIds) {
                        searchingItems = searchingItems.filter(item => item.id ? !excludeItemsIds.includes(Number(item.id)) : true);
                    }
                    this.setState({ searchingItems, loading: false, itemsLoaded: true });
                }).catch(() => {
                    this.setState({ searchingItems: [], loading: false, itemsLoaded: true });
                });
            };
            this.handleInputRef = (el) => {
                var _a, _b;
                this.originInputEl = el;
                (_b = (_a = this.props).onInputRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
            };
        }
        componentDidMount() {
            if (this.props.inputEl && this.props.value)
                console.warn('You should use either inputEl or value prop');
            this.hideRealInput();
            this.assignInitState();
            this.bindListeners();
        }
        componentWillReceiveProps({ inputEl, value, items, defaultSearchValue }) {
            var _a;
            if (inputEl)
                return;
            value = value === null || value === void 0 ? void 0 : value.toString();
            if (this.state.currentValue.id !== value) {
                const currentValue = this.findItem(value, items) || this.findItem(value, this.state.searchingItems) || this.defaultValue;
                const searchValue = currentValue.id ? (_a = currentValue.searchText) !== null && _a !== void 0 ? _a : currentValue.text : defaultSearchValue !== null && defaultSearchValue !== void 0 ? defaultSearchValue : '';
                this.setState({ currentValue, searchValue }, () => { var _a, _b; return (_b = (_a = this.props).onChangeSearch) === null || _b === void 0 ? void 0 : _b.call(_a, searchValue); });
            }
        }
        componentWillUnmount() {
            this.removeListeners();
        }
        hideRealInput() {
            if (!this.props.inputEl)
                return;
            this.props.inputEl.style.display = 'none';
        }
        assignInitState() {
            var _a;
            const inputEl = this.props.inputEl;
            const value = Boolean(inputEl) ? inputEl.value : this.props.value;
            if (!value)
                return this.assignPreselectValue();
            const currentValue = this.findItem(value.toString()) || this.defaultValue;
            const { defaultSearchValue } = this.props;
            const searchValue = currentValue.id ? (_a = currentValue.searchText) !== null && _a !== void 0 ? _a : currentValue.text : defaultSearchValue !== null && defaultSearchValue !== void 0 ? defaultSearchValue : '';
            this.setState({ currentValue, searchValue }, () => { var _a, _b; return (_b = (_a = this.props).onChangeSearch) === null || _b === void 0 ? void 0 : _b.call(_a, searchValue); });
        }
        // to add ability fill preselect city
        assignPreselectValue() {
            this.setState(state => { var _a; return ({ ...state, searchValue: (_a = this.props.defaultSearchValue) !== null && _a !== void 0 ? _a : '' }); });
        }
        bindListeners() {
            document.addEventListener('keypress', this.handleEnterKeyPressed);
            if (!changeValueEvent)
                return;
            document.addEventListener(changeValueEvent, this.handleChangeValueEvent);
        }
        removeListeners() {
            document.removeEventListener('keypress', this.handleEnterKeyPressed);
            if (!changeValueEvent)
                return;
            document.removeEventListener(changeValueEvent, this.handleChangeValueEvent);
        }
        findItem(itemId, items = this.props.items) {
            var _a;
            let targetItem = null;
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                const { id, children } = item;
                if ((id === null || id === void 0 ? void 0 : id.toString()) === itemId) {
                    targetItem = item;
                    break;
                }
                if (children && children.length > 0) {
                    for (let j = 0; j < children.length; j++) {
                        let childItem = children[j];
                        if (((_a = childItem.id) === null || _a === void 0 ? void 0 : _a.toString()) === itemId) {
                            targetItem = childItem;
                            break;
                        }
                    }
                }
            }
            return targetItem;
        }
        get defaultValue() {
            const { placeholder = '' } = this.props;
            return { id: undefined, text: placeholder };
        }
        get displaySearchValue() {
            if (this.props.alwaysDisplaySearchValue)
                return this.state.searchValue;
            const { searchValue, isOpened, currentValue: { text, searchText } } = this.state;
            return isOpened ? searchValue : (searchText !== null && searchText !== void 0 ? searchText : text);
        }
        get isSearchInput() {
            return !!this.state.searchValue || this.props.isSearchInput;
        }
        get items() {
            return this.state.itemsLoaded && this.isSearchInput ? this.state.searchingItems : this.props.items;
        }
        get queryParams() {
            return Object.assign({ query: this.state.searchValue }, extraQueryParams(this));
        }
        get templateType() {
            if (this.props.templateType)
                return this.props.templateType;
            if (Env.version === 'desktop' || /^\/?admin\//.test(window.location.pathname))
                return TemplatesTypes.Autocomplete;
            return TemplatesTypes.FixedAutocomplete;
        }
        render() {
            const { currentValue, loading, isOpened } = this.state;
            const { disabled = false, isRequired, tag, onMount = () => { }, label = initLabel, isLabelFixed, notice } = this.props;
            const Template = TypesToTemplatesMap[this.templateType];
            if (!Template)
                return null;
            return (h(Template, { isRequired: isRequired, inputValue: this.displaySearchValue, label: label, selectedValue: currentValue, items: this.items, isLoading: loading, disabled: disabled, buildClassName: this.buildClassName, onInput: this.handleChangeSearch, onChange: this.handleChangeCurrentValue, onOpen: this.showDropdown, onClose: this.handleClose, onClear: this.handleClear, onApply: this.handleApply, onMount: onMount, isOpen: isOpened, onInputRef: this.handleInputRef, tag: tag, isLabelFixed: isLabelFixed, notice: notice }));
        }
    });
}
