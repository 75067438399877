import Http from 'core/services/Http';
export default function getPoints([[latFrom, lonFrom], [latTo, lonTo]]) {
    return Http.post('/yandex_delivery/pickup_points', {
        data: {
            latitude: {
                from: latFrom,
                to: latTo
            },
            longitude: {
                from: lonFrom,
                to: lonTo
            }
        }
    });
}
