export function copyText(text) {
    let $input = $('<input/>')
        .attr({ type: 'text', value: text })
        .css({ position: 'absolute', left: '-99999px' })
        .appendTo('body');
    let input = $input[0];
    input.select();
    document.execCommand('copy');
    $input.remove();
}
