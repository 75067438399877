import { h } from 'preact';
export const Actions = (props) => {
    const { buildClassName, onApply, onClear, styles, isVisible } = props;
    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onApply();
    };
    return (h("div", { className: buildClassName('autocompleteActions', { '__visible': isVisible }), style: styles },
        h("div", { className: buildClassName('autocompleteClear'), onClick: onClear },
            h("span", null)),
        h("div", { className: buildClassName('autocompleteApply'), onClick: handleClose },
            h("span", null))));
};
