import { h, Component } from 'preact';
export class CustomerType extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (event) => {
            const target = event.target;
            if (target.checked) {
                this.props.onChange('legal_entity');
            }
            else {
                this.props.onChange('individual_entity');
            }
        };
    }
    render() {
        const ct = this.context.currentState
            .formFields
            .find(({ key }) => key === 'customer_type');
        return (h("label", null,
            h("input", { type: 'checkbox', className: 'b-control-Switch', name: this.props.name, checked: (ct === null || ct === void 0 ? void 0 : ct.value) === 'legal_entity', onChange: this.handleChange }),
            h("span", null, "\u0417\u0430\u043A\u0430\u0437 \u043E\u0442 \u044E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0433\u043E \u043B\u0438\u0446\u0430")));
    }
}
