import { load } from '@fingerprintjs/botd'

if (!Env.isLogged && Env.name !== 'test') {
  setTimeout(() => {
    if (document.cookie.split('; ').includes('bodesy=2')) {
      return
    }
    document.cookie = 'bodesy=2; path=/; max-age=86400'

    load()
      .then((botd) => botd.detect())
      .then((result) => {
        if (result.bot) {
          const botType = result.botType || 'unknown'
          const data = { rbt: true, type: botType, event: 'rbte' }

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push(data)
          // fetch('/rbg.gif', {
          //   method: 'POST',
          //   headers: { 'Content-Type': 'application/json' },
          //   body: JSON.stringify(data)
          // })
        }
      })
      .catch((error) => window.bugsnagClient?.notify(error))
  }, 200)
}
