var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.input = this.findElement('%{LabledField/input}');
        this.handleFocusInput = () => {
            this.el.classList.add('__labelFloated');
        };
        this.handleBlurInput = () => {
            var _a;
            if (((_a = this.input) === null || _a === void 0 ? void 0 : _a.value) || this.props.isLabelFixed)
                return;
            this.el.classList.remove('__labelFloated');
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{LabledField/input}', event: 'focus', handler: this.handleFocusInput },
            { selector: '%{LabledField/input}', event: 'blur', handler: this.handleBlurInput }
        ];
    }
    init() {
        var _a;
        this.addNativeListeners();
        if (((_a = this.input) === null || _a === void 0 ? void 0 : _a.value) || this.props.isLabelFixed) {
            this.el.classList.add('__labelFloated');
        }
    }
};
default_1 = __decorate([
    register('LabledField')
], default_1);
export default default_1;
