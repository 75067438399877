import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import Tooltip from 'core/components/UI/Tooltip';
import * as validations from '../../../stores/validations';
export class TextControl extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            labelFloating: false,
            errors: ''
        };
        this.componentDidMount = () => {
            var _a;
            // @ts-ignore
            this.unsubscribeFromErrors = (_a = validations[`$${this.props.name}ErrorsFormattedToString`]) === null || _a === void 0 ? void 0 : _a.listen(errors => {
                this.setState({ errors });
            });
        };
        this.componentWillUnmount = () => {
            var _a;
            (_a = this.unsubscribeFromErrors) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.handleChange = (event) => {
            const target = event.target;
            this.props.onChange(target.value);
        };
        this.handleFocusInput = () => {
            this.setState({ labelFloating: true });
        };
        this.handleBlurInput = () => {
            if (this.props.value || this.props.preventLabelFloating)
                return;
            this.setState({ labelFloating: false });
        };
    }
    render() {
        const { label, required = false, disabled = false, inputRef, tooltip, type, name, value, disableAutocomplete = false, className } = this.props;
        const { labelFloating, errors } = this.state;
        return (h("div", { className: htmlClassNames('control-Text', className, {
                '__labelFloating': value || labelFloating,
                '__invalid': !!errors
            }) },
            this.state.errors && (h("p", { className: 'control-Text_error' }, this.state.errors)),
            h("label", { className: 'control-Text_label' },
                h("span", null,
                    label,
                    required && h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*")),
                h("input", { className: 'control-Text_input', type: type, name: name, value: value, required: required, disabled: disabled, ref: inputRef, autocomplete: disableAutocomplete ? 'off' : 'on', onInput: this.handleChange, onFocus: this.handleFocusInput, onBlur: this.handleBlurInput })),
            tooltip && (h("div", { className: 'control-Text_tooltip' },
                "?",
                h(Tooltip, { label: tooltip })))));
    }
}
