import { h, Component } from 'preact';
import NoticeService from 'core/services/NoticeService';
import { uuidV4 } from 'core/utils/uuid';
import { processUpload } from 'core/services/DirectUpload';
import { FilePreview } from './components/FilePreview';
export const FILE_SIZE_LIMIT = 10485760; // 10 Мб
export class FileUploader extends Component {
    constructor() {
        super(...arguments);
        this.state = { loadingFiles: [] };
        this.fileSizeLimit = this.props.fileSizeLimit || FILE_SIZE_LIMIT;
        this.handleChange = (e) => {
            const files = [...e.target.files];
            const sizeLimitInMB = this.fileSizeLimit / (1024 * 1024);
            files.forEach(file => {
                if (file.size >= this.fileSizeLimit) {
                    NoticeService.error(`Не удалось загрузить ${file.name}. Файл должен быть меньше ${sizeLimitInMB} Мб`);
                    return;
                }
                if (Env.isDirectUploading)
                    return this.handleEnqueueUpload(file);
                this.props.onAddFile({
                    src: URL.createObjectURL(file),
                    file
                });
            });
        };
        this.handleEnqueueUpload = (file) => {
            const id = uuidV4();
            this.setState(state => addFileToState(state, file, id), () => {
                processUpload(file).then(res => {
                    this.setState(state => removeFileFromState(state, id));
                    this.props.onAddFile({ src: URL.createObjectURL(file), file, uploadResult: res });
                }).catch(() => {
                    this.props.onAddFile({ src: URL.createObjectURL(file), file });
                }).finally(() => this.setState(state => removeFileFromState(state, id)));
            });
        };
    }
    render() {
        const { files, accept, buttonTitle, onRemoveFile, loading = false } = this.props;
        const isFiles = files.length > 0 || this.state.loadingFiles.length > 0;
        return (h("div", { className: 'b-FileUploader' },
            isFiles && (h("div", { className: 'b-FileUploader_files' },
                files.map(file => h(FilePreview, { file: file, key: file.id, onRemove: onRemoveFile, disabled: loading })),
                this.state.loadingFiles.map(file => h(FilePreview, { file: file, key: file.id, loading: true })))),
            h("div", { className: 'b-FileUploader_button' },
                h("div", { className: 'b-FileUploader_buttonTitle' }, buttonTitle),
                h("input", { type: 'file', accept: accept, onChange: this.handleChange, multiple: true, disabled: loading }))));
    }
}
function addFileToState(state, file, id) {
    return { ...state, loadingFiles: [...state.loadingFiles, { id, file, src: URL.createObjectURL(file) }] };
}
function removeFileFromState(state, id) {
    return { ...state, loadingFiles: state.loadingFiles.filter(file => file.id !== id) };
}
