import rAFThrottle from 'core/utils/rAFThrottle';
class ScrollDirectionWatcher {
    constructor(delegates, threshold = 200) {
        var _a;
        this.delegates = delegates;
        this.threshold = threshold;
        this._lastScroll = 0;
        this._scrollEnable = false;
        this._scrollDistanceThreshold = 100;
        this.handleScroll = rAFThrottle(() => {
            const scrollTop = this.delegates.scrollContainer ? this.delegates.scrollContainer.scrollTop : window.pageYOffset;
            if (scrollTop < this.threshold && !this._scrollEnable)
                return;
            let scrollOvercome = scrollTop > this._lastScroll + this._scrollDistanceThreshold;
            if (scrollTop > this._lastScroll && scrollOvercome) {
                this.delegates.onScrollDown();
            }
            else if (scrollTop < this._lastScroll) {
                this.delegates.onScrollUp();
            }
            this._scrollEnable = scrollTop > this.threshold;
            this._lastScroll = scrollTop;
        }, 300);
        const scrollingEl = (_a = this.delegates.scrollContainer) !== null && _a !== void 0 ? _a : window;
        scrollingEl.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    destroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
export { ScrollDirectionWatcher };
