import { camelizeKeys } from 'humps';
let xhr;
export const makeRequest = (method, endpoint, data, cache, onReceiveData) => {
    if (xhr) {
        xhr.abort();
    }
    let cacheKey;
    if (cache) {
        cacheKey = cache.serializeCacheKey({
            headers: data.headers,
            body: data.json,
            url: endpoint,
            method
        });
        const cachedData = cache.get(cacheKey);
        if (cachedData) {
            onReceiveData(cachedData);
            return;
        }
    }
    xhr = new XMLHttpRequest();
    xhr.open(method, endpoint);
    if (data.headers) {
        Object.entries(data.headers).forEach(([header, headerValue]) => {
            xhr.setRequestHeader(header, headerValue);
        });
    }
    xhr.send(JSON.stringify(data.json));
    xhr.onreadystatechange = () => {
        var _a;
        if (!xhr || xhr.readyState !== 4) {
            return;
        }
        if (xhr.status === 200) {
            const payload = (_a = JSON.parse(xhr.response)) === null || _a === void 0 ? void 0 : _a.suggestions;
            if (payload) {
                const transformedPayload = payload.map((item) => camelizeKeys(item));
                cache === null || cache === void 0 ? void 0 : cache.set(cacheKey, transformedPayload);
                onReceiveData(transformedPayload);
            }
        }
    };
};
