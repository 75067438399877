var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor(el, props) {
        super(el, props);
        this.handleForceUpdate = () => {
            this.wStickySidebar.forceUpdate();
        };
        this.props = {
            topSpacing: 20,
            bottomSpacing: 20,
            relativeEl: '#index-content',
            ...props
        };
    }
    get forceUpdateEvent() {
        return `StickySidebar:${this.props.blockName}.forceUpdate`;
    }
    async init() {
        let { StickySidebarWidget } = await import(
        /* webpackChunkName: 'desktop__StickySidebar' */
        'site/widgets/StickySidebarWidget');
        document.addEventListener(this.forceUpdateEvent, this.handleForceUpdate);
        this.wStickySidebar = StickySidebarWidget($(this.el), {
            ...this.props,
            relativeEl: document.querySelector(this.props.relativeEl) || this.el
        });
    }
    destroy() {
        this.wStickySidebar.destroy();
        document.removeEventListener(this.forceUpdateEvent, this.handleForceUpdate);
    }
};
default_1 = __decorate([
    register('StickySidebar')
], default_1);
export default default_1;
