import { h, Component } from 'preact';
export class Notice extends Component {
    constructor() {
        super(...arguments);
        this.handleClose = () => {
            var _a, _b;
            (_b = (_a = this.props).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
    }
    get isCloseAvailable() {
        return !!this.props.onClose;
    }
    render() {
        const { title, desc, type } = this.props;
        return (h("div", { class: `b-NoticeList_item b-NoticeList_item__${type}`, onClick: this.handleClose },
            h("div", { class: '_title' }, title),
            h("div", { class: '_desc', dangerouslySetInnerHTML: { __html: desc } }),
            this.isCloseAvailable && h("div", { class: '_close' })));
    }
}
