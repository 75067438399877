var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h, render } from 'preact';
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.renderForm = (e) => {
            e.preventDefault();
            // Waiting finish of bubbling
            setTimeout(async () => {
                const { Form } = await import(
                /* webpackChunkName: "site_c-UserContactFormLink" */
                './components/Form');
                this.rootComponentEl = render(h(Form, {
                    ...this.props,
                    onClose: this.destroyForm
                }), document.body);
                document.body.append(this.rootComponentEl);
            }, 0);
        };
        this.destroyForm = () => {
            // https://github.com/preactjs/preact/issues/1151#issuecomment-398725321
            render(null, document.body, this.rootComponentEl);
        };
    }
    init() {
        this.el.classList.add('is-ready');
        this.addNativeListeners();
    }
    get nativeListeners() {
        return [{ event: 'click', handler: this.renderForm }];
    }
};
default_1 = __decorate([
    register('UserContactFormLink')
], default_1);
export default default_1;
