import { Component } from 'preact';
import h from '../../cssScope';
export class ThankYou extends Component {
    constructor() {
        super(...arguments);
        this.formatPhone = (phone) => {
            return phone ? phone.replace(/[^0-9]/g, '') : '';
        };
    }
    render() {
        const { clientName, chosenDate, chosenPeriod, inmyroomPhone } = this.context.store.getState();
        return (h("div", { className: 'sb-Page sb-ThankYou' },
            h("div", { className: 'sb-ThankYou_icon' }),
            h("h1", { className: 'sb-Title' },
                clientName,
                ", \u0441\u043F\u0430\u0441\u0438\u0431\u043E!"),
            h("p", { className: 'sb-ThankYou_mainInfo' },
                "\u0412\u0430\u0448\u0430 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0430 \u0437\u0430\u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0430 \u043D\u0430 ",
                h("strong", null, chosenDate === null || chosenDate === void 0 ? void 0 :
                    chosenDate.label,
                    " \u0441 ", chosenPeriod === null || chosenPeriod === void 0 ? void 0 :
                    chosenPeriod.from,
                    " \u0434\u043E ", chosenPeriod === null || chosenPeriod === void 0 ? void 0 :
                    chosenPeriod.to,
                    " \u0447\u0430\u0441\u043E\u0432.")),
            h("p", { className: 'sb-ThankYou_additionalInfo' },
                "\u0415\u0441\u043B\u0438 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u0447\u0442\u043E-\u0442\u043E \u043F\u043E\u043C\u0435\u043D\u044F\u0442\u044C, \u043F\u043E\u0437\u0432\u043E\u043D\u0438\u0442\u0435 \u043F\u043E \u043D\u043E\u043C\u0435\u0440\u0443 ",
                h("a", { href: `tel:${this.formatPhone(inmyroomPhone)}` }, inmyroomPhone),
                ".")));
    }
}
