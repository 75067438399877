import { h, Component } from 'preact';
import { getRequisites } from '../../../../../api/requisites';
import { Text } from './Text';
export class Inn extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            requisite: null,
            notFoundError: false
        };
        this.handleChange = (value) => {
            if (this.state.requisite) {
                this.setState({ requisite: null, notFoundError: false });
            }
            this.props.onChange(value);
        };
        this.handleGetRequisites = async () => {
            try {
                const { data } = await getRequisites(this.props.field.value);
                this.setState({ requisite: data, notFoundError: false });
            }
            catch (_) {
                this.setState({ requisite: null, notFoundError: true });
            }
        };
    }
    componentDidMount() {
        var _a;
        const requisite = (_a = this.context.currentState) === null || _a === void 0 ? void 0 : _a.requisites;
        if (requisite) {
            this.setState({ requisite });
        }
    }
    render() {
        const { field, name, disabled, formFields } = this.props;
        const { requisite, notFoundError } = this.state;
        return (h("div", null,
            h(Text, { field: field, name: name, type: 'text', disabled: disabled, onChange: this.handleChange, formFields: formFields }),
            notFoundError && (h("section", { className: 'inn-error' },
                h("p", null, "\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430. \u041C\u044B \u0441\u0432\u044F\u0436\u0435\u043C\u0441\u044F \u0441 \u0432\u0430\u043C\u0438 \u0432 \u0440\u0430\u0431\u043E\u0447\u0435\u0435 \u0432\u0440\u0435\u043C\u044F \u0434\u043B\u044F \u0443\u0442\u043E\u0447\u043D\u0435\u043D\u0438\u044F \u0434\u0430\u043D\u043D\u044B\u0445"))),
            requisite ? (h("section", { className: 'inn-success' },
                h("p", null,
                    h("strong", null, requisite.name)),
                h("p", null,
                    "\u0418\u041D\u041D: ",
                    requisite.inn,
                    requisite.kpp ? ' КПП: ' + requisite.kpp : ''),
                h("p", null, requisite.legalAddress))) : (h("section", null,
                h("button", { type: 'button', onClick: this.handleGetRequisites, className: 's-Checkout_b-Button __black' }, "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C")))));
    }
}
