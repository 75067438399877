import Axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import toArray from 'core/utils/array/toArray';
import requestInterceptors from './interceptors/request';
let { defaults: { transformRequest = [], transformResponse = [] } } = Axios;
export const BASE_CONFIG = {
    transformRequest: [decamelizeKeys, ...toArray(transformRequest)],
    transformResponse: [...toArray(transformResponse), camelizeKeys],
    baseURL: 'https://suggestions.dadata.ru/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
};
const axiosInstance = Axios.create(BASE_CONFIG);
requestInterceptors.forEach(interceptor => {
    axiosInstance.interceptors.request.use(interceptor);
});
export default axiosInstance;
