var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
// Контроллер включает в себя загрузку видео-обложки и ее проигрывание на ховер ивенте
// Если добавится новая функциональность, то разбить контроллер на плагины
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.videoLoaded = false;
        this.videoEl = this.findElement('%{PostPreview/video}');
        this.handleMouseEnter = () => {
            if (!this.videoLoaded)
                this.initVideo();
            this.videoEl.play();
            this.videoEl.classList.add('__isHovered');
        };
        this.handleMouseLeave = () => {
            this.videoEl.classList.remove('__isHovered');
            this.videoEl.pause();
            this.videoEl.currentTime = 0;
        };
    }
    initVideo() {
        this.videoLoaded = true;
        this.videoEl.src = this.videoEl.dataset.src;
    }
    init() {
        this
            .bindEvent('mouseenter', this.handleMouseEnter)
            .bindEvent('mouseleave', this.handleMouseLeave);
    }
};
default_1 = __decorate([
    register('PostPreview')
], default_1);
export default default_1;
