import { h, Component } from 'preact';
import { Radio } from 'core/components/UI/Inputs/Radio';
import { DEFAULT_COUNTRY_ID } from 'site/global/constants';
import { FieldsKeys } from '../../../constants';
export class RadioGroup extends Component {
    constructor() {
        super(...arguments);
        this.handleAutoSelectSingleValue = ({ field: { items, value }, onChange }) => {
            if (items.length > 1 && items.some(item => item.id === value))
                return;
            const singleOption = items[0];
            if (singleOption.id !== value)
                return onChange(singleOption.id);
        };
        this.isChecked = (item_id, value) => {
            if (item_id !== 'installments')
                return value === item_id;
            return value.toString().includes('installment');
        };
    }
    componentDidMount() {
        this.handleAutoSelectSingleValue(this.props);
    }
    componentWillUpdate(props) {
        this.handleAutoSelectSingleValue(props);
    }
    buildHint(item) {
        const country = this.props.formFields.find((field) => field.key === FieldsKeys.Country);
        if ((country === null || country === void 0 ? void 0 : country.value) !== DEFAULT_COUNTRY_ID && item.id === 'delivery')
            return '';
        return item.hint;
    }
    render() {
        const { field: { value, items, name }, onChange } = this.props;
        return (h("fieldset", { className: `ui-RadioItemsContainer __itemCount_${items.length}` }, items.map((item) => (h(Radio, { value: item.id, checked: this.isChecked(item.id, value), text: item.text, subtext: this.buildHint(item), name: name, onChange: onChange, hidden: item.hidden })))));
    }
}
