var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.onToggleItems = () => {
            this.el.classList.toggle('__detailsVisible');
        };
        this.onVote = (voteUrl) => async (e) => {
            const vote = e.currentTarget;
            const currentVotesCount = vote.dataset.votesCount;
            await Http.post(voteUrl);
            vote.dataset.votesCount = currentVotesCount + 1;
        };
        this.onHide = async () => {
            await Http.put('/admin/moderator/reviews/change_state/hide', {
                data: {
                    ids: [this.props.reviewId]
                }
            });
            window.location.reload();
        };
        this.onDelete = async () => {
            await Http.delete('/admin/moderator/reviews/destroy', {
                data: {
                    ids: [this.props.reviewId]
                }
            });
            window.location.reload();
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-ProductReview/itemsToggler}', event: 'click', handler: this.onToggleItems },
            { selector: '%{c-ProductReview/profitVoting/yes}', event: 'click', handler: this.onVote(this.props.likeUrl) },
            { selector: '%{c-ProductReview/profitVoting/no}', event: 'click', handler: this.onVote(this.props.dislikeUrl) },
            { selector: '%{c-ProductReview/adminAction/hide}', event: 'click', handler: this.onHide },
            { selector: '%{c-ProductReview/adminAction/delete}', event: 'click', handler: this.onDelete }
        ];
    }
};
default_1 = __decorate([
    register('ProductReview')
], default_1);
export default default_1;
