import { h, Component } from 'preact';
export function asyncComponent(importComponent, key = 'default') {
    return class extends Component {
        constructor() {
            super(...arguments);
            this.state = { component: null };
        }
        componentDidMount() {
            importComponent()
                .then(({ [key]: component }) => {
                this.setState({ component });
            });
        }
        render() {
            const WrappedComponent = this.state.component;
            return WrappedComponent ? h(WrappedComponent, Object.assign({}, this.props)) : null;
        }
    };
}
