var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
const SLIDES_TO_SHOW = 3;
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.initRootSlider = async () => {
            this.rootSlider = this.getInnerEl('rootSlider')[0];
            this.rootSliderWidget = await this.initSliderWidget(this.rootSlider);
        };
        this.initSliderWidget = async (el) => {
            let { SliderWidget } = await import(
            /* webpackChunkName: 'common__w-Slider2' */
            'site/widgets/SliderWidget');
            const sliderWidget = SliderWidget($(el), {
                slidesToShow: SLIDES_TO_SHOW,
                carousel: false,
                arrows: true,
                draggable: false
            });
            sliderWidget.init();
            return sliderWidget;
        };
        this.getSubcategoriesSliders = () => {
            return this.getInnerEl('subcategories').map((item) => ({
                id: item.dataset.parentId,
                el: item,
                widget: null
            }));
        };
        this.initHandlers = () => {
            this.getInnerEl('rootSliderItem').forEach((control) => {
                control.addEventListener('click', this.handleRootSliderItemClick);
            });
            this.parentCategoryEl.addEventListener('click', this.handleGoToParentCategory);
        };
        this.handleRootSliderItemClick = (e) => {
            // Use it as a link only for SEO
            e.preventDefault();
            const control = e.currentTarget;
            const subcategorySlider = this.getSubcategorySliderById(control.dataset.id);
            if (!subcategorySlider)
                return;
            const { name, parentName } = control.dataset;
            const url = control.getAttribute('href');
            this.openSubcategorySlider(subcategorySlider).then(() => {
                this.setRootCategory(subcategorySlider.id, name, url);
                this.showParentCategoryEl(parentName);
                this.setUrl(control.href);
            });
        };
        this.handleGoToParentCategory = () => {
            this.closeSubcategorySlider();
            this.closeParentCategoryEl();
            this.setRootCategory(null, this.rootCategoryName, this.rootCategoryUrl);
            this.setUrl(this.props.rootUrl);
        };
        this.openSubcategorySlider = async (subcategorySlider) => {
            this.rootSlider.classList.add('__hidden');
            subcategorySlider.el.classList.remove('__hidden');
            subcategorySlider.widget = await this.initSliderWidget(subcategorySlider.el);
        };
        this.closeSubcategorySlider = () => {
            var _a;
            const subcategorySlider = this.getSubcategorySliderById(this.openedCategoryId);
            if (!subcategorySlider)
                return;
            (_a = subcategorySlider.widget) === null || _a === void 0 ? void 0 : _a.destroy();
            subcategorySlider.el.classList.add('__hidden');
            this.rootSlider.classList.remove('__hidden');
            requestAnimationFrame(() => {
                var _a;
                (_a = this.rootSliderWidget) === null || _a === void 0 ? void 0 : _a.refresh();
            });
        };
        this.setRootCategory = (id, name, url) => {
            this.openedCategoryId = id;
            this.openedCategoryEl.innerHTML = name;
            this.openedCategoryEl.setAttribute('href', url);
        };
        this.showParentCategoryEl = (name) => {
            this.parentCategoryEl.innerHTML = name;
            this.parentCategoryEl.classList.remove('__empty');
        };
        this.closeParentCategoryEl = () => {
            this.parentCategoryEl.classList.add('__empty');
            this.parentCategoryEl.innerHTML = '';
        };
    }
    async init() {
        this.openedCategoryEl = this.getInnerEl('openedCategory')[0];
        this.parentCategoryEl = this.getInnerEl('parentCategory')[0];
        this.rootCategoryName = this.openedCategoryEl.innerText;
        this.rootCategoryUrl = this.openedCategoryEl.getAttribute('href');
        this.subcategoriesSliders = this.getSubcategoriesSliders();
        this.initRootSlider();
        this.initHandlers();
    }
    destroy() {
        var _a;
        (_a = this.rootSliderWidget) === null || _a === void 0 ? void 0 : _a.destroy();
        this.subcategoriesSliders.forEach(({ widget }) => { widget === null || widget === void 0 ? void 0 : widget.destroy(); });
    }
    getSubcategorySliderById(id) {
        return this.subcategoriesSliders.find((subcategory) => subcategory.id === id);
    }
    setUrl(url) {
        history.replaceState(null, '', url);
    }
    getInnerEl(selector) {
        return this.findElements(`%{w-CategoryLandingSlider/${selector}}`);
    }
};
default_1 = __decorate([
    register('CategoryLandingSlider')
], default_1);
export default default_1;
