import rAFThrottle from 'core/utils/rAFThrottle';
import generateId from 'core/utils/generateId';
import debounce from 'core/utils/debounce';
const TRANSITIONS = {
    up: [{
            to: 'down',
            cond: (viewportTop, point) => viewportTop > point,
            callback: (watcher) => watcher.onDown()
        }],
    down: [{
            to: 'up',
            cond: (viewportTop, point) => viewportTop < point,
            callback: (watcher) => watcher.onUp()
        }]
};
export function buildWayPointService(window, getScrollOffset) {
    let __watchers = [];
    let getPoints = (watchers) => requestAnimationFrame(() => {
        watchers.forEach(watcher => {
            watcher.$$point = watcher.getPoint();
        });
    });
    let watch = (points) => {
        let watchers = points.map(({ getPoint, once = false, onDown, onUp }) => {
            let $$state = 'up';
            return {
                $$id: generateId(),
                $$point: null,
                $$state,
                getPoint,
                once,
                onDown,
                onUp
            };
        });
        __watchers.push(...watchers);
        addEventListeners();
        getPoints(watchers);
        return watchers.map(({ $$id }) => $$id);
    };
    let unwatch = (watcherIds) => {
        __watchers = __watchers.filter(({ $$id }) => !watcherIds.includes($$id));
        if (__watchers.length === 0)
            removeEventListeners();
    };
    let performTransition = ({ to: newState, callback }, watcher) => {
        watcher.$$state = newState;
        callback(watcher);
    };
    let tick = rAFThrottle(() => {
        let viewportTop = getScrollOffset();
        getPoints(__watchers);
        __watchers.forEach(watcher => {
            let transition = TRANSITIONS[watcher.$$state].find(({ cond }) => {
                return cond(viewportTop, watcher.$$point);
            });
            if (transition) {
                performTransition(transition, watcher);
                if (watcher.once)
                    unwatch([watcher.$$id]);
            }
        });
    });
    let handleResize = debounce(100, () => {
        tick();
    });
    let addEventListeners = () => {
        window.addEventListener('load', tick);
        window.addEventListener('scroll', tick, { passive: true });
        window.addEventListener('resize', handleResize);
    };
    let removeEventListeners = () => {
        window.removeEventListener('load', tick);
        window.removeEventListener('scroll', tick);
        window.removeEventListener('resize', handleResize);
    };
    return { watch, unwatch };
}
const WayPointService = buildWayPointService(window, () => window.pageYOffset);
export default WayPointService;
