import Axios from 'axios';
import { uuidV4 } from 'core/utils/uuid';
import { getDirectUpload } from './api/directUploads';
import { Errors, FILENAME_WILDCARD } from './constants';
export function processUpload(file) {
    return new Promise(async (resolve, reject) => {
        let config;
        try {
            config = (await getDirectUpload()).data;
        }
        catch (_a) {
            return reject(Errors.SignError);
        }
        const data = new FormData();
        data.append('utf8', '✓');
        data.append('success_action_status', '');
        Object
            .entries(config)
            .forEach(([name, value]) => { if (name !== 'uri')
            data.append(name, value); });
        const filename = `${uuidV4()}.${file.name.split('.').pop()}`;
        data.append('file', file, filename);
        try {
            await Axios({
                method: 'POST',
                url: config.uri,
                data,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const filePath = config.key.replace(FILENAME_WILDCARD, filename);
            return resolve({ url: config.uri + filePath, key: filePath });
        }
        catch (_b) {
            reject(Errors.UploadError);
        }
    });
}
