var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Axios from 'axios';
import { Controller, register } from 'core/services/Controller';
import { LazyBlock } from 'core/services/LazyBlock';
import { connect, disconnect } from 'core/services/Controller';
var ThumbKey;
(function (ThumbKey) {
    ThumbKey["Default"] = "default";
    ThumbKey["Medium"] = "medium";
    ThumbKey["High"] = "high";
    ThumbKey["Standard"] = "standard";
    ThumbKey["Maxres"] = "maxres";
})(ThumbKey || (ThumbKey = {}));
const NOT_FOUND_THUMB_WIDTH = 120;
const YT_PREVIEW_URL = 'https://img.youtube.com/vi';
const YT_API_VIDEOS_URL = 'https://www.googleapis.com/youtube/v3/videos';
const VERSION_MAPPING = {
    [ThumbKey.Maxres]: 'maxresdefault',
    [ThumbKey.Standard]: 'sddefault',
    [ThumbKey.High]: 'hqdefault',
    [ThumbKey.Medium]: 'mqdefault',
    [ThumbKey.Default]: 'default'
};
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleClickYoutube = () => {
            requestAnimationFrame(() => {
                this.addSrcToEl(true);
                this.parent.classList.add('__clicked');
            });
        };
    }
    init() {
        switch (this.tagType) {
            case 'video':
                this.handleVideoLazyLoading();
                break;
            case 'iframe':
                this.handleYoutubeLazyLoading();
                break;
        }
    }
    destroy() {
        disconnect(this.parent);
    }
    get tagType() {
        return this.props.type || 'video';
    }
    get parent() {
        return this.el.parentElement;
    }
    async handleVideoLazyLoading() {
        await this.lazyAddSrcToEl();
        const video = this.el;
        video.play();
        this.el.classList.add('is-loaded');
    }
    async handleYoutubeLazyLoading() {
        if (!this.props.youtubeId)
            return this.addSrcToEl();
        const thumbUrl = await getPreviewUrl(this.props.youtubeId);
        this.parent.classList.add('b-LazyVideo');
        this.parent.appendChild(this.buildPreviewEl(thumbUrl));
        connect(this.parent);
    }
    // common helpers methods
    async lazyAddSrcToEl() {
        await LazyBlock.wait($(this.el));
        this.addSrcToEl();
    }
    addSrcToEl(autoPlay = false) {
        let src = this.el.dataset['src'];
        if (autoPlay) {
            src = src.includes('?') ? `${src}&autoplay=1` : `${src}?autoplay=1`;
        }
        this.el.setAttribute('src', src);
    }
    // youtube helpers methods
    buildPreviewEl(thumbUrl) {
        const div = document.createElement('div');
        div.classList.add('b-LazyVideo_preview');
        div.setAttribute('data-controller', 'LazyPhoto');
        div.setAttribute('data-props', '{}');
        div.setAttribute('data-src', thumbUrl);
        div.onclick = this.handleClickYoutube;
        return div;
    }
};
default_1 = __decorate([
    register('LazyVideo')
], default_1);
export default default_1;
function buildYtPreviewUrl(youtubeId, version) {
    return [YT_PREVIEW_URL, youtubeId, `${VERSION_MAPPING[version]}.jpg`].join('/');
}
function getPreviewUrl(youtubeId) {
    const maxResUrl = buildYtPreviewUrl(youtubeId, ThumbKey.Maxres);
    const downloadingImage = new Image();
    const promise = new Promise(resolve => {
        downloadingImage.onload = function (data) {
            const loadedImage = data.composedPath()[0];
            if (loadedImage.naturalWidth > NOT_FOUND_THUMB_WIDTH)
                return resolve(maxResUrl);
            const standardUrl = buildYtPreviewUrl(youtubeId, ThumbKey.Standard);
            const params = { id: youtubeId, key: Env.googleYtKey, part: 'snippet' };
            Axios.get(YT_API_VIDEOS_URL, { params })
                .then(({ data }) => {
                const snippet = data.items[0].snippet;
                if (!snippet)
                    return resolve(standardUrl);
                const thumb = Object.values(snippet.thumbnails).sort((thumb1, thumb2) => { var _a, _b; return ((_a = thumb2 === null || thumb2 === void 0 ? void 0 : thumb2.width) !== null && _a !== void 0 ? _a : 0) - ((_b = thumb1 === null || thumb1 === void 0 ? void 0 : thumb1.width) !== null && _b !== void 0 ? _b : 0); })[0];
                resolve((thumb === null || thumb === void 0 ? void 0 : thumb.url) || standardUrl);
            })
                .catch(e => {
                resolve(standardUrl);
                throw e;
            });
        };
    });
    downloadingImage.src = maxResUrl;
    return promise;
}
