import { h, Component } from 'preact';
import { Select as UISelect } from 'core/components/UI/Inputs/Select';
export class Select extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (e) => {
            const target = e.target;
            this.props.onChange(target.value);
        };
    }
    render() {
        const { field: { key, label, value, options, required, tip } } = this.props;
        return (h(UISelect, { name: key, label: label, value: value, options: options, required: required, onChange: this.handleChange, tooltip: tip }));
    }
}
