var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { buildBlankCollection } from './commits/paginateCollection';
import { View } from './store';
import { asyncLoadStyles } from 'core/utils/asyncLoadAssets';
const Opener = asyncComponent(() => import(/* webpackChunkName: "common_c-ChatsOpener" */ './components/Opener'));
let default_1 = class extends VController {
    get initState() {
        const { userId } = this.props;
        return {
            isChatOpen: false,
            loadingResources: [],
            chats: buildBlankCollection(),
            newMessages: [],
            currentView: { name: View.Chats, props: { userId } }
        };
    }
    init() {
        asyncLoadStyles('Chats').finally(() => this.renderer.render());
    }
    render() {
        return h(Opener, Object.assign({ initState: this.initState }, this.props));
    }
};
default_1 = __decorate([
    register('Chats')
], default_1);
export default default_1;
