import { h, Component } from 'preact';
import { Select } from 'core/components/UI/Inputs/Select';
import { OPERATION_LOAD_PICKUP_POINTS } from 'site/VControllers/components/Checkout/operations/form';
export class Terminal extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (e) => {
            const el = e.target;
            this.props.onChange(el.value);
        };
    }
    componentWillMount() {
        if (this.props.field.items.length === 0) {
            this.context.store.operate(OPERATION_LOAD_PICKUP_POINTS);
        }
    }
    render() {
        const { items, label, value, isRequired } = this.props.field;
        return (h(Select, { label: label, value: value, required: isRequired, options: items, onChange: this.handleChange }));
    }
}
