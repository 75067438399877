var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VController, register } from 'core/services/Controller';
import { LazyBlock } from 'core/services/LazyBlock';
import { dispatchCustomEvent, documentDispatch } from 'core/utils/events';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { RELOAD_ADMIN_PANEL } from 'site/controllers/AdminPanel';
import { CHANGE_EVENT, SHOW_PREVIEW_EVENT, HIDE_PREVIEW_EVENT } from './events';
import h, { scopeName } from './cssScope';
import { ViewType } from './constants';
import { buildEventName } from './utils/buildEventName';
export { CHANGE_EVENT, SHOW_PREVIEW_EVENT, HIDE_PREVIEW_EVENT, buildEventName, ViewType };
const ProductVariantsWidget = asyncComponent(() => import(/* webpackChunkName: "desktop_c-ProductVariants" */ './components/ProductVariantsWidget'));
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleSelect = (variant) => {
            dispatchCustomEvent(this.el, this.buildEventName(CHANGE_EVENT), variant);
            documentDispatch(RELOAD_ADMIN_PANEL);
        };
        this.handleShowPreview = (variant) => {
            if (!this.needHandleHoverEvents)
                return;
            dispatchCustomEvent(this.el, this.buildEventName(SHOW_PREVIEW_EVENT), variant);
        };
        this.handleHidePreview = () => {
            if (!this.needHandleHoverEvents)
                return;
            dispatchCustomEvent(this.el, this.buildEventName(HIDE_PREVIEW_EVENT));
        };
    }
    // Hover events are necessary only for the desktop version
    get needHandleHoverEvents() {
        return Env.version === 'desktop';
    }
    async init() {
        await LazyBlock.waitNative(this.el);
        super.init();
    }
    buildEventName(event) {
        return buildEventName(event, this.props.viewType);
    }
    render() {
        const { initialValue, variants, showedCount, useSelect = false, size, shownCount, type, version } = this.props;
        return (h(ProductVariantsWidget, { initialValue: initialValue, variants: variants, showedCount: showedCount, onSelect: this.handleSelect, onShowPreview: this.handleShowPreview, onHidePreview: this.handleHidePreview, useSelect: useSelect, size: size, type: type, shownCount: shownCount, version: version }));
    }
};
default_1 = __decorate([
    register(scopeName)
], default_1);
export default default_1;
