import { h, Component } from 'preact';
import Http from 'core/services/HttpClient';
import debounce from 'core/utils/debounce';
import { Text } from './Text';
export class Email extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (value) => {
            this.props.onChange(value);
            this.validateEmail(value);
        };
        // TODO: I really don't know for what it, but copy it from OrderForm
        this.validateEmail = debounce(500, (value) => {
            if (!value)
                return;
            Http.post('/orders/validate_email.json', { email: value });
        });
    }
    render() {
        const { field, name, disabled, formFields } = this.props;
        return (h(Text, { field: field, name: name, type: 'email', disabled: disabled, onChange: this.handleChange, formFields: formFields }));
    }
}
