import Http from 'core/services/Http';
import { COMMIT_SET_SPECIALTY } from '../commits/setSpecialty';
export function OPERATION_CHANGE_SPECIALTY(store) {
    return async ({ id, url }) => {
        let { categories } = store.getState();
        let nextCategories = categories[id]
            ? categories[id]
            : await Http.get(url, { params: { speciality_id: id } });
        store.commit(COMMIT_SET_SPECIALTY, { categories: nextCategories, id });
    };
}
