var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
import HttpRequestBatcher from 'core/services/HttpRequestBatcher';
import { dispatchCustomEvent } from 'core/utils/events';
import waitPageLoaded from 'core/utils/waitPageLoaded';
function createCheckExistenceBatcher({ url }) {
    return async (requests) => {
        const objectIds = requests.map(({ id }) => id);
        const result = await Http.post(url, {
            data: {
                object_ids: objectIds
            }
        });
        return (request) => result[request.id];
    };
}
const BATCHER_MAP = {
    Photo: createCheckExistenceBatcher({
        url: '/front_api/photos/related_products/batch_check_existence.json'
    }),
    Product: createCheckExistenceBatcher({
        url: '/front_api/products/related_products/batch_check_existence.json'
    })
};
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = () => {
            waitPageLoaded()
                .then(this.checkExistence)
                .then((flag) => {
                this.el.classList.toggle('is-ready', flag);
                this.el.addEventListener('click', this.onOpen);
            });
        };
        this.onOpen = async () => {
            const { RelatedProductsPopup } = await import(
            /* webpackChunkName: 'common__s-RelatedProductsPopup' */
            // @ts-ignore
            'core/services/RelatedProductsPopup');
            RelatedProductsPopup.open({
                objectType: this.props.objectType,
                objectId: this.props.objectId
            });
            dispatchCustomEvent(this.el, 'c-BtnRelatedProducts:open', {
                objectType: this.props.objectType,
                objectId: this.props.objectId
            });
        };
        this.checkExistence = () => {
            return HttpRequestBatcher.request(BATCHER_MAP[this.props.objectType], { id: this.props.objectId });
        };
    }
};
default_1 = __decorate([
    register('BtnRelatedProducts')
], default_1);
export default default_1;
