import { h, Component } from 'preact';
import NoticeService from 'core/services/NoticeService';
import { OPERATION_SET_OZON_PICKUP } from 'site/VControllers/components/Checkout/operations/ozon';
export class Ozon extends Component {
    constructor() {
        super(...arguments);
        this.receiveMessage = (event) => {
            const host = this.context.store.getState().delivery.ozonWidgetParams.host;
            if (event.origin !== host || typeof event.data !== 'string')
                return;
            const { id, address, messageType } = JSON.parse(event.data);
            if (messageType !== 'OzonRocketWidgetSelectDeliveryVariant' || !id)
                return;
            this.context.store.operate(OPERATION_SET_OZON_PICKUP, { id, address });
            NoticeService.info('Пункт выдачи выбран');
        };
    }
    componentDidMount() {
        window.addEventListener('message', this.receiveMessage, false);
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.receiveMessage, false);
    }
    get widgetSrc() {
        const { ozonWidgetParams, cityName } = this.context.store.getState().delivery;
        const { host, ...params } = ozonWidgetParams;
        return Object.entries(params).reduce((src, [key, value]) => {
            return src + '&' + key + '=' + (key === 'packages' ? JSON.stringify(value) : value);
        }, `${host}/lk/widget?defaultcity=${cityName}`);
    }
    get isInactive() {
        return Env.name === 'test' || !this.context.store.getState().delivery.ozonWidgetParams;
    }
    render() {
        if (this.isInactive)
            return h("div", null);
        return (h("iframe", { title: 'Ozon widget', style: 'width: 100%; height: 100%; min-width: 320px; min-height: 350px; border: none; overflow: hidden', src: this.widgetSrc }, "\u0411\u0440\u0430\u0443\u0437\u0435\u0440 \u043D\u0435 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442 iframe"));
    }
}
