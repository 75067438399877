export function COMMIT_CHANGE_FIELDS(state, fields, md5) {
    return {
        ...state,
        formFields: [
            ...fields
        ],
        formAddressMd5: md5
    };
}
export function COMMIT_SET_ORDER_INFO(state, orderInfo) {
    return { ...state, orderInfo };
}
export function COMMIT_SET_FIELDS_REF(state, key, el) {
    return { ...state, formFieldsRefs: { ...state.formFieldsRefs, [key]: el } };
}
