import { h, Component } from 'preact';
export class RadioGroup extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: 'claim'
        };
        this.onChange = (e) => {
            this.setState({ value: e.target.value });
            let productReturnSelector = document.querySelector('.product_return');
            let claimSelector = document.querySelector('.claim');
            claimSelector === null || claimSelector === void 0 ? void 0 : claimSelector.classList.toggle('__hide');
            productReturnSelector === null || productReturnSelector === void 0 ? void 0 : productReturnSelector.classList.toggle('__hide');
        };
    }
    render() {
        const { value } = this.state;
        return (h("div", { class: 's-InfoPage_b-RadioGroup' },
            h("div", { class: 's-InfoPage_b-RadioGroup_radioButton' },
                h("input", { id: 'claim', type: 'radio', value: 'claim', checked: value == 'claim', onChange: this.onChange }),
                h("label", { for: 'claim' }, " \u041F\u0440\u0435\u0442\u0435\u043D\u0437\u0438\u044F ")),
            h("div", { class: 's-InfoPage_b-RadioGroup_radioButton' },
                h("input", { id: 'product_return', type: 'radio', value: 'product_return', checked: value == 'product_return', onChange: this.onChange }),
                h("label", { for: 'product_return' }, " \u0412\u043E\u0437\u0432\u0440\u0430\u0442 \u0442\u043E\u0432\u0430\u0440\u0430 "))));
    }
}
