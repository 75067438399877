import chunkArray from 'core/utils/array/chunk';
cond.Any = true;
function cond(...args) {
    let condition = chunkArray(args, 2)
        .map(([when, to]) => {
        return { when, to };
    })
        .find(({ when }) => {
        return typeof when === 'function'
            ? when()
            : when;
    });
    return condition ? condition.to() : null;
}
export { cond };
export default cond;
