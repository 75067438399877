var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import DOMComponent from 'core/services/DOMComponent';
import { pushToDataLayer } from 'core/services/GTM';
import Http from 'core/services/HttpClient';
import { buildEventName, CHANGE_EVENT } from 'site/VControllers/Products/ProductVariants';
let ChangeableProductQuickView = class ChangeableProductQuickView extends Controller {
    constructor() {
        super(...arguments);
        this.responseStore = {};
        this.handleChange = async ({ detail }) => {
            this.el.classList.add('__loading');
            const { url, html, gtmPayload } = await this.getData(detail.id);
            window.history.replaceState({ $$type: 'ProductQuickView', productId: detail.id }, '', url);
            requestAnimationFrame(() => {
                this.el.innerHTML = html;
                connect(this.el);
                // TODO: Remove it after removing all DOMComponents
                // Location: jarvis/app/assets/javascripts/*/DOMComponents
                DOMComponent.connect(this.el);
                this.el.classList.remove('__loading');
            });
            pushToDataLayer(gtmPayload);
        };
    }
    get nativeListeners() {
        return [
            { selector: 'document', event: buildEventName(CHANGE_EVENT, this.props.viewType), handler: this.handleChange }
        ];
    }
    async getData(id) {
        const cachedData = this.responseStore[id];
        if (cachedData)
            return cachedData;
        const { data } = await Http.get(`${window.location.origin}/products/${id}/quick_view_variant.json`);
        this.responseStore[id] = data;
        return data;
    }
};
ChangeableProductQuickView = __decorate([
    register('ChangeableProductQuickView')
], ChangeableProductQuickView);
export { ChangeableProductQuickView };
