var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import Http from 'core/services/HttpClient';
import waitPageLoaded from 'core/utils/waitPageLoaded';
export const RELOAD_ADMIN_PANEL = 'AdminPanel:reload';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.requestedContents = {};
        this.handleOpen = () => {
            this.el.classList.add('is-opened');
            document.body.classList.add('has-modal-open');
        };
        this.handleClose = () => {
            this.el.classList.remove('is-opened');
            document.body.classList.remove('has-modal-open');
        };
        this.applyContent = (data) => {
            let contentEl = this.findElement('%{AdminPanel/content}');
            contentEl.innerHTML = data;
            connect(contentEl);
            this.el.classList.toggle('has-content', !!data);
        };
        this.load = async () => {
            const cachedData = this.requestedContents[window.location.href];
            if (cachedData)
                return this.applyContent(cachedData);
            let { data } = await Http.get('/front_api/admin_panel', { params: { referer: window.location.href } });
            this.requestedContents[window.location.href] = data;
            this.applyContent(data);
        };
    }
    init() {
        this
            .bindEvent('mouseenter', this.handleOpen)
            .bindEvent('mouseleave', this.handleClose);
        this.addNativeListeners();
        waitPageLoaded().then(() => this.load());
    }
    get nativeListeners() {
        return [
            { selector: 'document', event: RELOAD_ADMIN_PANEL, handler: this.load }
        ];
    }
};
default_1 = __decorate([
    register('AdminPanel')
], default_1);
export default default_1;
