import { YoutubePlayer } from './YoutubePlayer';
const VIDEO_PLAYERS_MAPPING = {
    youtube: YoutubePlayer
};
export class VideoPlayer {
    constructor({ type, ...props }) {
        let Player = VIDEO_PLAYERS_MAPPING[type];
        this.player = new Player(props);
    }
    play() {
        this.player.play();
    }
    pause() {
        this.player.pause();
    }
    destroy() {
        this.player.destroy();
    }
}
