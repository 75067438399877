import 'whatwg-fetch'
import 'core-js/es6/map'
import 'core-js/es6/promise'
import 'core-js/fn/array/includes'
import 'core-js/fn/array/find'
import 'core-js/fn/array/from'
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import 'core-js/fn/string/pad-start'
import 'core-js/fn/string/includes'
import 'object-assign-polyfill'
import 'regenerator-runtime/runtime'
import 'intersection-observer'
import 'setimmediate'
import 'ric'
import 'custom-event-polyfill'
import 'element-closest'
import './vendor/composedpath.polyfill'
import './vendor/visualViewport'

require('smoothscroll-polyfill').polyfill()
require('raf').polyfill()
