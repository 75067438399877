import { Component, h } from 'preact';
import { baseAutocomplete } from 'site/VControllers/Autocomplete/components/Hoc/baseAutocomplete';
import { documentDispatch, COUNTRY_ID_UPDATED_EVENT } from 'core/utils/events';
const CountryAutocomplete = baseAutocomplete('/front_api/autocomplete/countries', 'Страна', null, {
    extraQueryParams: () => ({ delivery: true })
});
export class Country extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            // Store it here because creating ref is async,
            // but we need to rerender autocomplete after creating it
            inputRef: undefined
        };
        this.handleChange = (item, prevItem) => {
            if (item.id === prevItem.id)
                return;
            this.props.onChange(item.id);
            if (item.id)
                documentDispatch(COUNTRY_ID_UPDATED_EVENT, { id: item.id });
        };
        this.handleCreatingInputRef = (el) => {
            this.setState({ inputRef: el });
        };
    }
    render() {
        const { field, name, useHidden, ...autocompleteProps } = this.props;
        const { inputRef } = this.state;
        return (h("div", { className: 'ui-Input __autocomplete __text __select' },
            useHidden && h("input", { ref: this.handleCreatingInputRef, name: name, type: 'text', value: field.value }),
            (!useHidden || inputRef) && (h(CountryAutocomplete, Object.assign({ isRequired: field.isRequired, inputEl: inputRef, value: field.value, label: field.label }, autocompleteProps, { items: field.items, onChange: this.handleChange })))));
    }
}
