import { h, render } from 'preact'

import { createVDOMRenderer }    from './createVDOMRenderer'
import { createVDOMComponent }   from './createVDOMComponent'
import { cssScope, createScope, getScopedClass } from './cssScope'

let renderVDOM = (el, renderFn) => {
  render(renderFn(), el)
}

export {
  renderVDOM,
  createVDOMComponent,
  createVDOMRenderer,
  cssScope,
  createScope,
  getScopedClass,
  h
}
