import { h, Component } from 'preact';
import { Text } from './templates/Text';
import { Select } from './templates/Select';
import { Email } from './templates/Email';
import { Phone } from './templates/Phone';
import { Files } from './templates/Files';
import { DirectUploadFiles } from './templates/DirectUploadFiles';
import { Password } from './templates/Password';
import { Checkboxes } from './templates/Checkboxes';
// Set any because setting type `ITemplateProps` doesn't work when a template has extended options
const TYPES_TO_FIELDS = {
    text: Text,
    select: Select,
    email: Email,
    phone: Phone,
    directUploadFiles: DirectUploadFiles,
    files: Files,
    password: Password,
    checkboxes: Checkboxes
};
export class Field extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (value) => {
            const { field: { key }, onChange } = this.props;
            onChange(key, value);
        };
    }
    render() {
        const { field: { type, ...field } } = this.props;
        const FieldTemplate = TYPES_TO_FIELDS[type];
        if (!FieldTemplate)
            return null;
        return (h(FieldTemplate, { field: field, onChange: this.handleChange }));
    }
}
