var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.handleCoverUpload = ({}, { url }) => {
            this.el.style.backgroundImage = `url(${url})`;
        };
    }
    init() {
        var _a;
        if (((_a = Env.user) === null || _a === void 0 ? void 0 : _a.id) === this.props.userId)
            this.el.classList.add('has-actions');
        $(document).on('userProfileCoverUploader.upload', this.handleCoverUpload);
    }
};
default_1.shouldInit = () => Env.isLogged;
default_1 = __decorate([
    register('UserProfileCover')
], default_1);
export default default_1;
