var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this._itemsCount = 0;
        this.handleCheckoutContextUpdate = (state) => {
            this.itemsCount = state.items.length;
        };
    }
    async init() {
        const { default: checkoutContext } = await import(
        /* webpackChunkName: 'common__c-checkoutContext' */
        'site/VControllers/components/Checkout/context');
        this.checkoutContext = checkoutContext;
        this.checkoutContextUuid = this.checkoutContext.statelessSubscribe(this.handleCheckoutContextUpdate);
        this.itemsCount = Env.cart.itemsCount;
    }
    destroy() {
        this.checkoutContext.unsubscribe(this.checkoutContextUuid);
    }
    render() {
        this.el.setAttribute('data-items-count', this.itemsCount.toString());
    }
    set itemsCount(count) {
        this._itemsCount = count;
        this.render();
    }
    get itemsCount() {
        return this._itemsCount;
    }
};
default_1 = __decorate([
    register('CartItemsCounter')
], default_1);
export default default_1;
