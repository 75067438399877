import Store from 'core/services/Store';
export var View;
(function (View) {
    View["Chats"] = "chats";
    View["Messages"] = "messages";
})(View || (View = {}));
export default function (onChangeState, initialState) {
    return Store({
        props: { initialState },
        callbacks: {
            CALLBACK_AFTER_COMMIT: onChangeState
        }
    });
}
