function addEnvVars(config) {
    return {
        ...config,
        headers: {
            ...config.headers,
            'Authorization': `Token ${Env.daData.token}`
        }
    };
}
export default [addEnvVars];
