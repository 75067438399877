import Http from 'core/services/HttpClient';
export function changeQuantity(productId, quantity, withoutDeliveryResult = false) {
    return Http.get(`/cart/set/${productId}`, { params: { quantity, withoutDeliveryResult } });
}
export function removeItem(productId, withoutDeliveryResult = false) {
    return Http.get(`/cart/remove/${productId}`, { params: { withoutDeliveryResult } });
}
export function bulkRemoveItem(products) {
    return Http.post('/cart/bulk_remove', { products });
}
export function addItem(productId, quantity, refresh, paymentMethod, withoutDeliveryResult) {
    const params = { quantity };
    if (refresh)
        params.refresh = true;
    if (paymentMethod)
        params.paymentMethod = paymentMethod;
    if (withoutDeliveryResult)
        params.withoutDeliveryResult = withoutDeliveryResult;
    return Http.get(`/cart/add/${productId}.json`, { params });
}
export function changeSelected(productIds, selected) {
    return Http.post('/cart/bulk_change_selected', { productIds, selected });
}
export function assemblyAdd(productId) {
    return Http.post('/cart/item_services', { productId });
}
export function assemblyDelete(productId) {
    return Http.delete('/cart/item_services', { data: { productId } });
}
