var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
// @ts-ignore
import FavoriteListService from 'core/services/FavoriteListService';
import waitPageLoaded from 'core/utils/waitPageLoaded';
import { fetchCountersBatcher } from './Counters';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = () => {
            if (Env.isLogged) {
                this.el.addEventListener('click', this.onAdd);
            }
            if (this.props.count !== undefined) {
                this.setCount(this.props.count);
            }
            else {
                waitPageLoaded().then(this.fetchCount);
            }
        };
        this.onAdd = async (e) => {
            e.preventDefault();
            const [collections, { AddToFavCollectionModal }] = await Promise.all([
                Http.get('/front_api/my/favorite_collections.json'),
                import(
                /* webpackChunkName: 'common__m-AddToFavCollection' */
                // @ts-ignore
                'site/modals/AddToFavCollectionModal')
            ]);
            AddToFavCollectionModal({
                props: { collections },
                callbacks: {
                    CALLBACK_ADD_TO_EXISTING: ({ id }) => {
                        return Http.post('/front_api/my/favorite_collections/add_to_existing', {
                            data: {
                                id,
                                object_type: this.props.objectType,
                                object_id: this.props.objectId
                            }
                        }).then(this.fetchCount);
                    },
                    CALLBACK_ADD_TO_NEW: ({ title }) => {
                        return Http.post('/front_api/my/favorite_collections/add_to_new', {
                            data: {
                                title,
                                object_type: this.props.objectType,
                                object_id: this.props.objectId
                            }
                        }).then(this.fetchCount);
                    }
                }
            });
        };
        this.setCount = (count) => {
            this.el.setAttribute('data-count', count.toString());
            const counter = this.findElement('%{c-BtnAddToFavCollection/counter}');
            if (counter) {
                counter.textContent = count.toString();
            }
        };
        this.fetchCount = () => {
            return FavoriteListService.fetchAddedCount({
                objectType: this.props.objectType,
                objectId: this.props.objectId
            })
                .then(this.setCount)
                .then(fetchCountersBatcher([
                {
                    id: this.props.objectId,
                    type: this.props.objectType
                }
            ]));
        };
    }
};
default_1 = __decorate([
    register('BtnAddToFavCollection')
], default_1);
export default default_1;
