export function COMMIT_TOGGLE_CATEGORY(state, categoryId) {
    let currentSpecialty = state.currentSpecialtyId;
    let currentCategories = state.categories[currentSpecialty];
    let nextCategories = currentCategories.map((category) => {
        return category.id === categoryId
            ? { ...category, isActive: !category.isActive }
            : category;
    });
    return { ...state,
        categories: { ...state.categories,
            [currentSpecialty]: nextCategories
        }
    };
}
