import Axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { ContentType } from '../../constants';
import requestInterceptors from './interceptors/request';
import responseInterceptors from './interceptors/response';
const BASE_CONFIG = {
    adapter: jsonpAdapter,
    timeout: 5000
};
const axiosInstance = Axios.create(BASE_CONFIG);
requestInterceptors.forEach(interceptor => {
    axiosInstance.interceptors.request.use(interceptor);
});
responseInterceptors.forEach(interceptor => {
    axiosInstance.interceptors.response.use(interceptor);
});
let queryProcessed = false;
export const client = {
    get: queryRequest,
    get isServiceAlive() {
        if (this._isServiceAvailable !== undefined)
            return this._isServiceAvailable;
        if (queryProcessed) {
            return true;
        }
        queryProcessed = true;
        const prevOnError = window.onerror;
        // sometimes Kladr api return text format as jsonp response and we have unresolved promise
        window.onerror = () => {
            this._isServiceAvailable = false;
            window.onerror = prevOnError;
            return false;
        };
        queryRequest({ params: { cityId: '0', contentType: ContentType.City } }).finally(() => {
            this._isServiceAvailable = true;
            window.onerror = prevOnError;
        });
        return true;
    }
};
function queryRequest(config) {
    // jsonpAdapter ignores baseURL and url from config
    return axiosInstance.get(Env.kladr.url, config);
}
