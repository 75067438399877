import delay from 'core/utils/delay';
import { changeQuantity, removeItem, bulkRemoveItem, changeSelected, addItem, assemblyAdd, assemblyDelete } from '../api/cartItems';
import { COMMIT_BULK_REMOVE, COMMIT_CHANGE_ITEM_CHANGING, COMMIT_CHANGE_ITEM_QUANTITY, COMMIT_CHANGE_SELECT_ITEMS, COMMIT_REMOVE_ITEM, COMMIT_ADD_ITEM, COMMIT_CHANGE_ASSEMBLY, COMMIT_ADDED_ASSEMBLY_ANIMATING } from '../commits/cartItems';
import { COMMIT_CHANGE_LOADING } from '../commits/changeLoading';
export function OPERATION_CHANGE_ITEM_QUANTITY(store) {
    return function ({ productId, quantity, withoutDeliveryResult }) {
        store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, true);
        return changeQuantity(productId, quantity, withoutDeliveryResult)
            .then(({ data }) => {
            const payload = [productId, data.item ? data.item.quantity : quantity, data, withoutDeliveryResult];
            if (data.error === 'requested_quantity_not_available') {
                payload.push({ ...data.modal, type: 'SuggestedProduct' });
            }
            return store.commit(COMMIT_CHANGE_ITEM_QUANTITY, ...payload);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, false);
        });
    };
}
export function OPERATION_REMOVE_ITEM(store) {
    return function ({ productId, withoutDeliveryResult }) {
        store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, true);
        return removeItem(productId, withoutDeliveryResult)
            .then(({ data }) => {
            return store.commit(COMMIT_REMOVE_ITEM, productId, data, withoutDeliveryResult);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, false);
        });
    };
}
export function OPERATION_ADD_ITEM(store) {
    return function ({ productId, quantity, refresh = true, paymentMethod, fromTarget, withoutDeliveryResult }) {
        store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, true);
        return addItem(productId, quantity, refresh, paymentMethod, withoutDeliveryResult)
            .then(({ data }) => {
            const payload = [data.item, data, fromTarget];
            if (data.error === 'requested_quantity_not_available') {
                payload.push({ ...data.modal, type: 'SuggestedProduct' });
            }
            store.commit(COMMIT_ADD_ITEM, ...payload);
            return data;
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_ITEM_CHANGING, productId, false);
        });
    };
}
export function OPERATION_BULK_REMOVE_ITEMS(store) {
    return function () {
        const { items } = store.getState();
        const selectedProducts = items
            .filter(item => item.selected)
            .map(item => ({ id: item.productId }));
        store.commit(COMMIT_CHANGE_LOADING, true);
        bulkRemoveItem(selectedProducts)
            .then(({ data }) => {
            const { removedIds } = data;
            store.commit(COMMIT_BULK_REMOVE, removedIds, data);
        })
            .catch(() => store.commit(COMMIT_CHANGE_LOADING, false));
    };
}
export function OPERATION_BULK_CHANGE_SELECT_ITEM(store) {
    return function ({ productIds, checked }) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        changeSelected(productIds, checked)
            .then(({ data }) => {
            const { changedIds } = data;
            store.commit(COMMIT_CHANGE_SELECT_ITEMS, checked, changedIds, data);
        })
            .catch(() => store.commit(COMMIT_CHANGE_LOADING, false));
    };
}
export function OPERATION_CHANGE_ASSEMBLY(store) {
    return function ({ productId, active }) {
        const apiRequest = active ? assemblyAdd : assemblyDelete;
        store.commit(COMMIT_CHANGE_LOADING, true);
        apiRequest(productId)
            .then(({ data }) => {
            store.commit(COMMIT_ADDED_ASSEMBLY_ANIMATING, true);
            delay(200).then(() => {
                store.commit(COMMIT_ADDED_ASSEMBLY_ANIMATING, false);
            });
            store.commit(COMMIT_CHANGE_ASSEMBLY, data);
        })
            .catch(() => store.commit(COMMIT_CHANGE_LOADING, false));
    };
}
