import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { withStyleLoading } from 'core/components/Hoc/withStyleLoading';
import { Component } from 'preact';
import { getProductsSet } from './api/productsSet';
import h from './cssScope';
const CheaperTogether = asyncComponent(() => import(/* webpackChunkName: "site_c-CheaperTogether" */ './components/CheaperTogether'), 'CheaperTogether');
const StyledCheaperTogether = withStyleLoading(CheaperTogether, 'CheaperTogether');
export class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isLoading: true,
            products: null,
            productsSetId: null,
            isDisabled: false
        };
        this.handleCheckoutContextUpdate = (_state) => {
            const { productsSetId } = this.state;
            if (!productsSetId)
                return;
            this.setState({ isDisabled: this.isProductsSetAddedToCart(productsSetId) });
        };
        this.isProductsSetAddedToCart = (productsSetId) => {
            return this.checkoutContext
                .getContext().store
                .getState().items
                .some(item => item.productsSetId === productsSetId);
        };
        this.handleCheck = (productId) => () => {
            this.setState(prevState => ({
                products: prevState.products.map(product => {
                    if (product.id === productId) {
                        return {
                            ...product,
                            isChecked: !product.isChecked
                        };
                    }
                    return product;
                })
            }));
        };
        this.handleCheckProductsSetAdded = () => {
            this.setState({ isDisabled: true });
        };
    }
    async componentDidMount() {
        const { data: { products: resProducts, productsSetId } } = await getProductsSet(this.props.productId);
        if (!resProducts || !productsSetId) {
            this.setState({ isLoading: false });
            return;
        }
        const { state: { items } } = await this.initCheckout();
        const isProductsSetAddedToCart = this.isProductsSetAddedToCart(productsSetId);
        this.setState({
            products: resProducts.map(product => {
                return {
                    ...product,
                    hasDiscount: !!product.discount,
                    discountPrice: String(product.oldPrice),
                    isChecked: isProductsSetAddedToCart ? Boolean(items.find(item => item.productId === product.id)) : true
                };
            }),
            productsSetId,
            isLoading: false,
            isDisabled: isProductsSetAddedToCart
        });
    }
    async initCheckout() {
        const { default: checkoutContext } = await import(
        /* webpackChunkName: 'common__c-checkoutContext' */
        'site/VControllers/components/Checkout/context');
        this.checkoutContext = checkoutContext;
        await this.checkoutContext.init();
        this.checkoutContextUuid = await this.checkoutContext.subscribe(this.handleCheckoutContextUpdate);
        return {
            state: this.checkoutContext.getContext().store.getState()
        };
    }
    render() {
        const { isLoading, products, productsSetId, isDisabled } = this.state;
        const { productId } = this.props;
        return !isLoading && products && productsSetId && (h("div", { className: 'sb-CheaperTogether' },
            h("h2", { className: 'sb-CheaperTogether_title' }, "\u0412\u043C\u0435\u0441\u0442\u0435 \u0434\u0435\u0448\u0435\u0432\u043B\u0435:"),
            h(StyledCheaperTogether, { products: products, productId: productId, productsSetId: productsSetId, isDisabled: isDisabled, handleCheckProductsSetAdded: this.handleCheckProductsSetAdded, handleCheck: this.handleCheck })));
    }
}
