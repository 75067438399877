import cast from './cast';
// 300000 => 300 000
export function format(value, options = {}) {
    const { format = 'ru-Ru', unit = '' } = options;
    const result = [cast(value).toLocaleString(format)];
    if (unit)
        result.push(unit);
    return result.join('\u00A0');
}
export const currencyFormat = (value) => format(value, { unit: 'Р' });
export const parseCurrency = (currency) => parseInt(currency.replace(/\s/g, ''));
const spaceReg = /\u00A0/g;
export function currencyFormatWithCaretPosition(target) {
    const prevStart = target.selectionStart;
    const prevEnd = target.selectionEnd;
    const prevValueLength = target.value.length;
    const prevSpacesCount = (target.value.match(spaceReg) || []).length;
    const value = parseCurrency(target.value);
    target.value = Number.isNaN(value) ? '' : currencyFormat(value);
    requestAnimationFrame(() => {
        const spacesCount = (target.value.match(spaceReg) || []).length;
        if (prevSpacesCount < spacesCount && spacesCount > 1 && prevValueLength !== target.value.length) {
            target.selectionStart = prevStart + 1;
            target.selectionEnd = prevEnd + 1;
        }
        else if (prevSpacesCount > spacesCount && prevValueLength !== target.value.length) {
            target.selectionStart = prevStart - 1;
            target.selectionEnd = prevEnd - 1;
        }
        else {
            target.selectionStart = prevStart;
            target.selectionEnd = prevEnd;
        }
    });
    return value;
}
export default format;
