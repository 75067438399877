import { autocompleteCities } from '../api/cities';
import { DEFAULT_COUNTRY_ID } from 'site/global/constants';
import { ContentType } from '../constants';
import { getCities } from './KladrApi/api/cities';
import { client } from './KladrApi/client';
export function fetchCityItems(params) {
    if (params.countryId !== DEFAULT_COUNTRY_ID)
        return autocompleteCities(params);
    return new Promise(async (resolve, reject) => {
        var _a;
        if (!params.query)
            return resolve({ data: [] });
        if (!client.isServiceAlive) {
            return autocompleteCities(params).then(resolve).catch(reject);
        }
        try {
            const { data: { result } } = await getCities(prepareQuery(params.query));
            resolve({ data: buildTree(result || []) });
        }
        catch (error) {
            // to fallback to our autocomplete
            autocompleteCities(params).then(resolve).catch(reject);
            (_a = window.bugsnagClient) === null || _a === void 0 ? void 0 : _a.notify(new Error(`[Kladr response error] - ${JSON.stringify(error)}`));
        }
    });
}
// to replace added typeShort
function prepareQuery(input) {
    return input.replace(/^[А-Яа-я]\.\s/, '').trim();
}
function buildTree(cities) {
    const mapping = cities.reduce((result, item) => {
        var _a;
        const parent = (_a = item.parents) === null || _a === void 0 ? void 0 : _a.find(({ contentType }) => contentType === ContentType.Region);
        const parentId = (parent || item).id;
        const parentItem = result[parentId] || buildItem(parent || item);
        if (parentId !== item.id) {
            parentItem.children.push(buildItem(item));
        }
        return { ...result, [parentId]: parentItem };
    }, {});
    return Object.values(mapping);
}
function buildItem(item) {
    return {
        id: item.id,
        text: `${item.typeShort}. ${item.name}`,
        children: []
    };
}
