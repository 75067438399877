import { Component, createRef } from 'preact';
import classNames from 'classnames';
import h from '../../cssScope';
import { COMMIT_SET_PERIOD } from '../../store/commits/deliveryDate';
export class DeliveryPeriod extends Component {
    constructor() {
        super(...arguments);
        this.buttonRef = createRef();
        this.handlePeriodClick = () => {
            var _a;
            const { from, to } = this.props;
            const period = (_a = this.buttonRef.current) === null || _a === void 0 ? void 0 : _a.value;
            this.context.store.commit(COMMIT_SET_PERIOD, { period, from, to });
        };
    }
    render() {
        const { period, from, to, isPeriodActive } = this.props;
        return (h("button", { ref: this.buttonRef, className: classNames('sb-DeliveryDate_period', { '__active': isPeriodActive }), value: period, type: 'button', onClick: this.handlePeriodClick },
            "\u0421 ",
            from,
            " \u0434\u043E ",
            to));
    }
}
