import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
const convert = (field) => {
    const { value } = field;
    if (value === 'online_sbp') {
        return 'sbp';
    }
    if (value === 'online') {
        return 'bank_card';
    }
    return 'bank_card';
};
export function COMMIT_SET_CONFIRMATION_TOKEN(state, payment) {
    const { formFields } = state;
    const paymentMethodField = formFields.find(field => field.key === FieldsKeys.PaymentMethod);
    return {
        ...state,
        payment: {
            method: convert(paymentMethodField),
            ...payment
        }
    };
}
