var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import { dispatchCustomEvent } from 'core/utils/events';
import { CITY_CHANGE_EVENT_NAME } from './cities';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
const CountryAutocomplete = asyncComponent(() => import(/* webpackChunkName: "site_c-AutocompleteCountries" */ './components/Countries'), 'CountryAutocomplete');
export const COUNTRY_CHANGE_EVENT_NAME = 'c-CountryAutocomplete:changeValue';
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleChangeItem = (item, prevItem) => {
            if (prevItem.id === item.id)
                return;
            dispatchCustomEvent(this.el, CITY_CHANGE_EVENT_NAME, { item: { id: undefined, text: '' }, countryId: item.id });
        };
    }
    render() {
        const inputEl = this.el.querySelector('input');
        return inputEl ? h(CountryAutocomplete, Object.assign({ inputEl: inputEl }, this.props, { onChange: this.handleChangeItem, isRequired: true })) : null;
    }
};
default_1 = __decorate([
    register('CountryAutocomplete')
], default_1);
export default default_1;
