var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import { Layout } from './components/Layout';
import { prepareUrl } from 'site/VControllers/components/Checkout/services/statefullCart';
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleClose = () => {
            const referrer = prepareUrl(this.props.referrer);
            const fallback = prepareUrl('/products');
            const newUrl = (referrer && referrer !== window.location.href) ? referrer : null;
            window.location.href = newUrl || fallback || '/products';
        };
    }
    render() {
        return h(Layout, { onClose: this.handleClose });
    }
};
default_1 = __decorate([
    register('SinglePageCheckoutV2')
], default_1);
export default default_1;
