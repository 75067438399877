function checkTokenExists(config) {
    if (!Env.kladr.token)
        return config;
    return {
        ...config,
        timeout: 2000,
        params: { ...config.params, token: Env.kladr.token }
    };
}
export default [checkTokenExists];
