import Axios from 'axios';
import { stringify } from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import toArray from 'core/utils/array/toArray';
import { decamelize } from './transformers';
const STATIC_KEYS_REGEX = /^\w+-(\w+-?)+$/; // dont't transform keys witch contains '-' symbol
let { defaults: { transformRequest = [], transformResponse = [] } } = Axios;
export const DEFAULT_AXIOS_CONFIG = {
    withCredentials: true,
    crossdomain: true,
    transformRequest: [decamelize, ...toArray(transformRequest)],
    transformResponse: [...toArray(transformResponse), camelizeKeys],
    crossDomain: true,
    baseURL: '/',
    paramsSerializer: (params) => stringify(decamelizeKeys(params), { arrayFormat: 'brackets' })
};
export const AXIOS_CONFIG_V2 = {
    ...DEFAULT_AXIOS_CONFIG,
    transformResponse: [
        ...toArray(transformResponse),
        (obj) => camelizeKeys(obj, (key, convert) => {
            // if uuidV4 don't transform
            return STATIC_KEYS_REGEX.test(key) ? key : convert(key);
        })
    ]
};
