var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import { dispatchCustomEvent } from 'core/utils/events';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.popup = this.findElement('%{c-PhotoRelatedProducts/popup}');
        this.fetchProducts = () => {
            return Http.get(`/front_api/photos/${this.props.photoId}/related_products`);
        };
        this.renderProducts = (products) => requestAnimationFrame(() => {
            const popupItems = this.findElement('%{c-PhotoRelatedProducts/popup/items}', this.popup);
            popupItems.innerHTML = products.join('');
            if (products.length === 0) {
                this.popup.classList.add('__empty');
            }
            connect(this.popup);
        });
        this.handleClose = () => requestAnimationFrame(() => {
            this.el.classList.remove('__visible');
        });
        this.handleOpen = () => {
            requestAnimationFrame(() => {
                this.el.classList.add('__visible');
                this.popup.classList.add('__loading');
                this.popup.classList.remove('__empty');
            });
            Promise.resolve()
                .then(this.fetchProducts)
                .then((products) => requestAnimationFrame(() => {
                this.popup.classList.remove('__loading');
                dispatchCustomEvent(this.el, 'c-PhotoRelatedProducts:open', { photoId: this.props.photoId });
                this.renderProducts(products);
            }));
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-PhotoRelatedProducts/open}', event: 'click', handler: this.handleOpen },
            { selector: '%{c-PhotoRelatedProducts/popup/close}', event: 'click', handler: this.handleClose }
        ];
    }
};
default_1 = __decorate([
    register('PhotoRelatedProducts')
], default_1);
export default default_1;
