import { h, Component } from 'preact';
import Portal from 'preact-portal';
import htmlClassNames from 'core/utils/htmlClassNames';
import rAFThrottle from 'core/utils/rAFThrottle';
import { getScrollParent } from 'core/utils/getScrollParent';
export var Position;
(function (Position) {
    Position["Auto"] = "auto";
    Position["Top"] = "top";
    Position["Bottom"] = "bottom";
})(Position || (Position = {}));
const MOB_HORIZONTAL_OFFSET = 20;
const TOOLTIP_MAX_OFFSET = 10;
export default class Tooltip extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            active: false
        };
        this.node = null;
        this.handleRef = (el) => {
            this.node = el;
            if (!this.parent)
                return;
            if (this.node) {
                iterateThroughScrollParents(this.parent, (node) => node.addEventListener('scroll', this.handleScroll, { passive: true }));
                this.parent.addEventListener('mouseover', this.handleMouseover);
                this.parent.addEventListener('mouseleave', this.handleMouseout);
                this.parent.addEventListener('touchstart', this.handleTouchEvent, { passive: true });
            }
            else {
                iterateThroughScrollParents(this.parent, (node) => node.removeEventListener('scroll', this.handleScroll));
                this.parent.removeEventListener('touchstart', this.handleTouchEvent);
                this.parent.removeEventListener('mouseover', this.handleMouseover);
                this.parent.removeEventListener('mouseleave', this.handleMouseout);
            }
        };
        this.handleScroll = rAFThrottle(() => {
            this.handleMouseout();
        }, 300);
        this.handleTouchEvent = () => {
            if (!this.state.active)
                return this.handleMouseover();
            this.handleMouseout();
        };
        this.handleMouseover = () => {
            this.calcPosition();
            if (!this.state.active)
                this.setState({ active: true });
        };
        this.handleMouseout = () => {
            if (this.state.active)
                this.setState({ active: false });
        };
        this.calcPosition = () => {
            if (!(this.node && this.parent))
                return;
            const pos = this.parent.getBoundingClientRect();
            this.node.style.left = `${this.calcLeftPosition(pos)}px`;
            switch (this.position) {
                case Position.Top:
                    this.node.style.top = `${pos.top - this.node.offsetHeight}px`;
                    break;
                case Position.Bottom:
                    this.node.style.top = `${pos.top + pos.height}px`;
                    break;
            }
        };
        this.calcAutoPosition = () => {
            if (!(this.node && this.parent))
                return Position.Top;
            const pos = this.parent.getBoundingClientRect();
            return (pos.top - TOOLTIP_MAX_OFFSET >= this.node.offsetHeight) ? Position.Top : Position.Bottom;
        };
        this.calcLeftPosition = (pos) => {
            let leftPosition = pos.left;
            if (Env.version === 'mobile') {
                leftPosition += pos.width - this.node.offsetWidth;
                if (leftPosition < 0 || leftPosition > pos.left) {
                    return MOB_HORIZONTAL_OFFSET;
                }
                else {
                    return leftPosition;
                }
            }
            else {
                return leftPosition;
            }
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    get position() {
        if (this.props.position === Position.Auto)
            return this.calcAutoPosition();
        return this.props.position || this.calcAutoPosition();
    }
    get parent() {
        var _a;
        return (_a = this.base) === null || _a === void 0 ? void 0 : _a.parentElement;
    }
    render() {
        const { label, className } = this.props;
        const rootClassName = htmlClassNames('ui-Tooltip', `__${this.position}`, className, { __active: this.state.active });
        return (h(Portal, { into: 'body' },
            h("div", { ref: this.handleRef, className: rootClassName },
                h("div", { className: 'ui-Tooltip_in' }, label))));
    }
}
function iterateThroughScrollParents(node, callback) {
    while (node) {
        node = getScrollParent(node);
        if (node) {
            callback(node);
            node = node.parentNode;
        }
    }
}
