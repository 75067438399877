const NOT_SCROLL_OVERFLOW = ['visible', 'hidden'];
export function getScrollParent(node) {
    if (!(node && node instanceof HTMLElement))
        return null;
    const { overflowY, overflowX } = window.getComputedStyle(node);
    const isScrollable = !NOT_SCROLL_OVERFLOW.includes(overflowY) || !NOT_SCROLL_OVERFLOW.includes(overflowX);
    if (isScrollable && (node.scrollHeight >= node.clientHeight || node.scrollWidth >= node.clientWidth)) {
        return node;
    }
    return getScrollParent(node.parentNode);
}
