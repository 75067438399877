var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { render, h } from 'preact';
import { Controller, register } from 'core/services/Controller';
let default_1 = class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.handleSuccess = ({ starHTML, humanCount }) => {
            const countEl = this.findElement('%{UserRating/count}');
            const starsEl = this.findElement('%{UserRating/stars}');
            if (countEl) {
                countEl.innerText = humanCount;
            }
            if (starsEl) {
                const starContainer = document.createElement('div');
                starContainer.innerHTML = starHTML.trim();
                this.el.replaceChild(starContainer.firstChild, starsEl);
            }
        };
        this.renderForm = (e) => {
            e.preventDefault();
            // Waiting finish of bubbling
            setTimeout(async () => {
                const { Form } = await import(
                /* webpackChunkName: "site_c-UserRatingForm" */
                './components/Form');
                this.rootComponentEl = render(h(Form, {
                    ...this.props,
                    onClose: this.destroyForm,
                    onSuccess: this.handleSuccess
                }), document.body);
                document.body.append(this.rootComponentEl);
            }, 0);
        };
        this.destroyForm = () => {
            // https://github.com/preactjs/preact/issues/1151#issuecomment-398725321
            render(null, document.body, this.rootComponentEl);
        };
    }
    get nativeListeners() {
        return [{ selector: '%{UserRating/rate}', event: 'click', handler: this.renderForm }];
    }
};
default_1.shouldInit = ({}, props) => Env.isLogged && Env.user.id !== props.userId;
default_1 = __decorate([
    register('UserRating')
], default_1);
export default default_1;
