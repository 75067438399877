var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            if (!this.props.orderReview.isServiceReviewed) {
                await this.makeOrderReview();
            }
            await this.makeProductsReview();
        };
        this.makeOrderReview = async () => {
            const { AddOrderReviewModal } = await import(
            /* webpackChunkName: 'common__m-AddOrderReviewModal' */
            'site/modals/AddOrderReviewModal');
            return new Promise(resolve => {
                const modal = AddOrderReviewModal({
                    props: {
                        token: this.props.token,
                        userId: this.props.orderReview.userId,
                        orderId: this.props.orderReview.orderId,
                        ratings: this.props.orderReview.reviewFactorRatings,
                        currentView: 'ReviewForm'
                    },
                    callbacks: {
                        CALLBACK_CANCEL: () => {
                            modal.destroy();
                            resolve();
                        },
                        CALLBACK_FINISH: () => {
                            modal.destroy();
                            resolve();
                        }
                    }
                });
            });
        };
        this.makeProductsReview = () => {
            return this.props.productReviews.reduce((queue, productReview) => {
                return queue.then(() => this.openProductReviewModal(productReview));
            }, Promise.resolve());
        };
        this.openProductReviewModal = async (productReview) => {
            const { AddProductReviewModal } = await import(
            /* webpackChunkName: 'common__m-AddProductReviewModal' */
            // @ts-ignore
            'site/modals/AddProductReviewModal');
            return new Promise(resolve => {
                const modal = AddProductReviewModal({
                    props: {
                        token: this.props.token,
                        contentTypes: this.props.contentTypes,
                        productReview: productReview
                    },
                    callbacks: {
                        CALLBACK_CANCEL: () => {
                            modal.destroy();
                            resolve();
                        }
                    }
                });
            });
        };
    }
};
default_1 = __decorate([
    register('MailReview')
], default_1);
export default default_1;
