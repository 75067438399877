import { md5 } from 'js-md5';
import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
export function buildMd5(fields) {
    const ORDERED_FIELDS = [
        FieldsKeys.FirstName,
        FieldsKeys.LastName,
        FieldsKeys.Email,
        FieldsKeys.City,
        FieldsKeys.Phone,
        FieldsKeys.Address,
        FieldsKeys.StreetAddress,
        FieldsKeys.Apartment,
        FieldsKeys.DeliveryMethod,
        FieldsKeys.Terminal
    ];
    const payload = ORDERED_FIELDS.reduce((res, fieldKey) => {
        const field = fields.find(({ key }) => key === fieldKey);
        return res += (field === null || field === void 0 ? void 0 : field.value) || '';
    }, '');
    return md5(payload);
}
export class AddressFactory {
    constructor(payload) {
        Object.assign(this, payload);
        this.md5 = this.buildMd5();
    }
    buildMd5() {
        const data = buildMd5(this.fields);
        return data;
    }
}
