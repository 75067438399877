import debounce from 'core/utils/debounce';
export var BatchRenderer;
(function (BatchRenderer) {
    let queue = [];
    let flush = debounce(150, () => {
        requestAnimationFrame(() => {
            queue.forEach(resolve => resolve());
            queue = [];
        });
    });
    let push = (resolve) => {
        queue.push(resolve);
        flush();
    };
    async function render(callback) {
        return new Promise(resolve => push(resolve)).then(() => callback());
    }
    BatchRenderer.render = render;
})(BatchRenderer || (BatchRenderer = {}));
export var LazyPhoto;
(function (LazyPhoto) {
    const preloadPhoto = ({ src, srcset, width, height }) => {
        return new Promise(resolve => {
            const img = new Image(width, height);
            if (srcset)
                img.srcset = srcset;
            if (src)
                img.src = src;
            img.onload = () => {
                if (img.decode) {
                    img.decode().then(() => {
                        resolve(img.currentSrc || img.src);
                    });
                }
                else {
                    resolve(img.currentSrc || img.src);
                }
            };
        });
    };
    let renderPhoto = (el, src) => {
        return BatchRenderer.render(() => {
            if (el.tagName === 'IMG') {
                const image = el;
                image.src = src;
            }
            else {
                el.style.backgroundImage = `url(${src})`;
            }
            el.dataset.loaded = 'true';
        });
    };
    const sanitizeDimension = (dimension) => {
        return dimension > 0 ? dimension : undefined;
    };
    function preload(image) {
        return preloadPhoto(image);
    }
    LazyPhoto.preload = preload;
    async function load(el) {
        // *TODO: по другому сделать подгрузку фотки | Created at: 20.Aug.2019
        // потому что это нельзя отловить 404 ошибку
        const { src, srcset, loaded } = el.dataset;
        if (loaded)
            return;
        const { width, height } = el.getBoundingClientRect();
        const resultSrc = await preloadPhoto({ src, srcset, width: sanitizeDimension(width), height: sanitizeDimension(height) });
        await renderPhoto(el, resultSrc);
    }
    LazyPhoto.load = load;
})(LazyPhoto || (LazyPhoto = {}));
