var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import Store, { Provider } from 'core/services/Store';
import { OPERATION_CHANGE_SPECIALTY } from './operations/changeSpecialty';
import Specialties from './Specialties';
import Categories from './Categories';
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.store = Store({
            props: {
                initialState: {
                    currentSpecialtyId: this.props.currentSpecialtyId,
                    categories: {
                        [this.props.currentSpecialtyId]: this.props.categories
                    }
                }
            },
            callbacks: {
                CALLBACK_AFTER_COMMIT: () => {
                    this.renderer.render();
                }
            }
        });
        this.handleSelectSpecialty = (e) => {
            this.store.operate(OPERATION_CHANGE_SPECIALTY, {
                id: e.target.value,
                url: this.props.url
            });
        };
    }
    render() {
        let { currentSpecialtyId, categories } = this.store.getState();
        return (h(Provider, { store: this.store },
            h("div", { className: 'b-ProfiCategoriesForm' },
                h(Specialties, { onSelect: this.handleSelectSpecialty, specialties: this.props.specialties, currentSpecialtyId: currentSpecialtyId, specialtyType: this.props.specialtyType, workPlace: this.props.workPlace }),
                this.props.isAdmin && categories[currentSpecialtyId].length > 0 && (h(Categories, { categories: categories[currentSpecialtyId] })))));
    }
};
default_1 = __decorate([
    register('ProfiCategoriesForm')
], default_1);
export default default_1;
