import { isStoreFallbackState, prepareUrl } from 'site/VControllers/components/Checkout/services/statefullCart';
if (isStoreFallbackState()) {
    document.addEventListener('click', (e) => {
        const target = e.target;
        const closestTarget = target === null || target === void 0 ? void 0 : target.closest('[href]');
        if (!closestTarget)
            return;
        const newHref = prepareUrl(closestTarget.getAttribute('href'));
        if (newHref)
            closestTarget.setAttribute('href', newHref);
    });
}
