import EventEmitter from 'eventemitter3'
import { camelize } from 'humps'

import Http from 'core/services/HttpClient'
import HttpRequestBatcher from 'core/services/HttpRequestBatcher'

export const ACTIONS = {
  add: 'add',
  remove: 'remove'
}

function preparePayload(requests) {
  const objects = {}
  requests.forEach(({ id, type }) => {
    const { [type]: typeIds = [] } = objects
    typeIds.push(id)
    objects[type] = typeIds
  })

  return { objects }
}

function retrieveResponseValue({ data }, defaultValue = 0) {
  return ({ id, type }) => {
    const { [camelize(type)]: typeRow = {} } =  data
    return typeRow[id] || defaultValue
  }
}

async function fetchObjectAddedCountBatcher(requests) {
  let result
  try {
    result = await Http.post('/front_api/favorites/fetch_objects_added_count.json', preparePayload(requests))
  } catch (_e) {
    result = { data: {} }
  }

  return retrieveResponseValue(result)
}

async function checkHavingObjectBatcher(requests) {
  let result
  try {
    result = await Http.post('/front_api/favorites/check_having_objects.json', preparePayload(requests))
  } catch (_e) {
    result = { data: {} }
  }

  return retrieveResponseValue(result, false)
}

// @TODO возможно стоит переписать это на какой-то глобальный Store
// На который будут подписываться контроллеры / компоненты
// Аналогично можно сделать для счетчиков корзины

let FavoriteListService = (() => {
  const eventEmitter = new EventEmitter()

  const buildUrl = ({ action, objectId, objectType }) => {
    return `/front_api/favorites/${action}?object_type=${objectType}&object_id=${objectId}`
  }
  
  const toggle = ({ action, objectType, objectId, el }) => {
    const url = buildUrl({ action, objectType, objectId })

    return Http.post(url).then(() => {
      eventEmitter.emit(action, { objectType, objectId, el })
      eventEmitter.emit(`${action}.${objectType}.${objectId}`)
    })
  }

  const add = payload => {
    toggle({ ...payload, action: ACTIONS.add })
  }

  const remove = payload => {
    toggle({ ...payload, action: ACTIONS.remove })
  }

  const fetchAddedCount = ({ objectType, objectId }) => {
    return HttpRequestBatcher.request(fetchObjectAddedCountBatcher, {
      id:   objectId,
      type: objectType
    })
  }

  const requestHaving = ({ objectType, objectId }) => {
    return HttpRequestBatcher.request(checkHavingObjectBatcher, {
      id:   objectId,
      type: objectType
    })
  }

  const on  = (eventName, callback) => eventEmitter.on(eventName, callback)
  const off = (eventName, callback) => eventEmitter.off(eventName, callback)

  return {
    on,
    off,
    add,
    remove,
    requestHaving,
    fetchAddedCount
  }
})()

export default FavoriteListService
