import DOMComponent       from 'core/services/DOMComponent'
import HttpInterceptor    from 'core/services/HttpInterceptor'
import NoticeService      from 'core/services/NoticeService'
import ValidationErrors   from 'core/services/ValidationErrors'
import * as Controller from 'core/services/Controller'
import 'core/services/XMLRequest'

// Все это очень уныло :(

HttpInterceptor.run()

/* @TODO Это какой-то бред, переделать на Env.notices или что-то аналогичное */

if (window.json_notifications) {
  if (window.json_notifications.errors) {
    ValidationErrors.build(window.json_notifications.errors)
  }

  if (window.json_notifications.flash) {
    NoticeService.parse(window.json_notifications.flash)
  }
}

/* @LEGACY чтобы поддерживать старые библиотеки компонент */

window.Component = window.Component || {}

// connect декорируется, потому что используется не только здесь
Component.connect = (connect => target => {
  setImmediate(() => {
    window.evil &&
    window.evil.block &&
    window.evil.block.vitalize(target || document)
  })

  setImmediate(() => {
    DOMComponent.connect()
    Controller.connect()
  })

  connect && setImmediate(() => connect(target))
})(Component.connect)

$(() => {
  Component.connect()
})
