var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.radioInputs = this.el.querySelectorAll('input[type=radio]');
        this.handleActivateSection = () => {
            const params = new URL(window.location.href).searchParams;
            for (const [key, value] of params.entries()) {
                [...this.radioInputs]
                    .filter(radio => radio.name === key)
                    .forEach(radio => {
                    if (radio.value === value)
                        radio.checked = true;
                });
            }
        };
    }
    init() {
        this.handleActivateSection();
    }
};
default_1 = __decorate([
    register('RadioSection')
], default_1);
export default default_1;
