import * as fbMe from './fbMe';
import * as fb from './fb';
import * as tg from './tg';
import * as vb from './vb';
import * as vk from './vk';
import * as wa from './wa';
const SOCIAL_TYPE_MAPPING = { fbMe, fb, tg, vb, vk, wa };
export function getSocial(type, props) {
    let social = SOCIAL_TYPE_MAPPING[type];
    let shareUrl = social.shareUrl(props);
    let openTarget = social.openTarget || 'blank';
    return { shareUrl, openTarget };
}
