import { COMMIT_REFRESH_CHECKOUT } from './refreshCheckout';
import { COMMIT_CHANGE_LOADING } from './changeLoading';
export function COMMIT_CHANGE_SELECT_ITEMS(state, selected, productIds, data) {
    state = COMMIT_CHANGE_LOADING(COMMIT_REFRESH_CHECKOUT(state, data), false);
    return productIds.reduce((newState, productId) => COMMIT_CHANGE_ITEM(newState, productId, { selected }), state);
}
export function COMMIT_REMOVE_ITEM(state, productId, data) {
    return { ...COMMIT_REFRESH_CHECKOUT(state, data),
        items: state.items.filter(item => item.productsSetId || item.productId !== productId)
    };
}
export function COMMIT_CHANGE_ITEM_QUANTITY(state, productId, quantity, data) {
    return COMMIT_CHANGE_ITEM(COMMIT_REFRESH_CHECKOUT(state, data), productId, { quantity, changing: false });
}
export function COMMIT_CHANGE_ITEM_CHANGING(state, productId, changing) {
    return COMMIT_CHANGE_ITEM(state, productId, { changing });
}
export function COMMIT_CHANGE_ITEM(state, productId, itemPayload) {
    let { items } = state;
    items = items.map(item => item.productId === productId ? { ...item, ...itemPayload } : item);
    return { ...state, items };
}
export function COMMIT_CHANGE_ITEMS(state, updatedItems) {
    return {
        ...state,
        items: state.items.map((item) => {
            const updatedItem = updatedItems.find(({ productId }) => productId === item.productId);
            return { ...item, ...updatedItem } || item;
        })
    };
}
export function COMMIT_BULK_REMOVE(state, productIds, data) {
    return { ...COMMIT_REFRESH_CHECKOUT(state, data),
        items: state.items.filter(item => !productIds.includes(item.productId)),
        loading: false
    };
}
export function COMMIT_ADD_ITEM(state, item, data) {
    let { items } = state;
    const existing = items.find(({ productId, productsSetId }) => productId === item.productId && !productsSetId);
    state = COMMIT_REFRESH_CHECKOUT(state, data);
    if (existing)
        return COMMIT_CHANGE_ITEM(state, item.productId, { ...item, changing: false });
    items.push(item);
    return { ...state, items, loading: false };
}
export function COMMIT_CHANGE_ASSEMBLY(state, data) {
    return COMMIT_CHANGE_LOADING(COMMIT_REFRESH_CHECKOUT(state, data), false);
}
export function COMMIT_ADDED_ASSEMBLY_ANIMATING(state, isActive) {
    return { ...state, ui: { addedAssemblyItemAnimating: isActive } };
}
