import { createVDOMComponent } from 'core/services/VDOM';

let Provider = $self => {
  let getChildContext = () => {
    let { store } = $self.getProps();

    return { store: store }
  }

  let render = ({ children }) => {
    return children[0];
  }

  return { render, getChildContext }
}

export default createVDOMComponent(Provider);
