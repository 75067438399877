import { Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import h from './cssScope';
export { FieldsRow } from './FieldsRow';
export { ActionsContainer } from './ActionsContainer';
export class Form extends Component {
    render() {
        const { children, className, ...props } = this.props;
        return (h("form", Object.assign({}, props, { className: htmlClassNames('ScopeRoot', className) }), children));
    }
}
