import { createVDOMRenderer, h } from 'core/services/VDOM';

import Store from 'core/services/Store';

import NoticeList from './components/NoticeList';

import COMMIT_ADD_NOTICE    from './commits/addNotice';
import COMMIT_REMOVE_NOTICE from './commits/removeNotice';
import COMMIT_CLEAR_NOTITES from './commits/clearNotices';

import generateId from 'core/utils/generateId';

const NOTICE_TIMEOUT = 10000;

const NoticeService = (() => {
  let store = Store({
    props: {
      initialState: {
        notices: []
      }
    },

    callbacks: {
      CALLBACK_AFTER_COMMIT: state => {
        renderer.render(state);
      }
    }
  });

  let addNotice = ({ type, title, desc, timeout = NOTICE_TIMEOUT }) => {
    let notice = {
      id: generateId(),
      title,
      desc,
      type,
      onClose: () => removeNotice(notice.id)
    }

    store.commit(COMMIT_ADD_NOTICE, notice);

    setTimeout(() => removeNotice(notice.id), timeout);
  }

  let removeNotice = noticeId => {
    store.commit(COMMIT_REMOVE_NOTICE, noticeId);
  }

  let clearNotices = () => {
    store.commit(COMMIT_CLEAR_NOTITES);
  }

  /* Render */

  let renderer = createVDOMRenderer(document.body, state => (
    <NoticeList store={store} state={state}/>
  ));

  /* Public API */

  function error(desc, title = 'Ошибка') {
    addNotice({ type: 'error', title, desc });
  }

  function info(desc, title = '') {
    addNotice({ type: 'info', title, desc });
  }

  function warning(desc, title = '') {
    addNotice({ type: 'warning', title, desc });
  }

  function clear() {
    clearNotices();
  }

  function parse(flash) {
    // Временное решение
    if (Array.isArray(flash)) {
      flash.forEach(addNotice);
    } else {
      let type = ['error', 'notice', 'warning'].find((type) => flash[type]);

      ({
        error:   error,
        notice:  info,
        warning: warning
      })[type](flash[type]);
    }
  }

  return { error, info, warning, clear, parse };
})();

export default NoticeService;
