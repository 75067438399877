export function COMMIT_SET_DATE(state, chosenDate) {
    return {
        ...state,
        chosenDate
    };
}
export function COMMIT_SET_PERIOD(state, chosenPeriod) {
    return {
        ...state,
        chosenPeriod
    };
}
