import Axios from 'axios';
import { DEFAULT_AXIOS_CONFIG } from './config';
import requestInterceptors from './interceptors/request';
import { successHandlers, failHandlers } from './interceptors/response';
const apiLegacy = Axios.create(DEFAULT_AXIOS_CONFIG);
requestInterceptors.forEach(interceptor => {
    apiLegacy.interceptors.request.use(interceptor);
});
apiLegacy.interceptors.response.use(response => successHandlers.reduce((response, handler) => {
    handler(response);
    return response;
}, response), ({ response }) => {
    if (!response)
        return;
    failHandlers.reduce((response, handler) => {
        handler(response);
        return response;
    }, response);
    return Promise.reject(response);
});
export { apiLegacy };
