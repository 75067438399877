const TRESHOLD = 300;
const POLL_INTERVAL = 1000;
export var LazyBlock;
(function (LazyBlock) {
    let blocks = new Map();
    let observer = new IntersectionObserver(entries => {
        entries
            .filter(({ isIntersecting }) => isIntersecting)
            .filter(({ target }) => blocks.has(target))
            .forEach(({ target }) => {
            blocks.get(target).resolve();
            blocks.delete(target);
            observer.unobserve(target);
        });
    }, {
        rootMargin: `${TRESHOLD}px 0px ${TRESHOLD}px`,
        threshold: 0.01
    });
    observer.POLL_INTERVAL = POLL_INTERVAL;
    function waitNative(target) {
        return new Promise(resolve => {
            blocks.set(target, { resolve });
            observer.observe(target);
        });
    }
    LazyBlock.waitNative = waitNative;
    function wait($target) {
        const target = $target[0];
        return waitNative(target);
    }
    LazyBlock.wait = wait;
})(LazyBlock || (LazyBlock = {}));
