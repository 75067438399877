var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.scrollContainer = this.findElement(`%{c-SnapSlider/scrollContainer}`);
        this.prevButton = this.findElement('%{c-SnapSlider/action/prev}');
        this.nextButton = this.findElement('%{c-SnapSlider/action/next}');
        this.shouldGoToStart = false;
        this.handlePrev = () => {
            var _a;
            (_a = this.scrollContainer) === null || _a === void 0 ? void 0 : _a.scrollBy({ left: -this.scrollWidth, top: 0, behavior: 'smooth' });
        };
        this.handleNext = () => {
            var _a, _b;
            if (this.shouldGoToStart) {
                (_a = this.scrollContainer) === null || _a === void 0 ? void 0 : _a.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                this.shouldGoToStart = false;
                return;
            }
            (_b = this.scrollContainer) === null || _b === void 0 ? void 0 : _b.scrollBy({ left: this.scrollWidth, top: 0, behavior: 'smooth' });
        };
        this.handleScroll = () => {
            var _a;
            if (!this.scrollContainer || !((_a = this.scrollContainer) === null || _a === void 0 ? void 0 : _a.lastElementChild))
                return;
            const { right: containerRightBound } = this.scrollContainer.getBoundingClientRect();
            const { right: lastChildRightBound } = this.scrollContainer.lastElementChild.getBoundingClientRect();
            if (lastChildRightBound < containerRightBound) {
                this.shouldGoToStart = true;
            }
            else {
                this.el.classList.remove('__nextDisabled');
                this.nextButton.disabled = false;
            }
            if (this.scrollContainer.scrollLeft === 0) {
                this.el.classList.add('__prevDisabled');
                this.prevButton.disabled = true;
                this.shouldGoToStart = false;
            }
            else {
                this.el.classList.remove('__prevDisabled');
                this.prevButton.disabled = false;
            }
        };
    }
    get nativeListeners() {
        return [
            { selector: `%{c-SnapSlider/action/prev}`, event: 'click', handler: this.handlePrev },
            { selector: `%{c-SnapSlider/action/next}`, event: 'click', handler: this.handleNext },
            { selector: `%{c-SnapSlider/scrollContainer}`, event: 'scroll', handler: this.handleScroll, options: { passive: true } }
        ];
    }
    get itemWidth() {
        const { firstElementChild } = this.scrollContainer;
        const { width, marginLeft, marginRight } = window.getComputedStyle(firstElementChild);
        return parseFloat(width) + parseFloat(marginLeft) + parseFloat(marginRight);
    }
    get scrollWidth() {
        return this.itemWidth * 6;
    }
    init() {
        if (!this.scrollContainer)
            return;
        if (this.scrollContainer.scrollWidth <= this.scrollContainer.offsetWidth)
            return;
        this.addNativeListeners();
        this.handleScroll();
        this.el.classList.add('__active');
    }
    destroy() {
        this.removeNativeListeners();
    }
};
default_1 = __decorate([
    register('SnapSlider')
], default_1);
export default default_1;
