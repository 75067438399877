import { getCities } from 'site/VControllers/SinglePageCheckout/services/KladrApi/api/cities';
import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
import { updateCartInfo } from '../api/form';
import { getCityNameByPickupId, getDeliveryPriceByAddress } from '../api/ozon';
import { COMMIT_CHANGE_LOADING } from '../commits/changeLoading';
import { COMMIT_REFRESH_CHECKOUT } from '../commits/refreshCheckout';
import { COMMIT_SET_OZON_PICKUP } from '../commits/ozon';
import { OPERATION_LOAD_PICKUP_POINTS } from './form';
export function OPERATION_SET_OZON_PICKUP(store) {
    return async function ({ id, address }) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        const { formFields } = store.getState();
        const deliveryPrice = await getDeliveryPriceByAddress(address);
        if (!deliveryPrice)
            return;
        const cityName = await getCityNameByPickupId(id);
        if (!cityName)
            return;
        const { data } = await getCities(cityName);
        if (!data.result || data.result.length === 0)
            return;
        const cityId = data.result[0].id;
        const country = formFields.find(({ key }) => key === FieldsKeys.Country);
        if (!country)
            return;
        return updateCartInfo({ deliveryPrice, cityId, countryId: country.value })
            .then((result) => {
            store.commit(COMMIT_SET_OZON_PICKUP, { id, address });
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
            store.operate(OPERATION_LOAD_PICKUP_POINTS);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
