var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
// @ts-ignore
import TimerWidget from 'site/widgets/TimerWidget';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.wTimer = null;
        this.init = () => {
            this.connectTimerWidget();
        };
        this.destroy = () => {
            this.disconnectTimerWidget();
        };
        this.connectTimerWidget = () => {
            const timer = this.findElement('%{w-Timer}');
            this.wTimer = TimerWidget($(timer), {
                expireAt: this.props.expireAt,
                callbacks: {
                    onExpired: () => {
                        this.el.classList.add('__isExpired');
                    }
                }
            });
            this.wTimer.connect();
        };
        this.disconnectTimerWidget = () => {
            this.wTimer.disconnect();
            this.wTimer = null;
        };
    }
};
default_1 = __decorate([
    register('SalePostExpireAt')
], default_1);
export default default_1;
