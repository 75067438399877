import { h, Component } from 'preact';
import autocompleteTree from 'site/VDOMComponents/AutocompleteTree';
import { Actions } from '../../Actions';
const AutocompleteTree = autocompleteTree();
export class Autocomplete extends Component {
    constructor() {
        super(...arguments);
        this.input = null;
        this.state = { isLabelFloated: true };
        this.componentDidMount = () => {
            this.props.onMount();
        };
        this.handleChange = (item) => {
            this.props.onChange(item, this.input);
        };
        this.handleFocusAutocomplete = () => {
            if (this.props.disabled)
                return;
            this.props.onOpen();
        };
        this.handleBlurAutocomplete = () => {
            this.props.onClose();
        };
        this.handleInput = (e) => {
            const newValue = e.target.value;
            this.props.onInput(newValue);
        };
        this.handleClear = () => {
            this.props.onClear();
        };
        this.handleRef = (el) => {
            var _a, _b;
            this.input = el;
            (_b = (_a = this.props).onInputRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        };
        this.handleApply = () => {
            var _a, _b;
            (_b = (_a = this.props).onApply) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
    }
    get listClassList() {
        const { buildClassName, isLoading, isOpen } = this.props;
        return buildClassName('items', {
            '__open': isOpen,
            '__loading': isLoading
        });
    }
    render() {
        const { isRequired, inputValue, selectedValue, items, buildClassName, disabled = false, notFoundJsx, label, isOpen, tag: Tag = 'input', notice } = this.props;
        const { isLabelFloated } = this.state;
        const isItemsShown = isOpen && items.length > 0;
        return (h("div", { className: buildClassName('autocomplete', { '__labelFloated': isLabelFloated, '__isDropdownOpen': isItemsShown }), onFocusCapture: this.handleFocusAutocomplete, onBlurCapture: this.handleBlurAutocomplete, tabIndex: 0 },
            notice && (h("div", { className: 'ui-Input_notice' }, notice.message)),
            h("label", { className: buildClassName('label') },
                label,
                isRequired && (h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*"))),
            h(Tag, { required: isRequired, className: buildClassName('input', { '__isDropdownOpen': isItemsShown }), value: inputValue, disabled: disabled, autocomplete: 'new-autocomplete', onInput: this.handleInput, ref: this.handleRef }),
            h(AutocompleteTree, { inputValue: inputValue, items: items, onClick: this.handleChange, onChildClick: this.handleChange, getLabel: (item) => item.text, isSelected: (item) => item.id === selectedValue.id, className: this.listClassList, notFoundJsx: notFoundJsx }),
            h(Actions, { isVisible: !!inputValue && isItemsShown, buildClassName: buildClassName, onApply: this.handleApply, onClear: this.handleClear })));
    }
}
