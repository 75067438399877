export function dispatchCustomEvent(el, eventName, detail = {}) {
    if (!el)
        return;
    const event = new CustomEvent(eventName, { detail });
    event.initCustomEvent(eventName, true, false, detail);
    el.dispatchEvent(event);
}
export function documentDispatch(event, detail) {
    dispatchCustomEvent(document, event, detail);
}
export const COUNTRY_ID_UPDATED_EVENT = 'countryId:updated';
