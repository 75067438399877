import { createVDOMComponent, h } from 'core/services/VDOM'

import debounce from 'core/utils/debounce'
import htmlClassNames from 'core/utils/htmlClassNames'

function FormAutocomplete(self) {
  let onQuery = debounce(250, async e => {
    let { onQuery } = self.getProps()

    let searchQuery = e.target.value

    let options = await onQuery(searchQuery)

    self.setState({ options, searchQuery })

    setOptionsVisibility(true)
  })

  let handleSelectOption = option => () => {
    let { onAddChoice } = self.getProps()

    onAddChoice(option)

    setOptionsVisibility(false)
  }

  let handleRemoveChoice = choice => () => {
    let { onRemoveChoice } = self.getProps()

    onRemoveChoice(choice)
  }

  let handleOverlayClick = e => {
    let node = self.getDOMNode()

    if (!node.contains(e.target)) {
      setOptionsVisibility(false)

      cleanSearchQuery()
    }
  }

  let setOptionsVisibility = flag => {
    self.setState({
      isResultsVisible: flag
    })
  }

  let cleanSearchQuery = () => {
    self.setState({
      searchQuery: null
    })
  }

  let getAutocompleteClasses = () => {
    let { choices, multiple } = self.getProps()

    let { isFocused } = self.getState()

    return htmlClassNames(
      'b-FormAutocomplete',
      { '__multiple': multiple },
      { '__focused': isFocused || choices.length > 0 }
    )
  }

  let isSearchVisible = () => {
    let { choices, multiple } = self.getProps()

    return choices.length > 0 && multiple === false
      ? false
      : true
  }

  let toggleFocus = flag => () => {
    self.setState({
      isFocused: flag
    })
  }

  return {
    getInitialState() {
      return {
        isResultsVisible: false,
        searchQuery: null
      }
    },

    componentDidMount() {
      document.addEventListener('click', handleOverlayClick)
    },

    componentWillUnmount() {
      document.removeEventListener('click', handleOverlayClick)
    },

    render({ choices, title, placeholder }, selfState) {
      return (
        <div class={getAutocompleteClasses()}>
          {title && (<label>{title}</label>)}

          <div class='b-FormAutocomplete_input ui-Input __text __labelFloated'>
            <label className='ui-Input_label'>{placeholder}</label>

            <div className='ui-Input_input'>
              {choices.map(choice =>
                <div class='b-FormAutocomplete_choice'>
                  <span class='_title'>{choice.text}</span>
                  <span class='_remove' onClick={handleRemoveChoice(choice)}></span>
                </div>
              )}

              {isSearchVisible() && (
                <input
                  class='b-FormAutocomplete_search'
                  type='text'
                  value={selfState.searchQuery}
                  onFocus={toggleFocus(true)}
                  onBlur={toggleFocus(false)}
                  onInput={onQuery}
                />
              )}
            </div>
          </div>

          {selfState.isResultsVisible && (
            <div class='b-FormAutocomplete_results'>
              {selfState.options.length > 0 ? (
                <div class='b-FormAutocomplete_options'>
                  {selfState.options.map(option =>
                    <div
                      class='b-FormAutocomplete_option'
                      onClick={handleSelectOption(option)}
                    >
                      {option.text}
                    </div>
                  )}
                </div>
              ) : (
                <div class='b-FormAutocomplete_options'>
                  <div class='b-FormAutocomplete_option'>Совпадений не найдено
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }
  }
}

export default createVDOMComponent(FormAutocomplete)
