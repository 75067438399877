import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import { camelize } from 'humps';
export class Radio extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (e) => {
            const target = e.target;
            this.props.onChange(target.value);
        };
    }
    render() {
        const { value, checked, text, subtext, id, name, hidden } = this.props;
        return (h("label", { className: htmlClassNames('ui-Input __radio', `__v_${camelize(value)}`, { __hidden: hidden }) },
            h("input", { type: 'radio', name: name, id: id, value: value, checked: checked, onChange: this.handleChange }),
            h("div", { className: 'ui-Input_indicator' }),
            h("span", { className: 'ui-Input_text' }, text),
            subtext && h("span", { className: 'ui-Input_subtext', dangerouslySetInnerHTML: { __html: subtext } })));
    }
}
