var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import { Http } from 'core/services/HttpClient';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.isLoaded = false;
        this.handleMouseEnter = (e) => {
            const hoveredItem = this.findClosest('%{HeaderSubnav/navItem}', e.target);
            if (!hoveredItem)
                return;
            this.loadContent();
            this.el.removeEventListener('mouseenter', this.handleMouseEnter);
        };
    }
    get nativeListeners() {
        return [
            { selector: `%{HeaderSubnav/navItem}`, event: 'mouseenter', handler: this.handleMouseEnter }
        ];
    }
    async loadContent() {
        if (this.isLoaded)
            return;
        const navResponse = await Http.get('/front_api/navigation/subnav', {
            params: { page: this.props.page }
        });
        this.el.innerHTML = navResponse.data;
        this.isLoaded = true;
        connect(this.el);
    }
};
default_1 = __decorate([
    register('HeaderSubnav')
], default_1);
export default default_1;
