const DOM_COMPONENT_PREVENTED = 'DOMComponentPrevented';

let __components = new Map();

let __initedComponents = [];

let initComponent = (name, $el, props = {}) => {
  if (!__components.has(name)) {
    throw new Error(`Unknown component ${name}`);
  }

  let createFn = __components.get(name);

  Promise.resolve()
    .then(() => createFn($el, props))
    .then(({
      init,
      destroy,
      shouldInit = () => true
    } = {}) => {
      if (shouldInit()) {
        if (init) init();

        __initedComponents.push([$el, destroy]);
      }
    })
}

let connect = (target = document) => {
  $(target).find('[data-dom-component]:not([data-component-inited])').each((i, el) => {
    let $el   = $(el);
    let props = $el.data('props');

    $el.data('domComponent').split(' ').forEach(name => {
      initComponent(name, $el, props);
    });

    $el.attr('data-component-inited', true);
  });
}

let disconnect = (target = document) => {
  __initedComponents = __initedComponents.filter(([$el, destroy]) => {
    if (target.contains($el[0])) {
      if (destroy) destroy();

      return true;
    } else {
      return false;
    }
  });
}

let register = (name, createFn) => {
  __components.set(name, createFn);
}

export default { register, disconnect, connect }

export { DOM_COMPONENT_PREVENTED }
