var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import WayPointService from 'core/services/WayPointService';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.watcherIds = null;
        this.init = () => {
            this.watcherIds = WayPointService.watch([
                {
                    getPoint: () => $(this.props.relativeEl).offset().top,
                    onDown: this.setVisibility(true),
                    onUp: this.setVisibility(false)
                },
                {
                    getPoint: () => $(this.props.relativeEl).offset().top + $(this.props.relativeEl).height(),
                    onDown: this.setVisibility(false),
                    onUp: this.setVisibility(true)
                }
            ]);
            this.addNativeListeners();
        };
        this.destroy = () => {
            WayPointService.unwatch(this.watcherIds);
        };
        this.handleToTop = (e) => {
            e.preventDefault();
            window.scrollTo({ top: $(this.props.relativeEl).closest('.b-Article').offset().top, behavior: 'smooth' });
        };
        this.setVisibility = (flag) => () => requestAnimationFrame(() => {
            this.el.classList.toggle('__isVisible', flag);
        });
    }
    get nativeListeners() {
        return [
            { selector: '%{c-ActionPanel/toTop}', event: 'click', handler: this.handleToTop }
        ];
    }
};
default_1 = __decorate([
    register('ActionPanel')
], default_1);
export default default_1;
