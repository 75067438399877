var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
const ACTIVE_STATE_CLASS = '__active';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.tabs = [];
        this.lists = [];
        this.getInnerItems = (selector) => {
            return this.findElements(`%{w-DocumentsNav/${selector}}`).map((item) => ({
                active: Boolean(item.dataset.active),
                el: item
            }));
        };
        this.updateItemsState = (currentKey, items) => {
            items.forEach((item) => {
                if (item.el.dataset.key === currentKey) {
                    item.active = true;
                    item.el.classList.add(ACTIVE_STATE_CLASS);
                }
                else {
                    item.active = false;
                    item.el.classList.remove(ACTIVE_STATE_CLASS);
                }
            });
        };
        this.handleTabClick = (e) => {
            const currentTab = e.currentTarget;
            const currentKey = currentTab.dataset.key;
            this.updateItemsState(currentKey, this.tabs);
            this.updateItemsState(currentKey, this.lists);
        };
        this.addHandlers = () => {
            this.tabs.forEach((tab) => {
                tab.el.addEventListener('click', this.handleTabClick);
            });
        };
        this.removeHandlers = () => {
            this.tabs.forEach((tab) => {
                tab.el.removeEventListener('click', this.handleTabClick);
            });
        };
    }
    init() {
        this.tabs = this.getInnerItems('tab');
        this.lists = this.getInnerItems('list');
        this.addHandlers();
    }
    destroy() {
        this.removeHandlers();
        this.tabs = [];
        this.lists = [];
    }
};
default_1 = __decorate([
    register('DocumentsNav')
], default_1);
export default default_1;
