var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { dispatchCustomEvent } from 'core/utils/events';
export const CITY_CHANGE_EVENT_NAME = 'c-CityAutocomplete:changeValue';
export const CITY_SELF_CHANGE_EVENT_NAME = 'c-CityAutocomplete:CityChanged';
const CityAutocomplete = asyncComponent(() => import(/* webpackChunkName: "site_c-AutocompleteCities" */ './components/Cities'), 'CityAutocomplete');
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleChangeItem = (item, prevItem) => {
            if (prevItem.id === item.id)
                return;
            dispatchCustomEvent(this.el, CITY_SELF_CHANGE_EVENT_NAME, { cityId: item.id });
        };
    }
    render() {
        const inputEl = this.el.querySelector('input');
        if (!inputEl)
            return;
        return (h(CityAutocomplete, Object.assign({ inputEl: inputEl, onChange: this.handleChangeItem, withOnShowLoad: true, isSearchInput: true, isRequired: true }, this.props)));
    }
};
default_1 = __decorate([
    register('CityAutocomplete')
], default_1);
export default default_1;
