var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { VisibilityObserver } from 'core/services/VisibilityObserver';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.subscribers = this.findElements(`%{VisibilityTracker/${this.props.trackerId}/subscriber}`, document);
        this.init = () => {
            super.init();
            new VisibilityObserver().observe(this.el, {
                onVisible: () => {
                    this.subscribers.forEach(subscriber => {
                        subscriber.classList.add(`__${this.props.trackerId}_visible`);
                        subscriber.classList.remove(`__${this.props.trackerId}_unvisible`);
                    });
                },
                onUnvisible: () => {
                    this.subscribers.forEach(subscriber => {
                        subscriber.classList.remove(`__${this.props.trackerId}_visible`);
                        subscriber.classList.add(`__${this.props.trackerId}_unvisible`);
                    });
                }
            });
        };
    }
};
default_1 = __decorate([
    register('VisibilityTracker')
], default_1);
export default default_1;
