import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    connect() {
        this.load();
    }
    load() {
        fetch(this.urlValue)
            .then(response => {
            if (response.status === 200) {
                return response.text();
            }
            else {
                throw new Error('Не удалось загрузить данные. Статус: ' + response.status);
            }
        }).then(html => this.element.innerHTML = html);
    }
}
default_1.values = { url: String };
