import cond from 'core/utils/cond';
import qs   from 'qs';

/*
  @TODO Подключить какую-нибудь либу для запросов
]*/

let __subscribers = new Map();
let __defaults    = {
  credentials: 'include',
  mode: 'cors',
}

let subscribe = (name, callback) => {
  __subscribers.set(callback, name);
}

let _publish = eventName => argument => {
  return [...__subscribers]
    .filter(([_, name]) => name === eventName)
    .reduce((q, [callback]) => q.then(callback), Promise.resolve(argument))
}

let _processData = data => {
  return cond(
    when => data instanceof FormData,
      to => [null, data],

    when => typeof data === 'object',
      to => ['application/json', JSON.stringify(data)],

    when => typeof data === 'string',
      to => ['application/x-www-form-urlencoded', data],

    when => cond.Any,
      to => { throw new Error('HTTPService: Unknown data format.') }
  )
}

let _processParams = (url, params) => {
  let [_, queryString] = url.match(/\?([^#]+)/) || [null, null];

  return [
    url.replace(/\?[^#]+/, ''),
    qs.stringify({
      ...qs.parse(queryString),
      ...params
    }, { arrayFormat: 'brackets' })
  ].join('?')
}

let _prepareOptions = ([url, { data, params, ...options }]) => {
  let resultOptions = {
    headers: {},
    ...__defaults,
    ...options
  };

  if (data) {
    let [contentType, body] = _processData(data);

    resultOptions.body = body;

    if (contentType) {
      resultOptions.headers['Content-Type'] = contentType;
    }
  }

  if (params) {
    url = _processParams(url, params);
  }

  return [url, resultOptions];
}

let _sendRequest = ([url, options]) => {
  return fetch(url, options);
}

let _parseResponse = response => {
  let contentType = response.headers.get('content-type');

  let contentPromise = contentType && contentType.includes('application/json') ?
    response.json() :
    response.text();

  return new Promise(resolve => {
    contentPromise.then(content => resolve([response, content]))
  });
}

let _checkStatus = ([response, content]) => {
  if (response.status >= 200 && response.status < 300) {
    return [response, content];
  } else {
    throw new Error(`Http: ${response.status}`);
  }
}

let _getContent = ([_response, content]) => {
  return content;
}

let createRequest = (url, options) => {
  return Promise.resolve([url, options])
    .then(_prepareOptions)
    .then(_publish('beforeRequest'))
    .then(_sendRequest)
    .then(_parseResponse)
    .then(_publish('afterRequest'))
    .then(_checkStatus)
    .then(_getContent)
}

let _carryRequestMethod = method => (url, options = {}) => {
  return createRequest(url, { method, ...options });
}

export default {
  subscribe,
  createRequest,
  get:    _carryRequestMethod('GET'),
  put:    _carryRequestMethod('PUT'),
  post:   _carryRequestMethod('POST'),
  patch:  _carryRequestMethod('PATCH'),
  delete: _carryRequestMethod('DELETE'),

  defaults: __defaults
}
