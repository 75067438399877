import debounce from 'core/utils/debounce'

let HttpRequestBatcher = (() => {
  let __requests = []

  let groupByBatcher = (groupMap, [batcher, request]) => {
    let requests = groupMap.get(batcher) || []

    groupMap.set(batcher, [...requests, request])

    return groupMap
  }

  let callBatcher = (requests, batcher) => {
    batcher(
      requests.map(({ params }) => params)
    ).then(resultMapper => {
      processResult(requests, resultMapper)
    })
  }

  let processResult = (requests, resultMapper) => {
    requests.forEach(({ params, resolve }) => {
      resolve(resultMapper(params))
    })
  }

  let processRequests = debounce(60, () => {
    __requests
      .reduce(groupByBatcher, new Map())
      .forEach(callBatcher)

    __requests = []
  })

  let request = (batcher, params = {}) => {
    return new Promise(resolve => {
      __requests.push([batcher, { resolve, params }])

      processRequests()
    })
  }

  return { request }
})()

export default HttpRequestBatcher
