import { h, Component } from 'preact';
export function withFormState(ChildComponent) {
    return class WithFormState extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                fields: this.props.fields
            };
            this.handleChangeField = (key, value) => {
                this.setState({
                    fields: this.state.fields.map((field) => field.key === key ? { ...field, value } : field)
                });
            };
        }
        render() {
            const { fields } = this.state;
            return (
            // @ts-ignore
            // Using `Omit` brokes  typing
            h(ChildComponent, Object.assign({}, this.props, { fields: fields, onChangeField: this.handleChangeField })));
        }
    };
}
