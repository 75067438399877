import { COMMIT_REMOVE_PROMOCODE } from './promoCode';
import { COMMIT_CHANGE_ITEMS } from './cartItems';
import { COMMIT_CHANGE_FIELDS } from './form';
import { COMMIT_CHANGE_LOADING } from '../commits/changeLoading';
import { buildMd5 } from '../factories/AddressFactory';
import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
import { COMMIT_SET_OZON_PICKUP } from './ozon';
import { COMMIT_SET_YANDEX_PICKUP } from './yandex';
export function COMMIT_REFRESH_CHECKOUT(state, payload, loadingCommit = COMMIT_CHANGE_LOADING) {
    var _a, _b, _c;
    let newState = state;
    const { delivery, isPromocodeRemoved, totalPriceInfo, isMinOrderPriceSatisfied, itemsChanges, changedFormFields, promocode, totalWeight, assemblyItems } = payload;
    if (isPromocodeRemoved)
        newState = COMMIT_REMOVE_PROMOCODE(newState);
    const changedItemsKeys = itemsChanges ? Object.keys(itemsChanges) : [];
    if (changedItemsKeys.length > 0) {
        const updatedItems = Object.keys(itemsChanges).map((key) => ({ ...itemsChanges[Number(key)], productId: Number(key) }));
        newState = COMMIT_CHANGE_ITEMS(newState, updatedItems);
    }
    if (changedFormFields && changedFormFields.length > 0) {
        const updatedFields = state.formFields.map((field) => {
            const updatingField = changedFormFields.find((changedField) => field.key === changedField.key);
            if (field.key === FieldsKeys.DeliveryMethod && (updatingField === null || updatingField === void 0 ? void 0 : updatingField.value) !== 'pickup') {
                newState = COMMIT_SET_OZON_PICKUP(newState, { id: null, address: null });
            }
            return updatingField ? { ...field, ...updatingField } : field;
        });
        newState = COMMIT_CHANGE_FIELDS(newState, updatedFields, buildMd5(updatedFields));
    }
    const oldPickupPointId = (_b = (_a = state.delivery) === null || _a === void 0 ? void 0 : _a.yandexWidgetParams) === null || _b === void 0 ? void 0 : _b.pickupPointId;
    const newPickupPointId = (_c = delivery === null || delivery === void 0 ? void 0 : delivery.yandexWidgetParams) === null || _c === void 0 ? void 0 : _c.pickupPointId;
    if (state.yandexPickupId && !!oldPickupPointId && !newPickupPointId) {
        newState = COMMIT_SET_YANDEX_PICKUP(newState, { id: null });
    }
    return {
        ...loadingCommit(newState, false),
        delivery: { ...newState.delivery, ...delivery },
        totalPriceInfo,
        isMinOrderPriceSatisfied,
        promocode,
        totalWeight,
        assemblyItems
    };
}
