var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
import waitPageLoaded from 'core/utils/waitPageLoaded';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.wComments = null;
        this.init = async () => {
            return Promise.resolve()
                .then(waitPageLoaded)
                .then(this.loadComments)
                .then(this.connectCommentsWidget);
        };
        this.destroy = () => {
            this.disconnectCommentsWidget();
        };
        this.loadComments = async () => {
            const { comments } = await Http.get('/front_api/comments.json', {
                params: {
                    commentable_id: this.props.objectId,
                    commentable_type: this.props.objectType
                }
            });
            return comments;
        };
        this.connectCommentsWidget = async (comments) => {
            const { CommentsWidget } = await import(
            /* webpackChunkName: 'common__c-Comments' */
            // @ts-ignore
            'site/widgets/CommentsWidget');
            this.wComments = CommentsWidget($(this.el), {
                props: {
                    comments,
                    objectId: this.props.objectId,
                    objectType: this.props.objectType,
                    layoutType: this.props.layoutType
                }
            });
        };
        this.disconnectCommentsWidget = () => {
            var _a;
            (_a = this.wComments) === null || _a === void 0 ? void 0 : _a.disconnect();
            this.wComments = null;
        };
    }
};
default_1 = __decorate([
    register('Comments')
], default_1);
export default default_1;
