import { h, Component } from 'preact';
import autocompleteTree from 'site/VDOMComponents/AutocompleteTree';
// This version renders all elements without nested scroll
const AutocompleteTree = autocompleteTree();
const MOBILE_LEFT_GAP = 20;
export class FixedAutocomplete extends Component {
    constructor() {
        super(...arguments);
        this.input = null;
        this.state = { isLabelFloated: true };
        this.componentDidMount = () => {
            this.props.onMount();
        };
        this.handleChange = (item) => {
            this.props.onChange(item, this.input);
        };
        this.handleFocusAutocomplete = () => {
            if (this.props.disabled)
                return;
            this.setState({ isLabelFloated: true }, this.props.onOpen);
        };
        this.handleBlurAutocomplete = () => {
            this.props.onClose();
            if (this.props.selectedValue.text || this.props.isLabelFixed)
                return;
            this.setState({ isLabelFloated: false });
        };
        this.handleInput = (e) => {
            const newValue = e.target.value;
            this.props.onInput(newValue);
        };
        this.handleClear = () => {
            this.props.onInput('');
        };
        this.handleRef = (el) => {
            var _a, _b;
            this.input = el;
            (_b = (_a = this.props).onInputRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        };
    }
    get listClassList() {
        const { buildClassName, isLoading, isOpen, items } = this.props;
        const isChildren = items.some(item => Boolean(item.children));
        return buildClassName('items __fixedList', {
            '__open': isOpen,
            '__loading': isLoading,
            '__withoutChildren': !isChildren
        });
    }
    get listStyles() {
        if (!this.input)
            return;
        const rect = this.input.getBoundingClientRect();
        if (rect.left != MOBILE_LEFT_GAP)
            return { left: `-${rect.left - MOBILE_LEFT_GAP}px` };
    }
    render() {
        const { isRequired, inputValue, selectedValue, items, buildClassName, disabled = false, label, isOpen, tag: Tag = 'input' } = this.props;
        const { isLabelFloated } = this.state;
        const isItemsShown = isOpen;
        const className = buildClassName('autocomplete', { '__autocompleteFixed': true, '__labelFloated': isLabelFloated, '__isDropdownOpen': isItemsShown });
        return (h("div", { className: className, onFocusCapture: this.handleFocusAutocomplete, onBlurCapture: this.handleBlurAutocomplete, tabIndex: 0 },
            h("label", { className: buildClassName('label') },
                label,
                isRequired && (h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*"))),
            h(Tag, { required: isRequired, className: buildClassName('input', { '__isDropdownOpen': isItemsShown }), value: inputValue, disabled: disabled, autocomplete: 'new-autocomplete', onInput: this.handleInput, ref: this.handleRef }),
            h(AutocompleteTree, { inputValue: inputValue, items: items, onClick: this.handleChange, onChildClick: this.handleChange, getLabel: (item) => item.text, isSelected: (item) => item.id === selectedValue.id, rootClassName: this.listClassList, notFoundJsx: items.length === 0 ? h("p", null, "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E") : undefined, headJSX: h("h5", { className: buildClassName('itemsTitle'), onClick: this.handleBlurAutocomplete }, "\u041F\u0440\u0435\u0434\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u044F"), rootStyles: this.listStyles })));
    }
}
