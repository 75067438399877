var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { VController, register } from 'core/services/Controller';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
import { createVDOMRenderer } from 'core/services/VDOM';
const App = asyncComponent(() => import(/* webpackChunkName: "site_c-UserSettings-App" */ './App'), 'App');
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.renderer = createVDOMRenderer(this.el, () => {
            return h(App, Object.assign({}, this.props));
        });
    }
    init() {
        loadComponentsStyles('UserSettings').finally(this.renderer.render);
    }
};
default_1 = __decorate([
    register('UserSettings')
], default_1);
export default default_1;
