import Axios from 'axios';
import { AXIOS_CONFIG_V2 } from './config';
import requestInterceptors from './interceptors/request';
import { successHandlers, failHandlers, failFormHandlers } from './interceptors/response';
const api = Axios.create(AXIOS_CONFIG_V2);
requestInterceptors.forEach(interceptor => {
    api.interceptors.request.use(interceptor);
});
api.interceptors.response.use(response => successHandlers.reduce((response, handler) => {
    handler(response);
    return response;
}, response), ({ response }) => {
    if (!response)
        return;
    let base = null;
    if (Array.isArray(response.data.errors)) {
        base = response.data.errors.find(({ name }) => name === 'base');
    }
    const handlers = response.data.flash || base ? failHandlers : failFormHandlers;
    handlers.reduce((response, handler) => {
        handler(response);
        return response;
    }, response);
    return Promise.reject(response);
});
export { api };
