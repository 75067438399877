var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    async init() {
        // @TODO migrate ArticleQuizWidget to ts
        // @ts-ignore
        let { ArticleQuizWidget } = await import(
        /* webpackChunkName: 'common__c-ArticleQuiz' */
        'site/widgets/ArticleQuizWidget');
        this.wArticleQuiz = ArticleQuizWidget($(this.el), {
            props: {
                quiz: this.props.quiz,
                baseResultUrl: this.props.baseResultUrl
            },
            callbacks: {
                CALLBACK_START: () => {
                    return Http.post(`/front_api/quizzes/${this.props.quiz.id}/start`);
                },
                CALLBACK_ANSWER: (question, answer) => {
                    return Http.post(`/front_api/quizzes/${this.props.quiz.id}/questions/${question.id}/answer`, {
                        data: {
                            answer: answer.id
                        }
                    });
                }
            }
        });
    }
    destroy() {
        this.wArticleQuiz.destroy();
        this.wArticleQuiz = null;
    }
};
default_1 = __decorate([
    register('ArticleQuiz')
], default_1);
export default default_1;
