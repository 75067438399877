import { Component } from 'preact';
import h from '../../cssScope';
import { Text as UIText } from 'core/components/UI/Inputs/Text/Text';
import { Button } from '../../components/Button';
import { COMMIT_SET_PAGE } from '../../store/commits/page';
import { COMMIT_SET_LOADING } from '../../store/commits/loading';
import { COMMIT_SET_COMMENT } from '../../store/commits/comment';
import { OPERATION_COMPLETE } from '../../store/operations/complete';
export class Comment extends Component {
    constructor() {
        super(...arguments);
        this.handlePrevPage = () => {
            this.context.store.commit(COMMIT_SET_PAGE, this.props.prevPage);
        };
        this.handleComplete = async () => {
            await this.context.store.operate(OPERATION_COMPLETE)
                .then(() => {
                this.context.store.commit(COMMIT_SET_PAGE, this.props.nextPage);
                this.context.store.commit(COMMIT_SET_LOADING, false);
            })
                .catch(() => {
                this.context.store.commit(COMMIT_SET_LOADING, false);
            });
        };
        this.handleChangeComment = (e) => {
            const { value } = e.target;
            this.context.store.commit(COMMIT_SET_COMMENT, value);
        };
    }
    render() {
        const { comment, isLoading } = this.context.store.getState();
        return (h("div", { className: 'sb-Page sb-Comment' },
            h("h1", { className: 'sb-Title' }, "\u0427\u0442\u043E \u043D\u0430\u043C \u0432\u0430\u0436\u043D\u043E \u0437\u043D\u0430\u0442\u044C \u043F\u0440\u0438 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0435?"),
            h("p", { className: 'sb-Comment_mainInfo' }, "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435, \u043F\u043E\u0434\u044A\u0435\u0434\u0435\u0442 \u043B\u0438 \u043A \u0432\u0430\u0448\u0435\u043C\u0443 \u0434\u043E\u043C\u0443 \u043C\u0430\u0448\u0438\u043D\u0430 \u0432\u044B\u0448\u0435 2 \u043C\u0435\u0442\u0440\u043E\u0432 \u0438 \u043D\u0435 \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D \u043B\u0438 \u043F\u0440\u043E\u0435\u0437\u0434. \u0415\u0441\u043B\u0438 \u0430\u0432\u0442\u043E \u043D\u0435 \u043F\u0440\u043E\u0435\u0434\u0435\u0442 \u2014 \u043C\u0435\u0431\u0435\u043B\u044C \u043F\u0440\u0438\u0434\u0435\u0442\u0441\u044F \u043D\u0435\u0441\u0442\u0438 \u0432\u0440\u0443\u0447\u043D\u0443\u044E*"),
            h(UIText, { tag: 'textarea', label: '\u0412\u0430\u0436\u043D\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043F\u0440\u0438 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0435', placeholder: '\u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440: \u0437\u0430\u043A\u0440\u044B\u0442\u044B\u0439 \u0434\u0432\u043E\u0440, \u043D\u0438\u0437\u043A\u0430\u044F \u0430\u0440\u043A\u0430.', value: comment !== null && comment !== void 0 ? comment : '', onInput: this.handleChangeComment, isLabelFixed: true }),
            h("p", { className: 'sb-Comment_additionalInfo' }, "*\u041F\u0435\u0440\u0432\u044B\u0435 15\u043C \u2014 \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0430 \u0434\u0430\u043B\u0435\u0435 \u2014 200 \u0440\u0443\u0431. \u0437\u0430 \u043A\u0430\u0436\u0434\u044B\u0435 \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435 10\u043C. \u0412\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u043C\u043E\u0436\u0435\u0442\u0435 \u043E\u0442\u043A\u0430\u0437\u0430\u0442\u044C\u0441\u044F \u043E\u0442 \u044D\u0442\u043E\u0439 \u043F\u043B\u0430\u0442\u043D\u043E\u0439 \u0443\u0441\u043B\u0443\u0433\u0438 \u0438 \u0443\u043D\u0435\u0441\u0442\u0438 \u043C\u0435\u0431\u0435\u043B\u044C \u0438\u0437 \u0437\u043E\u043D\u044B \u0440\u0430\u0437\u0433\u0440\u0443\u0437\u043A\u0438 \u0441\u0430\u043C\u0438."),
            h("div", { className: 'sb-Actions' },
                h(Button, { mod: 'primaryLight', onClick: this.handlePrevPage }, "\u041D\u0430\u0437\u0430\u0434"),
                h(Button, { onClick: this.handleComplete, disabled: isLoading }, "\u0413\u043E\u0442\u043E\u0432\u043E"))));
    }
}
