import { HttpCache } from './abstract';
const minute = 60000;
export class DefaultHttpCache extends HttpCache {
    constructor() {
        super(...arguments);
        this._map = new Map();
        this._ttl = 10 * minute;
    }
    /**
     * Синглтон
     * @example
     * ```ts
     * cache.shared.get('key');
     * ```
     */
    static get shared() {
        if (!DefaultHttpCache.sharedInstance) {
            DefaultHttpCache.sharedInstance = new DefaultHttpCache();
        }
        return DefaultHttpCache.sharedInstance;
    }
    /**
     * Время жизни кеша в миллисекундах
     * @example
     * ```ts
     * cache.ttl = 60000;
     * cache.ttl = Infinity;
     * cache.tll = 0;
     *
     * // негативные значения игнорируются
     * cache.ttl = -1;
     * cache.ttl = Number.NEGATIVE_INFINITY;
     * ```
     */
    get ttl() {
        return this._ttl;
    }
    set ttl(ttl) {
        if (typeof ttl === 'number' && ttl >= 0) {
            this._ttl = ttl;
        }
    }
    /**
     * Количество элементов в кеше
     */
    get size() {
        return this._map.size;
    }
    get(key) {
        const data = this._map.get(key);
        if (!data)
            return null;
        if (data.expires <= Date.now()) {
            this.delete(key);
            return null;
        }
        return data.data;
    }
    set(key, data) {
        this._map.set(key, {
            data,
            expires: Date.now() + this.ttl
        });
        return this;
    }
    delete(key) {
        this._map.delete(key);
        return this;
    }
    reset() {
        this._map.clear();
        return this;
    }
}
