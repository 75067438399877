export function COMMIT_SET_NOTICES(state, notices) {
    return {
        ...state,
        notices: {
            ...state.notices,
            ...notices
        }
    };
}
export function COMMIT_REMOVE_NOTICES(state, fieldKey) {
    const notices = state.notices;
    delete notices[fieldKey];
    return {
        ...state,
        notices
    };
}
