import { getSuggestionsByGeolocate } from 'site/VControllers/SinglePageCheckout/services/DaDataApi/api/suggestions';
import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
import { updateCartInfo } from '../api/form';
import { COMMIT_CHANGE_LOADING } from '../commits/changeLoading';
import { COMMIT_REFRESH_CHECKOUT } from '../commits/refreshCheckout';
import { COMMIT_SET_YANDEX_PICKUP } from '../commits/yandex';
import { OPERATION_LOAD_PICKUP_POINTS } from './form';
export function OPERATION_SET_YANDEX_PICKUP(store) {
    return async function ({ id, coordinates, price, address, postings }) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        const { formFields } = store.getState();
        const { data: { suggestions } } = await getSuggestionsByGeolocate({ lat: coordinates[0], lon: coordinates[1] });
        if (suggestions.length === 0)
            return;
        const { settlementKladrId, cityKladrId } = suggestions[0].data;
        const country = formFields.find(({ key }) => key === FieldsKeys.Country);
        if (!country)
            return;
        return updateCartInfo({
            deliveryPrice: price,
            postingsDeliveryPrice: postings,
            cityId: settlementKladrId || cityKladrId,
            countryId: country.value,
            pickupPointId: id,
            pickupPointCoordinates: coordinates,
            pickupPointAddress: address
        })
            .then((result) => {
            store.commit(COMMIT_SET_YANDEX_PICKUP, { id });
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
            store.operate(OPERATION_LOAD_PICKUP_POINTS);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
