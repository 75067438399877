import { Component } from 'preact';
import { withStyleLoading } from 'core/components/Hoc/withStyleLoading';
import htmlClassNames from 'core/utils/htmlClassNames';
import h from './cssScope';
import { Item } from './components/Item';
class AutocompleteTree extends Component {
    render() {
        const { items, className, rootClassName, notFoundJsx, headJSX, maxDropdownHight, rootStyles, ...props } = this.props;
        return (h("div", { className: htmlClassNames('ScopeRoot', rootClassName), style: rootStyles },
            headJSX && h("div", { className: 'sb-Head' }, headJSX),
            h("ul", { className: htmlClassNames('sb-List __root', className), style: { maxHeight: maxDropdownHight } },
                items.map((item, i) => h(Item, Object.assign({ key: i, item: item }, props, { root: true }))),
                notFoundJsx && !!props.inputValue && (h("li", { className: 'sb-Item __root' }, notFoundJsx)))));
    }
}
export default function () {
    return withStyleLoading(AutocompleteTree, 'AutocompleteTree');
}
