var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.afterAnswer = (counters) => {
            this.el.classList.add('is-answered');
            this.findElements('%{MediaFeedback/counter}').forEach(el => {
                el.innerText = counters[el.dataset.counter].toString();
            });
        };
        this.handleAnswer = async (e) => {
            const answer = e.target.dataset.answer;
            const { no_count: no, yes_count: yes } = await Http.post(this.props.url, { data: { answer } });
            this.afterAnswer({ no, yes });
        };
    }
    init() {
        this.bindEvent('click on %{MediaFeedback/answer}', this.handleAnswer);
    }
};
default_1 = __decorate([
    register('MediaFeedback')
], default_1);
export default default_1;
