import { render } from 'preact'

export function createVDOMRenderer(el, renderFn) {
  let prevEl = null

  return {
    render: (...args) => {
      prevEl = render(renderFn(...args), el, prevEl)
    },

    destroy: () => {
      render('', el, prevEl)

      prevEl = null
    }
  }
}
