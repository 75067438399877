var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
import HttpRequestBatcher from 'core/services/HttpRequestBatcher';
// @ts-ignore
import { fetchProductsAd } from 'core/services/HttpRequestBatcher/batchers/posts';
import { LazyBlock } from 'core/services/LazyBlock';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.wSlider = null;
        this.shouldInit = () => {
            return !$.cookie('c-ArticleGalleryProducts:isHidden');
        };
        this.init = async () => {
            const products = await this.fetchProducts();
            if (products.length > 0) {
                await this.renderProducts(products);
                this.el.classList.remove('is-hidden');
                await this.connectSliderWidget();
                await LazyBlock.wait($(this.el));
                this.el.classList.add('is-animated');
            }
        };
        this.destroy = () => {
            this.disconnectSliderWidget();
        };
        this.fetchProducts = () => {
            return HttpRequestBatcher
                .request(fetchProductsAd, { photoId: this.props.photoId })
                .then((products = []) => products);
        };
        this.renderProducts = (products) => {
            return new Promise(resolve => {
                const html = products.join('');
                requestAnimationFrame(() => {
                    const slides = this.findElement('%{c-ArticleGalleryProducts/slides}');
                    if (slides) {
                        slides.innerHTML = html;
                    }
                    connect();
                    resolve();
                });
            });
        };
        this.connectSliderWidget = async () => {
            const { SliderWidget } = await import(
            /* webpackChunkName: 'common__w-Slider2' */
            'site/widgets/SliderWidget');
            const slides = this.findElement('%{c-ArticleGalleryProducts/slides}');
            this.wSlider = SliderWidget($(slides), {
                carousel: false,
                arrows: true,
                slidesToShow: 6
            });
            this.wSlider.init();
        };
        this.disconnectSliderWidget = () => {
            if (this.wSlider) {
                this.wSlider.destroy();
                this.wSlider = null;
            }
        };
    }
};
default_1 = __decorate([
    register('ArticleGalleryProducts')
], default_1);
export default default_1;
