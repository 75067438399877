import 'core/assetsPathOnFly'
import 'core/polyfills'
import 'core/featureTest'

import 'core/vendor/jquery.3.2.0'
import 'core/vendor/jquery_ujs'
import 'core/vendor/evil-blocks'
import 'core/vendor/jquery.easie'
import 'core/vendor/jquery.cookie'
import 'core/vendor/jquery.element'

/* Global */
import 'site/global/needsAuth'
import 'site/global/needsAddCartParams'
import 'site/global/photoLink'
import 'site/global/botDetection'

/* Controllers */
import 'core/controllers/Avatar'
import 'core/controllers/PhoneNumber'
import 'core/controllers/Rating'
import 'site/controllers/Accordion'
import 'site/controllers/ArticlePoll'
import 'site/controllers/HeaderTabs'
import 'site/controllers/AdminPanel'
import 'site/controllers/ArticleGallery'
import 'site/controllers/ArticleQuiz'
import 'site/controllers/AutoplayVideo'
import 'site/controllers/BtnProductQuickView'
import 'site/controllers/BtnRequestInfo'
import 'site/controllers/BtnShare'
import 'site/controllers/BtnFavorite'
import 'site/controllers/BtnRequestProductDesignerPrice'
import 'site/controllers/BtnRelatedProducts'
import 'site/controllers/CartItemsCounter'
import 'site/controllers/ClickedButton'
import 'site/controllers/Counters'
import 'site/controllers/FastLinks'
import 'site/controllers/FavoriteItemsCounter'
import 'site/controllers/FloatActions'
import 'site/controllers/HeaderSubnav'
import 'site/controllers/HeaderAllCategories'
import 'site/controllers/HeaderAllCategoriesLazy'
import 'site/controllers/InfinityItemList'
import 'site/controllers/LazyVideo'
import 'site/controllers/PopupBanner'
import 'site/controllers/Postable/PaginationPoint'
import 'site/controllers/Postable/ReadyPoint'
import 'site/controllers/PostPreview'
import 'site/controllers/MaskedInput'
import 'site/controllers/MediaFeedback'
import 'site/controllers/PhotoUploader'
import 'site/controllers/Products/ChangeableProductCard'
import 'site/controllers/Products/ChangeableProductQuickView'
import 'site/controllers/Products/ProductPreview'
import 'site/controllers/ProductAvailabilityForm'
import 'site/controllers/ScrollableProductGallery'
import 'site/controllers/ProductList'
import 'site/controllers/ReadMore'
import 'site/controllers/RecipeFiltersSwitcher'
import 'site/controllers/SnapSlider'
import 'site/controllers/RotatableBanner'
import 'site/controllers/ShortLinkGenerator'
import 'site/controllers/SmoothAnchor'
import 'site/controllers/SubscribeForm'
import 'site/controllers/Sticker'
import 'site/controllers/StickySidebar'
import 'site/controllers/UrlCopy'
import 'site/controllers/UserContacts'
import 'site/controllers/UserContactFormLink'
import 'site/controllers/UserRating'
import 'site/controllers/CategoryLandingSlider'
import 'site/controllers/DocumentsNav'
import 'site/controllers/ReviewsSlider'
import 'site/controllers/UploadPhotoToCollection'
import 'site/controllers/LazyPhoto'
import 'site/controllers/ToggleMore'
import 'site/controllers/PhotoLinkList'
import 'site/controllers/UserList'
import 'site/controllers/BrandSlider'
import 'site/controllers/PaginatedItemList'
import 'site/controllers/ActionPanel'
import 'site/controllers/Reactions'
import 'site/controllers/ArticleGalleryProducts'
import 'site/controllers/BtnAddToFavCollection'
import 'site/controllers/Comments'
import 'site/controllers/DevPanel'
import 'site/controllers/CityAutocomplete'
import 'site/controllers/FavCollectionPreview'
import 'site/controllers/PhotoList'
import 'site/controllers/PhotoMap'
import 'site/controllers/LegacyLoadMore'
import 'site/controllers/CollapsableCategoriesPreviews'
import 'site/controllers/MailReview'
import 'site/controllers/PhotoModalInfo'
import 'site/controllers/ProductReview'
import 'site/controllers/RequestServiceForm'
import 'site/controllers/SalePostExpireAt'
import 'site/controllers/PhotoRelatedProducts'
import 'site/controllers/CategoriesAutocomplete'
import 'site/controllers/LabledField'
import 'site/controllers/ImportantNews'
import 'site/controllers/VisibilityTracker'
import 'site/controllers/RadioSection'
import 'site/controllers/SearchSwitcher'
import 'site/controllers/UserProfileCover'

/* VControllers */
import 'site/VControllers/Chats'
import 'site/VControllers/SinglePageCheckout/v2'
import 'site/VControllers/SinglePageCheckout/v2/FrontInit'
import 'site/VControllers/DeliveryCalendar'
import 'site/VControllers/Autocomplete'
import 'site/VControllers/LazyHtml'
import 'site/VControllers/PhotoZoom'
import 'site/VControllers/Products/DeliveryInfo'
import 'site/VControllers/Products/ProductVariants'
import 'site/VControllers/Products/ShowPriceBlock'
import 'site/VControllers/Products/HeaderInfo'
import 'site/VControllers/ProfiCategoriesForm'
import 'site/VControllers/ProfiEducationField'
import 'site/VControllers/AuthModal'
import 'site/VControllers/CitySelect'
import 'site/VControllers/ReturnForm'
import 'site/VControllers/RegisterReclamation/RadioHideForm'
import 'site/VControllers/InstallmentBuy'
import 'site/VControllers/Installments'
import 'site/VControllers/CartActions'
import 'site/VControllers/UserSettings'
import 'site/VControllers/CartModalAside'
import 'site/VControllers/ScrollToSite'
import 'site/VControllers/AddProductReview'
import 'site/VControllers/YandexDeliveryMap'
import 'site/VControllers/SuggestedProductModal'
import 'site/VControllers/CheaperTogether'
import 'site/VControllers/DiscountTimer'

/* Bootstrap */
import 'site.desktop/bootstrap/gtmHandlers'
import 'core/bootstrap'
import 'site/bootstrap'

$.cookie.defaults = { domain: Env.cookieDomain, path: '/' }

/* global require */

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

window.Stimulus = Application.start()
const context = require.context('./scontrollers', true, /\.ts$/)
window.Stimulus.load(definitionsFromContext(context))
