import EventEmitter from 'eventemitter3'

import withStore from './withStore'
import Provider  from './Provider'

function Store({
  props: {
    initialState
  },

  callbacks: {
    CALLBACK_COMMAND_CONTEXT  = () => {},
    CALLBACK_BEFORE_COMMIT    = () => {},
    CALLBACK_AFTER_COMMIT     = () => {},
    CALLBACK_BEFORE_OPERATION = () => {},
    CALLBACK_AFTER_OPERATION  = () => {},
  } = {}
}) {
  let self          = {}

  let eventEmitter = new EventEmitter()
  let currentState = initialState

  let commit = (commitFunc, ...payload) => {
    console.log('%c%s', 'background: #4a148c; color: #f3e5f5; font-size: 0.9em; padding: 2px 10px;', commitFunc.name, payload)

    eventEmitter.emit('beforeCommit', currentState, commitFunc, payload)

    currentState = { ...currentState, ...commitFunc(currentState, ...payload) }

    eventEmitter.emit('afterCommit', currentState, commitFunc, payload)
  }

  // @LEGACY
  let runCommand = (commandFunc, ...payload) => {
    console.warn(commandFunc.name, payload)

    return commandFunc({
      ...CALLBACK_COMMAND_CONTEXT(),
      store: self
    }, ...payload)
  }

  let operate = async (operationFunc, ...payload) => {
    eventEmitter.emit('beforeOperation', currentState, operationFunc, payload)

    let result = await operationFunc(self)(...payload)

    eventEmitter.emit('afterOperation', currentState, operationFunc, payload)

    return result
  }

  let getState = () => {
    return currentState
  }

  let on = (eventName, callback) => {
    eventEmitter.on(eventName, callback)
  }

  let off = (eventName, callback) => {
    eventEmitter.off(eventName, callback)
  }

  eventEmitter.on('afterCommit',  CALLBACK_AFTER_COMMIT)
  eventEmitter.on('beforeCommit', CALLBACK_BEFORE_COMMIT)

  eventEmitter.on('beforeOperation', CALLBACK_BEFORE_OPERATION)
  eventEmitter.on('afterOperation', CALLBACK_AFTER_OPERATION)

  return self = {
    commit,
    operate,
    getState,
    runCommand,
    off,
    on
  }
}

export default Store

export { withStore, Provider }
