import { h, Component } from 'preact';
import { Text as UIText } from 'core/components/UI/Inputs/Text/Text';
export class Text extends Component {
    constructor() {
        super(...arguments);
        this.componentDidMount = () => {
            this.handleFocusOnLoad();
        };
        this.handleFocusOnLoad = () => {
            if (this.props.focusOnLoad && this.inputRef) {
                setTimeout(() => {
                    this.inputRef.scrollIntoView({ behavior: 'smooth' });
                    this.inputRef.focus();
                }, 1000);
            }
        };
        this.handleChange = () => {
            if (!this.inputRef)
                return;
            this.props.onChange(this.inputRef.value);
        };
        this.handleRef = (el) => {
            var _a, _b;
            this.inputRef = el;
            (_b = (_a = this.props).onRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        };
    }
    render() {
        const { field: { label, value, isRequired }, name, type = 'text', tag = 'input', disabled = false, rows, isOverflowText } = this.props;
        return (h(UIText, { label: label, value: value, name: name, type: type, tag: tag, required: isRequired, disabled: disabled, inputRef: this.handleRef, onInput: this.handleChange, rows: rows, isOverflowText: isOverflowText }));
    }
}
