export class HttpCache {
    /**
     * Сгенерировать уникальный ключ кеша из параметров http-запроса
     * @example
     * ```ts
     * cache.serializeCacheKey({
     *   url: 'https://example.com',
     *   body: { key: "value" },
     *   method: "POST"
     * })
     * ```
     */
    serializeCacheKey(payload) {
        try {
            return JSON.stringify(payload);
        }
        catch (_e) {
            // на случай попытки сериализации объекта с циклическими зависимостями внутри
            return payload.url + String(Math.random());
        }
    }
}
