import { h, Component } from 'preact';
import generateId from 'core/utils/generateId';
import { FileUploader } from 'core/components/UI/Inputs/FileUploader';
export class DirectUploadFiles extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            files: [],
            isLoading: false
        };
        this.handleAddFile = (file) => {
            this.setState(({ files: prevFiles }) => {
                return {
                    files: [...prevFiles, { ...file, id: generateId() }]
                };
            });
            this.props.onChange(this.state.files);
        };
        this.handleRemoveFile = (id) => () => {
            this.setState(({ files: prevFiles }) => {
                return {
                    files: prevFiles.filter(file => file.id !== id)
                };
            });
            this.props.onChange(this.state.files);
        };
    }
    render() {
        const { field: { label, whiteList, buttonTitle, fileSizeLimit } } = this.props;
        return (h("div", { className: 'ui-Input __files' },
            h("div", { className: 'ui-Input_fileUploader' },
                h("label", null, label),
                h(FileUploader, { files: this.state.files, buttonTitle: buttonTitle, accept: whiteList, onAddFile: this.handleAddFile, onRemoveFile: this.handleRemoveFile, loading: this.state.isLoading, fileSizeLimit: fileSizeLimit }))));
    }
}
