import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
export default class extends Component {
    get priceClasses() {
        return htmlClassNames('g-Col__6', 'b-ProfiCategoriesForm_categoryPrice', { '__isDisabled': !this.props.isActive });
    }
    render() {
        return (h("div", { className: 'b-ProfiCategoriesForm_category' },
            h("input", { type: 'hidden', name: 'user[profi_categories][][id]', value: this.props.id }),
            h("div", { className: 'g-Col__6' },
                h("input", { type: 'hidden', name: 'user[profi_categories][][is_active]', value: `${(!!this.props.isActive)}` }),
                h("div", { className: 'ui-Input __check' },
                    h("label", { className: 'ui-Input_label', for: `profi_categories_${this.props.id}` }, this.props.title),
                    h("label", { className: 'checkbox' },
                        h("input", { id: `profi_categories_${this.props.id}`, onChange: this.props.onCheck, className: 'ui-Input_input', type: 'checkbox', checked: this.props.isActive }),
                        h("span", null)))),
            h("div", { class: this.priceClasses },
                h("div", { className: 'ui-Input __text __labelFloated' },
                    h("div", { className: 'ui-Input_label' }, this.props.unit),
                    h("input", { name: 'user[profi_categories][][price]', value: this.props.price, required: !!this.props.isActive, className: 'ui-Input_input' })))));
    }
}
