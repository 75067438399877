var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import WayPointService from 'core/services/WayPointService';
import { documentDispatch } from 'core/utils/events';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.placeholder = this.findElement('%{Sticker/placeholder}');
        this.isFixed = false;
        this.setFixed = (flag) => () => {
            const { name, isPlaceholderWithActualHeight = false } = this.props;
            this.isFixed = flag;
            if (name)
                documentDispatch(`Sticker:${name}`, { isFixed: flag });
            requestAnimationFrame(() => {
                if (isPlaceholderWithActualHeight)
                    this.updatePlaceholderHeight();
                this.el.classList.toggle('__isFixed', this.isFixed);
            });
        };
        this.updatePlaceholderHeight = () => {
            this.placeholder.style.height = this.isFixed ? `${this.el.getBoundingClientRect().height}px` : '';
        };
        this.initWatcher = () => {
            this.watcherId = WayPointService.watch([
                {
                    getPoint: () => this.placeholder.getBoundingClientRect().top + window.pageYOffset,
                    onUp: this.setFixed(false),
                    onDown: this.setFixed(true)
                }
            ]);
        };
    }
    init() {
        if (!this.placeholder)
            return;
        this.initWatcher();
    }
    destroy() {
        WayPointService.unwatch(this.watcherId);
    }
};
default_1 = __decorate([
    register('Sticker')
], default_1);
export default default_1;
