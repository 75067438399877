const DEBUG = Env.name === 'development';
export const CONTAINERS = {
    products: {
        catalog: 'products/catalog',
        show: 'products/show',
        showCrossSell: 'products/show/cross-sell',
        showSameCollection: 'products/show/same-collection',
        showBestsellers: 'products/show/bestsellers',
        showComparison: 'products/show/comparison',
        showPopular: 'products/show/popular'
    }
};
export function getGtmContainer(el) {
    const parent = el.closest('[data-gtm-container]');
    return parent ? parent.dataset.gtmContainer : '';
}
export function pushToDataLayer(data) {
    requestIdleCallback(() => {
        []
            .concat(data || [])
            .forEach(data => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
            if (DEBUG) {
                console.log('Push to DataLayer: ', data);
            }
        });
    }, { timeout: 1000 });
}
