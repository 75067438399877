import { h, Component } from 'preact';
import { Checkbox as UICheckbox } from 'core/components/UI/Inputs/Checkbox';
export class Checkboxes extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            result: []
        };
        this.handleChange = (e) => {
            const target = e.target;
            if (this.state.result.includes(target.name)) {
                this.setState({ result: this.state.result.filter(category_type => category_type != target.name) });
            }
            else {
                this.setState({ result: [...this.state.result, target.name] });
            }
            this.props.onChange(this.state.result);
        };
    }
    render() {
        const { field: { label, options } } = this.props;
        return (h("div", null,
            h("div", { className: 'ui-Input_checkboxesLabel' }, label),
            h("br", null),
            options.map((option) => (h(UICheckbox, { name: option.value, label: option.label, isChecked: this.state.result.includes(option.value), onChange: this.handleChange, extraClasses: '__marginBottom' })))));
    }
}
