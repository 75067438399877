import { getCartId, HEADER_KEY, isStoreFallbackState, getCartIdFromMeta } from 'site/VControllers/components/Checkout/services/statefullCart';
function csrfToken(config) {
    const tokenStoreEl = document.querySelector('meta[name=csrf-token]');
    if (tokenStoreEl) {
        config.headers['X-CSRF-Token'] = tokenStoreEl.getAttribute('content');
    }
    return config;
}
function assignBaseUrl(config) {
    return { ...config, baseURL: Env.baseUrl };
}
function assignShoppingCart(config) {
    const cartId = isStoreFallbackState() ? getCartId() : getCartIdFromMeta();
    if (cartId)
        config.headers[HEADER_KEY] = cartId;
    return config;
}
export default [csrfToken, assignBaseUrl, assignShoppingCart];
