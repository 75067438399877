import { h, Component } from 'preact';
import { documentDispatch } from 'core/utils/events';
import { ADD_ERRORS_EVENT } from './events';
import { initialState, createStore } from './store';
import { COMMIT_ADD_ERRORS } from './commits/addErrors';
import { COMMIT_CLEAR_NOTICES } from './commits/clearNotices';
const store = createStore(initialState);
export class FormNoticeService {
    static errors(errors) {
        store.commit(COMMIT_ADD_ERRORS, errors);
        documentDispatch(ADD_ERRORS_EVENT, { errors });
    }
    static clear() {
        store.commit(COMMIT_CLEAR_NOTICES);
    }
    static get notices() {
        return store.getState().notices;
    }
}
export function withFormNoticeService(WrappedComponent) {
    return class extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                noticesStore: createStore(initialState, (currentState) => { this.setState(currentState); })
            };
            this.handleAddErrors = ({ detail: { errors } }) => {
                this.state.noticesStore.commit(COMMIT_ADD_ERRORS, errors);
            };
        }
        componentDidMount() {
            document.addEventListener(ADD_ERRORS_EVENT, this.handleAddErrors);
        }
        componentWillUnmount() {
            FormNoticeService.clear();
            document.removeEventListener(ADD_ERRORS_EVENT, this.handleAddErrors);
        }
        render() {
            return h(WrappedComponent, Object.assign({}, this.props, { noticesStore: this.state.noticesStore }));
        }
    };
}
