import { h, Component } from 'preact';
import rAFThrottle from 'core/utils/rAFThrottle';
export class MultipleRange extends Component {
    constructor() {
        super(...arguments);
        this.getDelta = () => Math.abs(this.props.min - this.props.max);
        this.getPointFromValue = (value) => {
            const valuePart = value - this.props.min;
            const rangePart = valuePart / this.getDelta();
            const point = this.rangeWidth * rangePart;
            return point;
        };
        this.getValueFromPoint = (point) => {
            const rangePart = point / this.rangeWidth;
            const valuePart = rangePart * this.getDelta();
            const value = valuePart + this.props.min;
            return Math.floor(value);
        };
        //#endregion
        //#region compute styles
        this.getThumbPositionStyle = (type) => {
            const left = this.state[type] - this.thumbOffset;
            return { left };
        };
        this.getActiveLineStyles = () => {
            const { currentMax, currentMin } = this.state;
            const width = currentMax - currentMin + this.thumbOffset;
            const { left } = this.getThumbPositionStyle('currentMin');
            return { left, width };
        };
        //#endregion
        //#region drag'n'drop handlers
        this.handleMouseDown = (dragThumb) => (e) => {
            this.handleDragStart(dragThumb, e.clientX, e, () => {
                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
            });
        };
        this.handleMouseMove = (e) => this.handleDrag(e.clientX);
        this.handleMouseUp = () => {
            this.handleDragEnd();
            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.handleMouseUp);
        };
        this.handleDragLeftThumb = (position) => {
            const rightEdge = this.thumbRightEl.offsetLeft - this.thumbOffset;
            if (position < 0)
                position = 0;
            else if (position > rightEdge)
                position = rightEdge;
            this.setState({ currentMin: position });
        };
        this.handleDragRightThumb = (position) => {
            const leftEdge = (this.thumbLeftEl.offsetLeft - this.thumbLeftEl.clientLeft) + this.thumbLeftEl.offsetWidth + this.thumbOffset;
            const rightEdge = this.rangeEl.offsetWidth;
            if (position < leftEdge)
                position = leftEdge;
            else if (position > rightEdge)
                position = rightEdge;
            this.setState({ currentMax: position });
        };
        this.handleTouchStart = (dragThumb) => (e) => {
            this.handleDragStart(dragThumb, e.touches[0].clientX, e);
        };
        this.handleTouchMove = (e) => this.handleDrag(e.touches[0].clientX);
        this.handleTouchEnd = () => this.handleDragEnd();
        this.handleDragStart = (dragThumb, clientX, e, callback) => {
            e.preventDefault();
            const target = e.currentTarget;
            const dragOffset = clientX - target.getBoundingClientRect().left - this.thumbOffset;
            this.setState({ dragOffset, dragThumb }, callback === null || callback === void 0 ? void 0 : callback());
        };
        this.handleDrag = (clientX) => {
            const position = clientX - this.state.dragOffset - this.rangeEl.getBoundingClientRect().left;
            this.handleChange();
            this.state.dragThumb === 'left'
                ? this.handleDragLeftThumb(position)
                : this.handleDragRightThumb(position);
        };
        this.handleDragEnd = () => {
            this.handleChange('end');
            this.setState({ dragOffset: 0, dragThumb: null });
        };
        //#endregion
        this.handleChange = rAFThrottle((type) => {
            const { dragThumb, currentMin, currentMax } = this.state;
            let { onMaxChange: handlerMax, onMinChange: handlerMin } = this.props;
            if (type === 'end') {
                handlerMax = this.props.onMaxChangeEnd;
                handlerMin = this.props.onMinChangeEnd;
            }
            dragThumb === 'left'
                ? handlerMin === null || handlerMin === void 0 ? void 0 : handlerMin(this.getValueFromPoint(currentMin), this.rangeEl) : handlerMax === null || handlerMax === void 0 ? void 0 : handlerMax(this.getValueFromPoint(currentMax), this.rangeEl);
        });
        this.setPoints = (props) => {
            const { min, max } = props;
            const { minValue = min, maxValue = max } = props;
            this.setState({
                currentMin: this.getPointFromValue(minValue),
                currentMax: this.getPointFromValue(maxValue)
            });
        };
    }
    //#region compute points
    get thumbWidth() { var _a, _b; return (_b = (_a = this.thumbLeftEl) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0; }
    get thumbOffset() {
        return this.thumbWidth / 2;
    }
    get rangeWidth() { var _a, _b; return (_b = (_a = this.rangeEl) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0; }
    componentWillUpdate(nextProps) {
        this.setPoints(nextProps);
    }
    componentWillReceiveProps(nextProps) {
        // This needs because DOM renders after lifecycle events
        setTimeout(() => this.setPoints(nextProps), 0);
    }
    componentDidMount() {
        this.setPoints(this.props);
    }
    render() {
        return (h("div", { className: 'ui-Input __range', ref: el => this.rangeEl = el },
            h("div", { className: 'ui-Input_track' },
                h("div", { className: 'ui-Input_trackLine', style: this.getActiveLineStyles() })),
            h("div", { ref: el => this.thumbLeftEl = el, className: 'ui-Input_thumb __left', style: this.getThumbPositionStyle('currentMin'), onMouseDown: this.handleMouseDown('left'), onTouchStart: this.handleTouchStart('left'), onTouchMove: this.handleTouchMove, onTouchEnd: this.handleTouchEnd }),
            h("div", { ref: el => this.thumbRightEl = el, className: 'ui-Input_thumb __right', style: this.getThumbPositionStyle('currentMax'), onMouseDown: this.handleMouseDown('right'), onTouchStart: this.handleTouchStart('right'), onTouchMove: this.handleTouchMove, onTouchEnd: this.handleTouchEnd })));
    }
}
