var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { render, h } from 'preact';
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.viewWidgetEl = null;
        this.editorWidgetEl = null;
        this.handleOpenEditor = () => {
            this.renderEditorWidget();
        };
        this.handleCloseEditor = () => {
            this.destroyEditorWidget();
        };
        this.handleSaveEditor = () => {
            this.destroyViewWidget();
            this.renderViewWidget();
        };
    }
    async init() {
        this.renderViewWidget();
        super.init();
    }
    async renderViewWidget() {
        const { PhotoMapWidget } = await import(
        /* webpackChunkName: "site_c-PhotoMapWidget" */
        './components/PhotoMapWidget');
        this.viewWidgetEl = render(h(PhotoMapWidget, {
            ...this.props,
            onOpenEditor: this.handleOpenEditor
        }), this.el);
    }
    async renderEditorWidget() {
        requestAnimationFrame(async () => {
            const { PhotoEditorWidget } = await import(
            /* webpackChunkName: "site_c-PhotoEditorWidget" */
            './components/PhotoEditorWidget');
            this.editorWidgetEl = render(h(PhotoEditorWidget, {
                ...this.props,
                onSave: this.handleSaveEditor,
                onClose: this.handleCloseEditor
            }), document.body);
        });
    }
    destroyViewWidget() {
        if (!this.viewWidgetEl)
            return;
        render(null, this.el, this.viewWidgetEl);
        this.viewWidgetEl = null;
    }
    destroyEditorWidget() {
        if (!this.editorWidgetEl)
            return;
        render(null, document.body, this.editorWidgetEl);
        this.editorWidgetEl = null;
    }
    destroy() {
        this.destroyViewWidget();
        this.destroyEditorWidget();
    }
};
default_1 = __decorate([
    register('PhotoMap')
], default_1);
export default default_1;
