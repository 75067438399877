import { getCartId, HEADER_KEY, isStoreFallbackState } from 'site/VControllers/components/Checkout/services/statefullCart';
if (isStoreFallbackState()) {
    XMLHttpRequest.prototype.open = (function (open) {
        return function (_method, _url) {
            open.apply(this, arguments);
            const cartId = getCartId();
            if (cartId)
                this.setRequestHeader(HEADER_KEY, cartId);
        };
    })(XMLHttpRequest.prototype.open);
}
