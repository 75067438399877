export var DayPeriod;
(function (DayPeriod) {
    DayPeriod["Morning"] = "morning";
    DayPeriod["Midday"] = "midday";
    DayPeriod["Evening"] = "evening";
    DayPeriod["Regional"] = "regional";
})(DayPeriod || (DayPeriod = {}));
export var PageName;
(function (PageName) {
    PageName["DeliveryDate"] = "DeliveryDate";
    PageName["AutoDeliveryDate"] = "AutoDeliveryDate";
    PageName["Comment"] = "Comment";
    PageName["ThankYou"] = "ThankYou";
    PageName["WaitContact"] = "WaitContact";
})(PageName || (PageName = {}));
