var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/HttpClient';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleContactsClick = (e) => {
            e.preventDefault();
            Http.get(this.props.url).then(({ data }) => this.el.innerHTML = data);
        };
    }
    init() {
        this.bindEvent('click on %{UserContacts/show}', this.handleContactsClick);
    }
};
default_1 = __decorate([
    register('UserContacts')
], default_1);
export default default_1;
