export function COMMIT_CLEAR_NOTICES(state) {
    return {
        ...state,
        notices: {
            errors: {},
            info: {},
            warnings: {}
        }
    };
}
