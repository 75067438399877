var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import SubscribeFormWidget from 'site/widgets/SubscribeFormWidget';
let default_1 = class extends Controller {
    init() {
        this.el.classList.add('is-visible');
        SubscribeFormWidget($(this.findElement('%{w-SubscribeForm}')), {
            props: { source: 'news_form' },
            callbacks: {
                CALLBACK_AFTER_SUBSCRIBE: ({ email }) => {
                    this.el.classList.remove('is-visible');
                    $(document).trigger('c-SubscribeForm:subscribe', { email });
                }
            }
        });
    }
};
default_1 = __decorate([
    register('SubscribeForm')
], default_1);
export default default_1;
