import { h, Component } from 'preact';
import Tooltip from 'core/components/UI/Tooltip';
function getFilesPluarl(number) {
    const many = 'файлов';
    try {
        const pluralRules = new Intl.PluralRules('ru-RU');
        switch (pluralRules.select(number)) {
            case 'one':
                return 'файл';
            case 'few':
                return 'файла';
            default:
                return many;
        }
    }
    catch (_a) {
        return many;
    }
}
export class Files extends Component {
    constructor() {
        super(...arguments);
        this.handleChangeItem = (e) => {
            const { files } = e.target;
            return files ? this.props.onChange([...files]) : [];
        };
    }
    get description() {
        const { field: { value } } = this.props;
        return value.length > 0 ? `Выбрано: ${value.length} ${getFilesPluarl(value.length)}` : 'Выберите файлы';
    }
    render() {
        const { field: { key, label, tip } } = this.props;
        return (h("div", null,
            h("div", { className: 'ui-Input __files' },
                h("input", { className: 'ui-Input_input', type: 'file', name: `${key}[]`, multiple: true, onChange: this.handleChangeItem }),
                h("label", { className: 'ui-Input_label' }, label),
                h("span", { className: 'ui-Input_description' }, this.description),
                tip && (h("div", { className: 'ui-Input_tooltip' },
                    "?",
                    h(Tooltip, { label: tip }))))));
    }
}
