function SubscribeFormWidget($el, { props, callbacks: { CALLBACK_AFTER_SUBSCRIBE = () => { } } }) {
    $el.on('submit', onSubmit);
    function onSubmit(e) {
        const email = $el.find('%{w-SubscribeForm/email}').val();
        $.post(`${Env.baseUrl}/subscribe`, { source: props.source, email })
            .done(() => saveUserId(email))
            .done(() => CALLBACK_AFTER_SUBSCRIBE({ email }));
        e.preventDefault();
    }
    function saveUserId(email) {
        $.cookie('userId', btoa(JSON.stringify({
            email,
            source: props.source,
            date: new Date()
        })));
    }
}
export default SubscribeFormWidget;
