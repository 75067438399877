import { h, Component } from 'preact';
import Http from 'core/services/HttpClient';
import { withFormState } from 'site/VDOMComponents/HOCs/withFormState';
import { Form as UIForm, FieldsRow, ActionsContainer } from 'core/components/UI/Form';
import { ActionButton } from 'core/components/UI/ActionButton';
import { Field } from '../Field';
function prepareData(fields) {
    return fields.reduce((data, field) => {
        if (!field.value || Array.isArray(field.value) && field.value.length === 0)
            return data;
        if (field.type === 'files') {
            const files = field.value;
            files.forEach((attachment) => {
                data.append(`${field.key}[]`, attachment);
            });
        }
        else if (field.type === 'directUploadFiles') {
            const files = field.value;
            files.forEach(file => {
                if (file.uploadResult)
                    return data.append(`${field.key}_key[]`, file.uploadResult.key);
                data.append(`${field.key}[]`, file.file);
            });
        }
        else if (field.type === 'checkboxes') {
            field.value.forEach((option) => {
                data.append(`${field.key}[]`, option);
            });
        }
        else {
            data.append(field.key, field.value);
        }
        return data;
    }, new FormData());
}
class FormInstance extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isLoading: false
        };
        this.handleSubmit = async (e) => {
            e.preventDefault();
            this.setState({ isLoading: true });
            const { fields } = this.props;
            const formData = prepareData(fields);
            await Http.post(this.props.submitUrl, formData);
            this.setState({ isLoading: false });
        };
    }
    render() {
        const { isLoading } = this.state;
        return (h(UIForm, { onSubmit: this.handleSubmit },
            this.props.fields.map((field) => (h(FieldsRow, null,
                h(Field, { field: field, onChange: this.props.onChangeField })))),
            h(ActionsContainer, { alignment: 'right' },
                h(ActionButton, { type: 'submit', text: '\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C', color: 'blue', disabled: isLoading })),
            h("div", { className: 'b-Form_agreement', style: { textAlign: 'center' } },
                "\u041E\u0441\u0442\u0430\u0432\u043B\u044F\u044F \u0437\u0430\u044F\u0432\u043A\u0443, \u0412\u044B \u0434\u0430\u0435\u0442\u0435 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u043F\u0440\u0438\u043D\u0438\u043C\u0430\u0435\u0442\u0435\u00A0",
                h("a", { href: `${Env.baseUrl}/pages/terms`, target: '_blank' }, "\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u043E\u0435 \u0441\u043E\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u0435"),
                "\u00A0\u0438\u00A0",
                h("a", { href: `${Env.baseUrl}/pages/pol`, target: '_blank' }, "\u043F\u043E\u043B\u0438\u0442\u0438\u043A\u0443 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438"),
                ".")));
    }
}
export const Form = withFormState(FormInstance);
