import { Component } from 'preact';
import h from '../../cssScope';
export class WaitContact extends Component {
    render() {
        return (h("div", { className: 'sb-Page sb-WaitContact' },
            h("p", { className: 'sb-WaitContact_mainInfo' }, "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A \u043D\u0430\u0448\u0435\u0439 \u0441\u043B\u0443\u0436\u0431\u044B \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438 \u0441\u0432\u044F\u0436\u0435\u0442\u0441\u044F \u0441 \u0432\u0430\u043C\u0438 \u0432 \u0431\u043B\u0438\u0436\u0430\u0439\u0448\u0435\u0435 \u0432\u0440\u0435\u043C\u044F."),
            h("strong", null, "\u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435 \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435!"),
            h("p", { className: 'sb-WaitContact_additionalInfo' }, "\u041C\u044B \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E \u0445\u0440\u0430\u043D\u0438\u043C \u043D\u0430 \u0441\u043A\u043B\u0430\u0434\u0435 \u0434\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u043D\u044B\u0435 \u0437\u0430\u043A\u0430\u0437\u044B \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 7 \u0440\u0430\u0431\u043E\u0447\u0438\u0445 \u0434\u043D\u0435\u0439."),
            h("p", { className: 'sb-WaitContact_additionalInfo' }, "\u0425\u043E\u0442\u0438\u0442\u0435 \u043F\u0435\u0440\u0435\u043D\u0435\u0441\u0442\u0438 \u0434\u0430\u0442\u0443 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438 \u043D\u0430 \u0435\u0449\u0435 \u0431\u043E\u043B\u0435\u0435 \u043F\u043E\u0437\u0434\u043D\u0438\u0439 \u0441\u0440\u043E\u043A? \u0423 \u043D\u0430\u0441 \u0435\u0441\u0442\u044C \u043F\u043B\u0430\u0442\u043D\u0430\u044F \u0443\u0441\u043B\u0443\u0433\u0430 \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F \u0437\u0430\u043A\u0430\u0437\u0430: 1 \u0434\u0435\u043D\u044C \u2014 500 \u0440\u0443\u0431\u043B\u0435\u0439. \u0415\u0441\u043B\u0438 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u0435\u0451 \u043E\u0444\u043E\u0440\u043C\u0438\u0442\u044C, \u0441\u043E\u043E\u0431\u0449\u0438\u0442\u0435 \u043E\u0431 \u044D\u0442\u043E\u043C \u043D\u0430\u0448\u0435\u043C\u0443 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0443 \u043F\u0440\u0438 \u0437\u0432\u043E\u043D\u043A\u0435.")));
    }
}
