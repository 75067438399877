import { h, Component } from 'preact';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
export function withStyleLoading(WrappedComponent, styleKey) {
    return class extends Component {
        constructor() {
            super(...arguments);
            this.state = { loading: true };
        }
        componentWillMount() {
            loadComponentsStyles(styleKey).finally(() => this.setState({ loading: false }));
        }
        render() {
            return this.state.loading ? null : h(WrappedComponent, Object.assign({}, this.props));
        }
    };
}
