var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
// @TODO описать типы для ItemList
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.itemsEl = this.findElement('%{InfinityItemList/items}');
    }
    async init() {
        let { ItemList, ItemsPlugin, LoadMorePlugin } = await import(
        /* webpackChunkName: 'common__c-InfinityItemList' */
        // @ts-ignore
        'core/services/InfinityList/lists/ItemList');
        this.itemList = ItemList({
            loadUrl: this.props.loadUrl,
            initialState: {
                items: this.props.items,
                loadMore: this.props.loadMore
            },
            plugins: [
                ItemsPlugin({
                    getSlotsForItems: (items) => {
                        return [{ $slot: $(this.itemsEl), items }];
                    }
                }),
                LoadMorePlugin({
                    $slot: $(this.el)
                })
            ],
            callbacks: {
                onBeforeLoad: () => {
                    this.el.classList.add('is-loading');
                },
                onAfterLoad: () => {
                    this.el.classList.remove('is-loading');
                }
            }
        });
        this.itemList.connect();
    }
    destroy() {
        this.itemList.disconnect();
        this.itemList = null;
    }
};
default_1 = __decorate([
    register('InfinityItemList')
], default_1);
export default default_1;
