// @TODO Переосмыслить и переделать все это

import Http                     from 'core/services/Http'
import NoticeService            from 'core/services/NoticeService'
import ValidationErrors         from 'core/services/ValidationErrors'
import Modal                    from 'core/services/LegacyModal' // remove after rewrite profi portfolio
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets'

const HttpInterceptor = (() => {
  function onSendRequest(xhr, settings) {
    const baseUrlRegExp = new RegExp(`^${Env.baseUrl}`)

    if (baseUrlRegExp.test(settings.url)) {
      settings.crossDomain = false
      settings.xhrFields   = { ...settings.xhrFields, withCredentials: true }
    }
  }

  function onErrorRequest(_e, request, params, data) {
    if (data === 'abort') return

    if (typeof (data = request.responseJSON) === 'object') {
      let { flash, errors } = data

      if (flash || errors) {
        NoticeService.clear()
      }

      if (flash) {
        NoticeService.parse(flash)
      }

      if (errors) {
        ValidationErrors.build(errors)
      }
    }
  }

  function onSuccessRequest(_e, _request, _params, data) {
    if (typeof data === 'object') {
      if (data.flash) {
        NoticeService.parse(data.flash)
      }

      if (data.redirect_to) {
        window.location.href = data.redirect_to
      }

      if (data.reload) {
        window.location.reload()
      }

      /* @TODO "Выпилить, когда будет время..."
      =============================================== */

      if (data.vitalize) {
        Component.connect(data.vitalize)
      }

      if (data.global_event === 'modal.close') {
        $(document.body).trigger('modal.close')
      }

      if (data.modal) {
        const modal = data.modal

        const options = {
          title: modal.title,
          content: modal.content,
          classes: modal.class_name
        }
        loadComponentsStyles('LegacyModal').then(() => (new Modal(options)).show())
      }

      /* Конец "Выпилить, когда будет время..."
      =============================================== */
    }
  }

  function onBeforeRequest([url, { headers, ...options }]) {
    const tokenStoreEl = document.querySelector('meta[name=csrf-token]')
    return [url, { ...options,
      headers: { ...headers,
        'X-CSRF-Token': tokenStoreEl ? tokenStoreEl.getAttribute('content') : ''
      }
    }]
  }

  function onAfterRequest([response, content]) {
    if (typeof content === 'object') {
      if (content.flash) {
        NoticeService.parse(content.flash)
      }

      if (content.redirect_to) {
        window.location.href = content.redirect_to
      }

      if (content.open) {
        let link = document.createElement('a')
        link.href = content.open
        link.target = '_blank'
        link.click()
      }

      if (content.reload) {
        window.location.reload()
      }

      if (content.errors) {
        ValidationErrors.build(content.errors)
      }
    }

    return [response, content]
  }

  function run() {
    $.ajaxSetup({
      beforeSend: onSendRequest
    })

    $(document)
      .ajaxError(onErrorRequest)
      .ajaxSuccess(onSuccessRequest)

    Http.subscribe('beforeRequest', onBeforeRequest)
    Http.subscribe('afterRequest', onAfterRequest)
  }

  return { run }
})()

export default HttpInterceptor
