export function pushToCollection(collection, item) {
    if (!collection)
        collection = buildBlankCollection();
    let { items, maxPosition } = collection;
    let { position } = item;
    if (!items[item.id]) {
        maxPosition += 1;
        position = maxPosition;
    }
    item.position = position;
    items[item.id] = item;
    return { ...collection, items, maxPosition };
}
export function prependToCollection(collection, item, force = false) {
    if (!collection)
        collection = buildBlankCollection();
    let { items, minPosition } = collection;
    let { position } = item;
    if (force || !items[item.id]) {
        minPosition -= 1;
        position = minPosition;
    }
    item.position = position;
    items[item.id] = item;
    return { ...collection, items, minPosition };
}
export function buildBlankCollection() {
    return { items: {}, maxPosition: 0, page: 0, hasMore: true, minPosition: 0 };
}
export function updateCollection(collection, newItems, itemBuilder = item => item) {
    if (!collection)
        collection = buildBlankCollection();
    const prevMaxPosition = collection.maxPosition;
    collection = newItems.reduce((collection, item) => {
        return pushToCollection(collection, itemBuilder(item));
    }, collection);
    let { page, hasMore } = collection;
    if (prevMaxPosition < collection.maxPosition)
        page += 1;
    if (newItems.length === 0)
        hasMore = false;
    return { ...collection, page, hasMore };
}
