var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            const { ArticlePollWidget } = await import(
            /* webpackChunkName: 'common__c-ArticlePoll' */
            // @ts-ignore
            'site/widgets/ArticlePollWidget');
            ArticlePollWidget($(this.el), {
                props: {
                    title: this.props.title,
                    options: this.props.options,
                    isVoted: this.props.isVoted
                },
                callbacks: {
                    CALLBACK_VOTE: (option) => {
                        return Http.post(`/front_api/polls/${this.props.id}/vote/${option.id}`);
                    }
                }
            });
        };
    }
};
default_1 = __decorate([
    register('ArticlePoll')
], default_1);
export default default_1;
