var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import HttpRequestBatcher from 'core/services/HttpRequestBatcher';
import Http from 'core/services/Http';
import waitPageLoaded from 'core/utils/waitPageLoaded';
import { map } from 'nanostores';
export const countersStore = map({
    viewsCount: 0,
    commentsCount: 0,
    likesCount: 0
});
export async function fetchCountersBatcher(requests) {
    const objects = requests.map(({ id, type }) => ({ id, type }));
    const result = await Http.post('/front_api/counters/fetch.json', {
        data: { objects }
    });
    countersStore.set(result);
    return (request) => result[`${request.type}_${request.id}`];
}
let default_1 = class extends Controller {
    init() {
        waitPageLoaded()
            .then(() => HttpRequestBatcher.request(fetchCountersBatcher, {
            id: this.props.objectId,
            type: this.props.objectType
        }))
            .then(({ views_count: viewsCount, comments_count: commentsCount, likes_count: likesCount } = {}) => {
            const viewsCountEl = this.findElement('%{Counters/viewsCount}');
            const commentsCountEl = this.findElement('%{Counters/commentsCount}');
            const likesCountEl = this.findElement('%{Counters/likesCount}');
            if (viewsCountEl)
                viewsCountEl.innerText = this.numberWithSpaces(viewsCount);
            if (commentsCountEl)
                commentsCountEl.innerText = this.numberWithSpaces(commentsCount);
            if (likesCountEl)
                likesCountEl.innerText = this.numberWithSpaces(likesCount);
        });
    }
    numberWithSpaces(x) {
        return (x === null || x === void 0 ? void 0 : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')) || '0';
    }
};
default_1 = __decorate([
    register('Counters')
], default_1);
export default default_1;
