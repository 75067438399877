import { ContentType } from '../constants';
import { getAddresses } from './KladrApi/api/addresses';
import { getSuggestions } from './DaDataApi/api/suggestions';
import { getCities } from '../api/cities';
import { DEFAULT_COUNTRY_ID } from 'site/global/constants';
export const INLINE_ADDRESS_ID_REGEXP = new RegExp(`^${ContentType.City}/(\\d+):?(${ContentType.Building}/(.+))?$`);
export const PART_SUBSTITUTIONS = {
    'край': 'край',
    'проезд': 'проезд',
    'пр-кт': 'пр-кт',
    'корпус': 'корпус',
    'б-р': 'б-р',
    'линия': 'линия',
    'литера': 'литера',
    'Респ': 'респ.',
    'АО': 'АО',
    'мкр.': 'мкр.'
};
const CITY_REGEXP = /^\s*г?\.?\s*[А-Яа-я\-]+\s*$/;
const NAME_SUBSTITUTIONS = {
    'Саха /Якутия/': 'Саха (Якутия)'
};
export function fetchAddresses(params) {
    return new Promise(async (resolve, reject) => {
        var _a, _b;
        if (!params.query)
            return resolve({ data: [] });
        const searchQuery = params.query.replace('/', ' ');
        let items = [];
        try {
            if (searchQuery.match(CITY_REGEXP)) {
                const { data } = await getCities({ query: searchQuery, countryId: DEFAULT_COUNTRY_ID });
                items = data;
            }
            if (items.length === 0) {
                const { data } = await getAddresses(searchQuery);
                items = buildData(data.result);
            }
        }
        catch (error) {
            (_a = window.bugsnagClient) === null || _a === void 0 ? void 0 : _a.notify(new Error(`[Kladr response error] - ${JSON.stringify(error)}`));
        }
        // we should stub requests like jarvis/spec/support/kladr.rb
        if (Env.name !== 'test' && isQuerySuitable(searchQuery)) {
            try {
                const { data } = await getSuggestions(searchQuery);
                const daDataItems = buildDaData(data === null || data === void 0 ? void 0 : data.suggestions);
                if (daDataItems.length > 0) {
                    // TODO code removes whole block of suggestions
                    // if we want to remove duplicates in suggestions
                    // we should check children in daDataItem
                    // check if child exists
                    // find correct item and push child to item.children
                    // const existsMap = items.reduce((acc, el) => {
                    //   if (el.id) acc[el.id] = true
                    //   return acc
                    // }, {} as { [key: string]: Nullable<boolean> })
                    daDataItems.forEach(item => {
                        // if (!(item.id && existsMap[item.id])) items.push(item)
                        items.push(item);
                    });
                }
            }
            catch (error) {
                reject();
                (_b = window.bugsnagClient) === null || _b === void 0 ? void 0 : _b.notify(new Error(`[Kladr response error] - ${JSON.stringify(error)}`));
            }
        }
        resolve({ data: groupByParent(items) });
    });
}
// to decrease requests amount to DaData
function isQuerySuitable(query) {
    if (query.length < 5)
        return false;
    const chars = [...query];
    const groupChars = chars.reduce((acc, char) => { var _a; return ({ ...acc, [char]: ((_a = acc[char]) !== null && _a !== void 0 ? _a : 0) + 1 }); }, {});
    // percent of uniq characters must be greater than or equal 30 %
    return (Object.keys(groupChars).length / query.length) >= 0.3;
}
function joinParts(parts) {
    return parts.reduce((acc, [type, name]) => {
        var _a;
        if (!(name || type))
            return acc;
        const humanType = type ? `${(_a = PART_SUBSTITUTIONS[type]) !== null && _a !== void 0 ? _a : `${type}.`} ` : '';
        const humanName = name ? NAME_SUBSTITUTIONS[name.toString()] || name : '';
        acc += `${acc ? ', ' : ''}${humanType}${humanName}`;
        return acc;
    }, '');
}
function groupByParent(items) {
    const mapping = items.reduce((result, item) => {
        var _a, _b, _c;
        if (!result[item.text])
            return { ...result, [item.text]: item };
        const children = (_b = (_a = result[item.text]) === null || _a === void 0 ? void 0 : _a.children) !== null && _b !== void 0 ? _b : [];
        if (children.every(({ id }) => { var _a; return id !== ((_a = item.children) === null || _a === void 0 ? void 0 : _a[0].id); })) {
            children.push(...((_c = item.children) !== null && _c !== void 0 ? _c : []));
        }
        return { ...result, [item.text]: { ...item, children } };
    }, {});
    return Object.values(mapping);
}
function buildDaData(suggestions) {
    if (!suggestions)
        return [];
    return suggestions.reduce((acc, { data }) => {
        const { cityKladrId, postalCode, settlementKladrId, settlementType } = data;
        let { houseKladrId, houseFiasId } = data;
        let fakePart = undefined;
        if (!houseKladrId && data.house) {
            houseKladrId = data.kladrId;
            houseFiasId = data.fiasId;
            fakePart = data.house;
        }
        const idSettlementValid = settlementType !== 'р-н';
        const zipParts = [[undefined, postalCode]];
        const cityParts = [
            data.regionKladrId !== data.cityKladrId ? [data.regionType, data.region] : [undefined, undefined],
            [data.cityType, data.city],
            idSettlementValid ? [settlementType, data.settlement] : [undefined, undefined]
        ];
        const block = joinParts([[data.blockTypeFull, data.block]]);
        const parts = [
            [data.streetType, data.street],
            [data.houseType, block ? `${data.house} ${block}` : data.house]
        ];
        const idParts = [
            [ContentType.City, settlementKladrId && idSettlementValid ? settlementKladrId : cityKladrId],
            [ContentType.Building, [houseKladrId, houseFiasId, postalCode, fakePart].filter(item => !!item).join(':')]
        ];
        const text = joinParts(parts);
        const id = idParts.reduce((acc, [type, id]) => id ? `${acc}${acc ? ':' : ''}${type}/${id}` : acc, '');
        let children = [];
        if (text) {
            children.push({
                id,
                text,
                searchText: joinParts([...cityParts, ...parts]),
                action: data.house ? 'change' : 'improve'
            });
        }
        acc.push({
            id: cityKladrId,
            text: joinParts([...zipParts, ...cityParts]),
            searchText: joinParts(cityParts),
            action: 'improve',
            isInfo: children.length > 0,
            children
        });
        return acc;
    }, []);
}
function buildData(items) {
    if (!items)
        return [];
    return items.map(buildItem);
}
function buildItem(item) {
    var _a;
    let zip;
    let lastIds = []; // for remove duplication from self-parent city-region
    let region;
    let city;
    let street;
    let building;
    const items = [...((_a = item.parents) !== null && _a !== void 0 ? _a : []), item];
    items.forEach(obj => {
        zip = obj.zip || zip;
        switch (obj.contentType) {
            case ContentType.Building:
                building = obj;
                break;
            case ContentType.City:
                city = obj;
                break;
            case ContentType.Region:
                region = obj;
                break;
            case ContentType.Street:
                street = obj;
                break;
        }
        if (lastIds.includes(obj.id))
            return;
        lastIds.push(obj.id);
    });
    const zipParts = [[undefined, zip]];
    const cityParts = [
        region && (region === null || region === void 0 ? void 0 : region.id) !== (city === null || city === void 0 ? void 0 : city.id) ? [region.typeShort, region.name] : [undefined, undefined],
        [city === null || city === void 0 ? void 0 : city.typeShort, city === null || city === void 0 ? void 0 : city.name]
    ];
    const parts = [[street === null || street === void 0 ? void 0 : street.typeShort, street === null || street === void 0 ? void 0 : street.name], [building === null || building === void 0 ? void 0 : building.typeShort, building === null || building === void 0 ? void 0 : building.name]];
    const id = [city, building].reduce((acc, item) => {
        if (!item)
            return acc;
        return acc + `${acc ? ':' : ''}${item.contentType}/${item.id}`;
    }, '');
    const children = [];
    const text = joinParts(parts);
    if (text) {
        children.push({
            id: `${id}:${building ? `${building.guid}:${zip}` : ''}`,
            text,
            searchText: joinParts([...cityParts, ...parts]),
            action: building ? 'change' : 'improve'
        });
    }
    return {
        children,
        id: city === null || city === void 0 ? void 0 : city.id,
        text: joinParts([...zipParts, ...cityParts]),
        searchText: joinParts(cityParts),
        action: 'improve',
        isInfo: children.length > 0
    };
}
