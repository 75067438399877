import { h, Component } from 'preact';
import WhichCompany from './WhichCompany';
export default class Specialties extends Component {
    constructor() {
        super(...arguments);
        this.whichCompany = () => {
            return this.props.specialtyType === 'UserProfession'
                ? h(WhichCompany, { workPlace: this.props.workPlace })
                : h("div", null);
        };
        this.specialtyName = () => {
            if (this.props.specialtyType == 'UserProfession') {
                return 'user[user_profession_id]';
            }
            else if (this.props.specialtyType == 'CompanyType') {
                return 'user[company_type_id]';
            }
        };
    }
    render() {
        return (h("div", { className: 'b-ProfiCategoriesForm_block __specialties pb-Group' },
            h("div", { className: 'pb-Group_title' }, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F"),
            h("div", { className: 'g-Row' },
                h("div", { className: 'g-Col__6' },
                    h("div", { className: 'ui-Input __select __text __labelFloated' },
                        h("label", { className: 'ui-Input_label' }, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F"),
                        h("select", { className: 'ui-Input_input', name: this.specialtyName(), onChange: this.props.onSelect }, this.props.specialties && this.props.specialties.map(([id, name]) => (h("option", { value: id, selected: id === this.props.currentSpecialtyId }, name)))))),
                this.whichCompany())));
    }
}
