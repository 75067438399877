import { h } from 'preact';
import { Layout } from '../../../components/Layout';
import Http from 'core/services/HttpClient';
import { connect, disconnect } from 'core/services/Controller';
let refEl = null;
const handleRef = async (el) => {
    if (!el) {
        if (refEl)
            disconnect(refEl);
        refEl = null;
        return;
    }
    refEl = el;
    const { data } = await Http.get('/front_ssi/important_news');
    el.innerHTML = data;
    connect(el);
};
export const Modal = (props) => {
    return (h("div", { className: 's-Checkout __fullScreen' },
        h("div", { ref: handleRef }),
        h(Layout, Object.assign({}, props))));
};
