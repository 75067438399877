import { h, Component } from 'preact';
import { FormNoticeService } from 'core/services/FormNoticeService';
import htmlClassNames from 'core/utils/htmlClassNames';
import Tooltip from 'core/components/UI/Tooltip';
export class Text extends Component {
    constructor() {
        super(...arguments);
        this.componentDidMount = () => {
            this.handleChangeValue(this.props.value);
        };
        this.handleChangeValue = (value) => {
            requestAnimationFrame(() => {
                if (value || this.props.isLabelFixed) {
                    this.setState({ isLabelFloated: true });
                    return;
                }
                this.setState({ isLabelFloated: false });
            });
        };
        this.handleFocusInput = () => {
            this.setState({ isLabelFloated: true });
        };
        this.handleBlurInput = () => {
            if (this.props.value || this.props.isLabelFixed)
                return;
            this.setState({ isLabelFloated: false });
        };
    }
    get uniqueId() {
        return `input-${Math.random().toString(36).substr(2, 9)}`;
    }
    get classes() {
        const { isLabelFloated } = this.state;
        const { className, color, sizeMod, notice, isOverflowText = true, isChangeProtected } = this.props;
        return htmlClassNames('ui-Input', className, {
            '__text': isOverflowText,
            [`__size_${sizeMod}`]: !!sizeMod,
            [`__color_${color}`]: !!color,
            [`__${notice === null || notice === void 0 ? void 0 : notice.type}`]: !!notice,
            '__labelFloated': isLabelFloated,
            '__changeProtected': isChangeProtected
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value)
            this.handleChangeValue(nextProps.value);
    }
    render() {
        const { label, required = false, class: _class, tag: Tag = 'input', inputRef, sizeMod: _sizeMove, notice, onKeyDown, onInputChangeClick, isChangeProtected, tooltip, id = this.uniqueId, ...inputProps } = this.props;
        return (h("div", { className: this.classes },
            notice && (h("div", { className: 'ui-Input_notice' }, notice.message)),
            h("label", { className: 'ui-Input_label', for: id },
                label,
                required && (h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*"))),
            h(Tag, Object.assign({}, inputProps, { id: id, required: required, ref: inputRef, className: 'ui-Input_input', onFocus: this.handleFocusInput, onBlur: this.handleBlurInput, onKeyDown: onKeyDown })),
            isChangeProtected && (h("button", { className: 'ui-Input_changeInputButton', type: 'button', onClick: onInputChangeClick }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C")),
            tooltip && (h("div", { className: 'ui-Input_tooltip' },
                "?",
                h(Tooltip, { label: tooltip })))));
    }
}
export const buildError = (field) => {
    const { errors } = FormNoticeService.notices;
    return errors[field] ? {
        type: 'error',
        message: errors[field].join(', ')
    } : undefined;
};
