import { Component } from 'preact';
import h from './cssScope';
import { withStyleLoading } from 'core/components/Hoc/withStyleLoading';
import { createStore, initialState } from './store';
import { links } from './pages/links';
import { PageName } from './types';
class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            ...initialState,
            currentPage: this.props.isDeliveryDateAutoUpdated ? PageName.AutoDeliveryDate : PageName.DeliveryDate,
            deliveryDates: this.props.deliveryDates,
            isDeliveryDateAutoUpdated: this.props.isDeliveryDateAutoUpdated,
            autoDeliveryDate: this.props.autoDeliveryDate,
            orderId: this.props.orderId,
            saveUrl: this.props.saveUrl,
            clientName: this.props.clientName,
            inmyroomPhone: this.props.inmyroomPhone,
            token: this.props.token
        };
    }
    getChildContext() {
        return {
            store: createStore((currentState) => { this.setState(currentState); }, this.state)
        };
    }
    render() {
        const { currentPage } = this.state;
        const { component: Page, nextPage, prevPage } = links[currentPage];
        return (h(Page, { nextPage: nextPage, prevPage: prevPage }));
    }
}
export default withStyleLoading(App, 'DeliveryCalendar');
