var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { index } from 'core/utils/DOMHelper';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.stars = this.findElement('%{Rating/stars}');
        this.handleStarClick = (e) => {
            var _a;
            const value = index(e.currentTarget) + 1;
            (_a = this.stars) === null || _a === void 0 ? void 0 : _a.setAttribute('data-count', value.toString());
            const inputEl = this.findElement('%{Rating/input}');
            if (inputEl)
                inputEl.value = value.toString();
        };
    }
    init() {
        [...Array(5)].forEach(() => {
            var _a, _b;
            const itemEl = document.createElement('div');
            itemEl.classList.add('b-StarRating_item', 'js-ratingStar');
            (_a = this.stars) === null || _a === void 0 ? void 0 : _a.insertBefore(itemEl, (_b = this.stars) === null || _b === void 0 ? void 0 : _b.firstChild);
            if (this.props.isEditable)
                itemEl.addEventListener('click', this.handleStarClick);
        });
    }
};
default_1 = __decorate([
    register('Rating')
], default_1);
export default default_1;
