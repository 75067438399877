var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.initUploader = () => {
            Promise.all([
                import(
                /* webpackChunkName: 'legacy__fileupload' */
                // @ts-ignore
                'core/vendor/jquery-ui/core'),
                import(
                /* webpackChunkName: 'legacy__fileupload' */
                // @ts-ignore
                'core/vendor/jquery-ui/widget'),
                import(
                /* webpackChunkName: 'legacy__fileupload' */
                // @ts-ignore
                'core/vendor/jquery.fileupload')
            ]).then(() => {
                let filesUploaded = 0;
                const selectCollectionFlagEl = this.findElement('%{UploadPhotoToCollection/selectCollectionFlag}');
                const selectCollectionInputEl = this.findElement('%{UploadPhotoToCollection/selectCollectionInput}');
                $(selectCollectionInputEl).on('select2-focus', () => {
                    selectCollectionFlagEl.checked = true;
                });
                const createCollectionFlagEl = this.findElement('%{UploadPhotoToCollection/createCollectionFlag}');
                const createCollectionInputEl = this.findElement('%{UploadPhotoToCollection/createCollectionInput}');
                $(createCollectionInputEl).on('focus', () => {
                    createCollectionFlagEl.checked = true;
                });
                const addEl = this.findElement('%{UploadPhotoToCollection/add}');
                const formEl = this.findElement('%{UploadPhotoToCollection/form}');
                console.log(selectCollectionFlagEl, selectCollectionInputEl, createCollectionFlagEl, createCollectionInputEl, addEl, formEl);
                //@ts-ignore
                $(addEl).fileupload({
                    dataType: 'json',
                    limitConcurrentUploads: 1,
                    add(_e, data) {
                        data.submit();
                    },
                    start() {
                        $('#preloader').show();
                    },
                    stop() {
                        filesUploaded = 0;
                        $('#preloader').hide();
                    },
                    submit(_e, data) {
                        data.formData = $(formEl).serializeArray();
                    },
                    done(_e, data) {
                        if (++filesUploaded == data.originalFiles.length) {
                            window.location.href = data.response().result.photoable_url;
                        }
                    }
                });
            });
        };
    }
    init() {
        this.initUploader();
        super.init();
    }
};
default_1 = __decorate([
    register('UploadPhotoToCollection')
], default_1);
export default default_1;
