import { Component } from 'preact';
import h from '../../cssScope';
import { Button } from '../../components/Button';
import { DeliveryDate as Item } from '../../components/DeliveryDate';
import { COMMIT_SET_PAGE } from '../../store/commits/page';
import { COMMIT_SET_LOADING } from '../../store/commits/loading';
import { COMMIT_SET_CLIENT_REFUSED } from '../../store/commits/clientRefused';
import { OPERATION_COMPLETE } from '../../store/operations/complete';
import { PageName } from '../../types';
export class DeliveryDate extends Component {
    constructor() {
        super(...arguments);
        this.state = { shownCount: 3 };
        this.handleNextPage = () => {
            this.context.store.commit(COMMIT_SET_PAGE, this.props.nextPage);
        };
        this.handleComplete = async () => {
            this.context.store.commit(COMMIT_SET_CLIENT_REFUSED, true);
            await this.context.store.operate(OPERATION_COMPLETE)
                .then(() => {
                this.context.store.commit(COMMIT_SET_PAGE, PageName.WaitContact);
                this.context.store.commit(COMMIT_SET_LOADING, false);
            })
                .catch(() => {
                this.context.store.commit(COMMIT_SET_LOADING, false);
            });
        };
        this.handleShowMore = (shownCount) => () => {
            this.setState({ shownCount });
        };
    }
    render() {
        const { deliveryDates, chosenDate, chosenPeriod, isLoading } = this.context.store.getState();
        const { shownCount } = this.state;
        return (h("div", { className: 'sb-Page' },
            h("h1", { className: 'sb-Title' },
                "\u041A\u043E\u0433\u0434\u0430 \u0432\u0430\u043C \u0443\u0434\u043E\u0431\u043D\u043E",
                h("br", null),
                "\u043F\u0440\u0438\u043D\u044F\u0442\u044C \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0443?"),
            h("ul", { className: 'sb-DeliveryDateList' }, deliveryDates
                .filter((_, index) => index < shownCount)
                .map((props, index) => (h("li", { key: index },
                h(Item, Object.assign({}, props, { isDateActive: (chosenDate === null || chosenDate === void 0 ? void 0 : chosenDate.date) === props.date })))))),
            deliveryDates.length > shownCount ? (h(Button, { onClick: this.handleShowMore(deliveryDates.length), mod: 'secondary' }, "\u041C\u043D\u0435 \u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043F\u043E\u0434\u0445\u043E\u0434\u0438\u0442")) : (h(Button, { onClick: this.handleComplete, mod: 'secondary' }, "\u041F\u043E\u0434\u0431\u043E\u0440 \u0434\u0430\u0442\u044B \u0441 \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u043E\u043C")),
            h("div", { className: 'sb-Actions' },
                h(Button, { onClick: this.handleNextPage, disabled: !chosenDate || !chosenPeriod || isLoading }, "\u0414\u0430\u043B\u0435\u0435"))));
    }
}
