import { h, Component } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
import { ActionButton } from 'core/components/UI/ActionButton';
import { hashCodeFromString } from 'core/utils/string';
export class FrameModal extends Component {
    constructor() {
        super(...arguments);
        this.handleOutsideClick = (e) => {
            var _a;
            const target = e.target;
            // The target can be removed from DOM after clicking if there is conditional rendering
            if (!document.contains(target) || ((_a = this.contentRef) === null || _a === void 0 ? void 0 : _a.contains(target)))
                return true;
            this.props.onClose();
        };
        this.handleContentRef = (el) => {
            var _a, _b;
            this.contentRef = el;
            (_b = (_a = this.props).onContentRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        };
        this.titleId = () => {
            const { title } = this.props;
            return `b-FrameModal_title${title && hashCodeFromString(title.toString())}`;
        };
    }
    componentDidMount() {
        document.body.classList.add('has-modal-open');
        requestAnimationFrame(() => { document.addEventListener('click', this.handleOutsideClick); });
    }
    componentWillUnmount() {
        document.body.classList.remove('has-modal-open');
        document.removeEventListener('click', this.handleOutsideClick);
    }
    get actionsClassNames() {
        return htmlClassNames('b-FrameModal_actions', { '__twoActions': this.props.rejectProps && this.props.nextProps });
    }
    render() {
        const { title, children, contentClassName, bodyClassName, tag: Tag = 'div', rejectProps, nextProps, onClose } = this.props;
        return (h("div", { className: 'b-FrameModal' },
            h("div", { className: 'b-FrameModal_backDrop' }),
            h("div", { className: 'b-FrameModal_wrapper' },
                h(Tag, { className: htmlClassNames('b-FrameModal_content', contentClassName), ref: this.handleContentRef, role: 'dialog', "aria-modal": 'true', "aria-labelledby": this.titleId() },
                    h("button", { className: 'b-FrameModal_close', onClick: onClose }),
                    title && h("div", { className: 'b-FrameModal_title', id: this.titleId() }, title),
                    h("div", { className: htmlClassNames('b-FrameModal_body', bodyClassName) }, children),
                    (rejectProps || nextProps) && (h("div", { className: this.actionsClassNames },
                        rejectProps && h(ActionButton, Object.assign({}, rejectProps)),
                        nextProps && h(ActionButton, Object.assign({}, nextProps))))))));
    }
}
