import { createVDOMRenderer } from 'core/services/VDOM';
export class VirtualController {
    constructor(el, props) {
        this.el = el;
        this.props = props;
        this.renderer = createVDOMRenderer(el, () => this.render());
    }
    static shouldInit(_element, _props) { return true; }
    render() { }
    init() {
        this.renderer.render();
    }
    destroy() {
        this.renderer.destroy();
    }
}
