var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { SHOW_PREVIEW_EVENT, HIDE_PREVIEW_EVENT, buildEventName } from 'site/VControllers/Products/ProductVariants';
let ProductPreview = class ProductPreview extends Controller {
    constructor() {
        super(...arguments);
        this.anotherProductPreviewEl = this.el.querySelector('[data-el="ProductPreview/anotherProductPreview"]');
        this.handleShowPreview = ({ detail }) => {
            this.anotherProductPreviewEl.style.backgroundImage = `url(${detail.imagePreview})`;
            this.anotherProductPreviewEl.classList.add('__shown');
        };
        this.handleHidePreview = () => {
            this.anotherProductPreviewEl.classList.remove('__shown');
            this.anotherProductPreviewEl.style.backgroundImage = '';
        };
    }
    get nativeListeners() {
        return [
            {
                selector: '[data-el="ProductPreview/variants"]',
                event: this.buildEventName(SHOW_PREVIEW_EVENT),
                handler: this.handleShowPreview
            },
            {
                selector: '[data-el="ProductPreview/variants"]',
                event: this.buildEventName(HIDE_PREVIEW_EVENT),
                handler: this.handleHidePreview
            }
        ];
    }
    buildEventName(event) {
        return buildEventName(event, this.props.viewType);
    }
};
ProductPreview = __decorate([
    register('ProductPreview')
], ProductPreview);
export { ProductPreview };
