import { Component, h } from 'preact';
import Tooltip from 'core/components/UI/Tooltip';
import { baseAutocomplete } from 'site/VControllers/Autocomplete/components/Hoc/baseAutocomplete';
import { SELF_PARENT_IDS } from 'site/VControllers/SinglePageCheckout/constants';
import { fetchCityItems } from 'site/VControllers/SinglePageCheckout/services/fetchCityItems';
import { FieldsKeys } from '../../..';
const CityAutocomplete = baseAutocomplete('/front_api/autocomplete/cities', 'Город', null, {
    extraQueryParams: (component) => ({ countryId: component.props.countryId })
});
export class City extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            // It is storing here because creating ref is async,
            // but we need to rerender autocomplete after creating it
            inputRef: undefined
        };
        this.handleChange = (item, prevItem) => {
            if (item.id === prevItem.id)
                return;
            this.props.onChange(item.id);
        };
        this.handleCreatingInputRef = (el) => {
            this.setState({ inputRef: el });
        };
        this.handleSearchInputRef = (el) => {
            var _a, _b;
            (_b = (_a = this.props).onRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        };
    }
    get isCountryBlank() {
        const countryField = this.props.formFields.find(field => field.key === FieldsKeys.Country);
        if (!countryField)
            return false;
        return !countryField.value;
    }
    render() {
        const { field, name, disabled, useHidden = false, ...autocompleteProps } = this.props;
        const { inputRef } = this.state;
        return (h("div", { className: 'ui-Input __autocomplete __text __select' },
            useHidden && h("input", { ref: this.handleCreatingInputRef, name: name, type: 'text', value: field.value }),
            (!useHidden || inputRef) && (h(CityAutocomplete, Object.assign({ isRequired: field.isRequired, inputEl: inputRef, disabled: disabled || this.isCountryBlank, value: field.value, label: field.label }, autocompleteProps, { items: field.items, countryId: field.countryId, onChange: this.handleChange, isSearchInput: true, withOnShowLoad: !!field.countryId, fetchItems: fetchCityItems, 
                // skip select region from tree
                preventChange: preventChange, onInputRef: this.handleSearchInputRef }))),
            this.isCountryBlank && h(Tooltip, { label: '\u0421\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443' })));
    }
}
function preventChange({ id }) {
    id = (id === null || id === void 0 ? void 0 : id.toString()) || '';
    if (SELF_PARENT_IDS.includes(id))
        return false;
    return /^\d{3}0{10}$/.test(id);
}
