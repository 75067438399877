var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register, connect } from 'core/services/Controller';
// @ts-ignore
import KeyboardService from 'core/services/KeyboardService';
import Http from 'core/services/Http';
import waitPageLoaded from 'core/utils/waitPageLoaded';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.shouldInit = () => {
            var _a;
            return Env.isLogged && ((_a = Env.user) === null || _a === void 0 ? void 0 : _a.ability.showDevPanel);
        };
        this.init = () => {
            super.init();
            KeyboardService.subscribe(['Shift', '+'], this.toggle);
            waitPageLoaded().then(this.onLoad);
        };
        this.destroy = () => {
            super.destroy();
            KeyboardService.unsubsribe(this.toggle);
        };
        this.toggle = () => {
            this.el.classList.toggle('is-opened');
            document.body.classList.toggle('has-modal-open');
        };
        this.goToTab = (e) => {
            const tabName = e.currentTarget.dataset.tabGo;
            const tabs = this.findElements('[data-tab-name], [data-tab-go]');
            tabs.forEach(el => {
                el.classList.remove('is-active');
            });
            const currentTabs = this.findElements(`[data-tab-name=${tabName}], [data-tab-go=${tabName}]`);
            currentTabs.forEach(el => {
                el.classList.add('is-active');
            });
        };
        this.onLoad = async () => {
            const content = await Http.get('/front_api/dev_panel');
            const contentContainer = this.findElement('%{c-DevPanel/content}');
            if (contentContainer) {
                contentContainer.innerHTML = content;
                connect(contentContainer);
            }
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-DevPanel/toggle}', event: 'click', handler: this.toggle },
            { selector: '[data-tab-go]', event: 'click', handler: this.goToTab }
        ];
    }
};
default_1 = __decorate([
    register('DevPanel')
], default_1);
export default default_1;
