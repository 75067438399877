var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.serializeField = (name) => {
            const field = this.findElement(`%{c-RequestServiceForm/${name}}`);
            return field.value;
        };
        this.onSubmit = (e) => {
            e.preventDefault();
            const data = {
                name: this.serializeField('name'),
                phone: this.serializeField('phone'),
                email: this.serializeField('email'),
                title: this.serializeField('title'),
                city_id: this.serializeField('city_id'),
                description: this.serializeField('description')
            };
            Http
                .post('/front_api/profi/requests', {
                data
            });
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-RequestServiceForm/submitBtn}', event: 'click', handler: this.onSubmit }
        ];
    }
};
default_1 = __decorate([
    register('RequestServiceForm')
], default_1);
export default default_1;
