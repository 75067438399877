import { h } from 'preact';
import htmlClassNames from 'core/utils/htmlClassNames';
export var SelectTypes;
(function (SelectTypes) {
    SelectTypes["checkbox"] = "checkbox";
    SelectTypes["radio"] = "radio";
})(SelectTypes || (SelectTypes = {}));
export const SelectInput = ({ label, title, onChange, onClick, id, name, checked, className, icon, type = SelectTypes.checkbox }) => {
    const classes = {
        root: htmlClassNames(['ui-Input', '__check', className], { '__checked': checked, '__icon': !!icon }),
        inputWrapper: htmlClassNames([type, 'ui-Input_wrapper'])
    };
    return (h("label", { className: classes.root, onClick: onClick },
        icon && h("div", { className: 'ui-Input_icon', style: { backgroundImage: `url(${icon})` } }),
        h("span", { className: 'ui-Input_label', title: title }, label),
        h("span", { className: classes.inputWrapper },
            h("input", { id: id, name: name, checked: checked, className: 'ui-Input_input', type: type, onChange: onChange }),
            h("span", { class: 'ui-Input_ui-item' }))));
};
