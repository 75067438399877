//Переписать все на новую модалку, а эту грохнуть
class Modal {
  static initClass() {
    this.prototype.defaults = {
      clone: false, // true | 'deep'
      vitalize: false,
      show: false,
      effect: 'fade',
      type: 'simple'
    }

    this.prototype.templates = {
      simple: `
        <div class="c-modal modal" tabindex="-1" data-role="modal">
            <div class="c-modal_backdrop modal-backdrop" data-role="modal-overlay"></div>
            <div class="c-modal_dialog modal-dialog">
              <div class="c-modal_body modal-body">
                <div class="c-modal_header modal-header">
                  <div class="c-modal_close modal-close" data-role="modal-close">
                  </div>
                  <div class="c-modal_title modal-title" data-role="modal-title"></div>
                </div>
                <div class="c-modal_content modal-content" data-role="modal-content"></div>
              </div>
            </div>
        </div>
      `
    }
  }

  constructor(options) {
    this.settings = $.extend({}, this.defaults, options)
    this.init(options)
    this.bind()
  }

  init(_options) {
    this.view = $(this.templates[this.settings.type])

    if (this.settings.id) {
      this.view.attr('id', this.settings.id)
    }

    if (this.settings.classes) {
      this.view.addClass(this.settings.classes)
    }

    if (this.settings.title) {
      this.view.find('[data-role="modal-title"]').append(this.settings.title)
    }

    // build content
    if (this.settings.content) {
      if (typeof this.settings.content === 'string') {
        this.settings.content = $(this.settings.content)
      }
      let { content } = this.settings

      // clone strategy
      if (this.settings.clone) {
        if (this.settings.clone === true) {
          content = content.clone()
        }
        if (this.settings.clone === 'deep') {
          content = content.clone(true)
        }
      }

      if (this.settings.show) {
        content.show()
      }

      this.view.find('[data-role="modal-content"]').append(content)
    }

    this.view.appendTo($(document.body))

    return Component.connect(this.view)
  }

  bind() {
    this.view.on('click', '[data-role="modal-close"]', this.close.bind(this))

    if (this.settings.type === 'simple') {
      this.view.on('click', '[data-role="modal-overlay"]', this.close.bind(this))
    }

    $(document.body).on('modal.close', () => {
      return this.close()
    })

    return $(document).on('keyup.modal', e => {
      if (e.which === 27) {
        return this.close()
      }
    })
  }

  show() {
    $(document.body).addClass('has-modal-open')

    $(window).scrollTop(pageYOffset - 0.000001) // For fix safari bug version 9.0.1

    return this.view.addClass('modal-open')
  }

  close() {
    $('body, html').removeClass('has-modal-open modal-full-page')
    this.view.removeClass('modal-open')
    this.view.remove()

    $(document).off('keyup.modal')
    return $(document.body).off('modal.close')
  }
}
Modal.initClass()

window.Modal = Modal

export { Modal }
export default Modal
