import htmlClassNames from 'core/utils/htmlClassNames';
import { Component } from 'preact';
import h from '../../cssScope';
import { Highlighted } from '../Highlighted';
export class Item extends Component {
    constructor() {
        super(...arguments);
        this.handleClick = (event) => {
            event.stopPropagation();
            const { onChildClick, onClick, item } = this.props;
            this.isChildrenPresent ? onClick(item) : onChildClick(item);
        };
    }
    get text() {
        const { getLabel, item } = this.props;
        return getLabel(item);
    }
    get isChildrenPresent() {
        const children = this.props.item.children;
        return children && children.length > 0;
    }
    render() {
        const { inputValue, extraContent, item, root = false, isSelected = () => false } = this.props;
        return (h("li", { className: htmlClassNames('sb-Item', { '__root': root }) },
            h("div", { className: htmlClassNames('sb-Item_element', { '__selected': isSelected(item), '__info': item.isInfo }), onMouseDown: this.handleClick },
                h(Highlighted, { inputValue: inputValue, text: this.text })), extraContent === null || extraContent === void 0 ? void 0 :
            extraContent(item),
            this.isChildrenPresent && (h("ul", { className: 'sb-List' }, item.children.map((item, i) => h(Item, Object.assign({ key: i }, this.props, { item: item, root: false })))))));
    }
}
