var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import cond from 'core/utils/cond';
import delay from 'core/utils/delay';
import scrollWindowTo from 'core/utils/scrollWindowTo';
import isElementInViewport from 'core/utils/isElementInViewport';
import { formatAmount } from 'site/VControllers/components/Checkout/services/humanize';
const getTopOffset = (el) => el.getBoundingClientRect().top + +document.body.scrollTop;
let default_1 = class extends Controller {
    async init() {
        const { ProductList, UrlPlugin, ItemsPlugin, FiltersPlugin, LoadMorePlugin, PaginatorPlugin, CategoryTeasersPlugin } = await import(
        /* webpackChunkName: 'common__ProductList' */
        'core/services/InfinityList/lists/ProductList');
        const paginatorEl = this.findElement('%{w-Paginator}');
        const categoryTeasersEl = this.findElements('%{w-CategoryTeasers}');
        const itemsEl = this.findElement('%{ProductList/items}');
        const filterChoiceListEl = this.findElement('%{ProductList/filterChoiceList}');
        const filters = this.findElements('[data-filter]').map(el => ({
            ...JSON.parse(el.dataset.filter), $slot: $(el)
        }));
        this.productList = ProductList({
            loadUrl: this.props.loadUrl,
            initialState: {
                url: document.location.href,
                items: this.props.items,
                filters: this.props.filters,
                loadMore: this.props.loadMore,
                paginator: this.props.paginator,
                hitProducts: this.props.hitProducts,
                loadCountUrl: this.props.loadCountUrl,
                categoryTeasers: this.props.categoryTeasers
            },
            plugins: [
                UrlPlugin(),
                ItemsPlugin({
                    getSlotsForItems(items) {
                        return [{ $slot: $(itemsEl), items }];
                    }
                }),
                this.props.loadMore && LoadMorePlugin({
                    $slot: $(this.el),
                    autoLoad: this.props.autoLoad,
                    disableLoadAnimation: true
                }),
                PaginatorPlugin({
                    $slot: $(paginatorEl),
                    maxPagesRangeSize: 7
                }),
                categoryTeasersEl.length > 0 && CategoryTeasersPlugin({
                    $slot: $(categoryTeasersEl)
                }),
                filters.length > 0 && FiltersPlugin({
                    filters,
                    loadCountUrl: this.props.hasCountLoad || false,
                    isModalOpened: this.props.isModalOpened || false,
                    syncFields: this.props.syncFields,
                    choiceList: filterChoiceListEl ? { slotEl: filterChoiceListEl } : null
                })
            ],
            callbacks: {
                onScrollToTop: (position) => {
                    return new Promise(resolve => requestAnimationFrame(async () => {
                        if (isElementInViewport(this.el))
                            return resolve();
                        const point = cond(position === 'top', _to => getTopOffset(this.el) - 10, position === 'items', _to => {
                            if (itemsEl)
                                return getTopOffset(itemsEl) - 100;
                            return getTopOffset(this.el) - 10;
                        });
                        await scrollWindowTo(point, 600);
                        await delay(200);
                        resolve();
                    }));
                },
                onBeforeLoad: () => { },
                onAfterLoad: ({ meta: { titleText = '', countText = '', pageTitle = '', removeSeoTitle = false } = {} }) => {
                    var _a;
                    const titleEl = this.findElement('%{ProductList/title}');
                    const countEl = this.findElement('%{ProductList/count}');
                    if (removeSeoTitle)
                        (_a = this.findElement('%{ProductList/seoNote}')) === null || _a === void 0 ? void 0 : _a.remove();
                    if (titleEl)
                        titleEl.innerText = titleText;
                    if (countEl)
                        countEl.innerText = formatAmount(parseInt(countText));
                    if (pageTitle)
                        document.title = pageTitle;
                }
            }
        });
        this.productList.connect();
    }
    destroy() {
        this.productList = (this.productList.connect(), null);
    }
};
default_1 = __decorate([
    register('ProductList')
], default_1);
export default default_1;
