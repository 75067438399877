import Tooltip from 'core/components/UI/Tooltip';
import htmlClassNames from 'core/utils/htmlClassNames';
import { Component } from 'preact';
import { GtmAddressEvents } from 'site/VControllers/components/Checkout/constants';
import { OPERATION_CHANGE_GTM_ADDRESS_EVENT } from 'site/VControllers/components/Checkout/operations/gtm';
import { PART_SUBSTITUTIONS } from 'site/VControllers/SinglePageCheckout/services/fetchAddresses';
import { FieldsKeys } from '../../..';
import h from '../../../cssScope';
import { AddressSuggestions } from 'luxury_theme.mobile/components/AddressSuggestions';
import { ContentType } from 'site/VControllers/SinglePageCheckout/constants';
import { COMMIT_REMOVE_NOTICES, COMMIT_SET_NOTICES } from 'site/VControllers/components/Checkout/commits/notices';
export class InlineAddress extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            inputRef: undefined
        };
        this.awaitCityId = null;
        this.awaitImproveAddress = false;
        this.handleInputRef = (el) => {
            this.setState({ inputRef: el });
        };
        // to allow commit input address
        this.handleChangeSearch = (event) => {
            var _a, _b, _c, _d;
            const value = event.target.value;
            if (!!value)
                (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: 'Укажите улицу и номер дома' } });
            this.awaitImproveAddress = true;
            (_d = (_c = this.props).onChangeValue) === null || _d === void 0 ? void 0 : _d.call(_c, FieldsKeys.Address, value);
        };
        this.handleSuggestionSelect = (suggestion) => {
            var _a, _b, _c, _d, _e, _f;
            if (!suggestion) {
                return;
            }
            (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.commit(COMMIT_REMOVE_NOTICES, FieldsKeys.InlineAddress);
            (_d = (_c = this.context) === null || _c === void 0 ? void 0 : _c.store) === null || _d === void 0 ? void 0 : _d.operate(OPERATION_CHANGE_GTM_ADDRESS_EVENT, GtmAddressEvents.AutocompleteAddress);
            const itemId = buildDaData(suggestion);
            this.awaitCityId = null;
            this.props.onChange(itemId);
            (_f = (_e = this.props).onChangeValue) === null || _f === void 0 ? void 0 : _f.call(_e, FieldsKeys.Address, suggestion.value);
        };
        this.handleFetchSuggestions = (suggestions) => {
            var _a, _b;
            if (suggestions.length)
                return;
            (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: 'Укажите улицу и номер дома' } });
        };
    }
    field(key) {
        return this.props.formFields.find(v => v.key === key);
    }
    get isCountryBlank() {
        const countryField = this.field(FieldsKeys.Country);
        if (!countryField)
            return false;
        return !countryField.value;
    }
    get isSettlementBlank() {
        const settlementField = this.field(FieldsKeys.Settlement);
        if (!settlementField)
            return false;
        return !settlementField.value;
    }
    get currentSavedAddressText() {
        const item = currentItem(this.props.field);
        if (!item)
            return undefined;
        return item.text;
    }
    get currentSavedFullAddressText() {
        const item = currentItem(this.props.field);
        if (!item)
            return undefined;
        return item.searchText;
    }
    render() {
        var _a, _b, _c;
        const { field, name, disabled, useHidden } = this.props;
        const { inputRef } = this.state;
        const isMobile = Env.version === 'mobile';
        const notice = (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.getState().notices[FieldsKeys.InlineAddress];
        const countryField = this.field(FieldsKeys.Country);
        const countryText = (countryField === null || countryField === void 0 ? void 0 : countryField.value) ? (_c = (countryField.items.find((v) => v.id === countryField.value))) === null || _c === void 0 ? void 0 : _c.text : null;
        const settlementField = this.field(FieldsKeys.Settlement);
        return (h("div", { className: 'ab-test' },
            this.currentSavedAddressText ? (h("p", { className: 'sb-Row_itemHint' },
                "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u0430\u0434\u0440\u0435\u0441: ",
                h("strong", null, this.currentSavedFullAddressText))) : (h("p", { className: 'sb-Row_itemHint' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0430\u0434\u0440\u0435\u0441 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435 \u0438\u043B\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0439\u0442\u0435 \u043F\u043E\u0438\u0441\u043A")),
            h("div", { className: htmlClassNames('ui-Input', { '__text': !isMobile, [`__${notice === null || notice === void 0 ? void 0 : notice.type}`]: !!notice }) },
                useHidden && h("input", { ref: this.handleInputRef, name: name, type: 'text', value: field.value }),
                Env.daData.token && (!useHidden || inputRef) && (h(AddressSuggestions, { value: this.currentSavedAddressText ? { value: this.currentSavedAddressText } : undefined, token: Env.daData.token, containerClassName: 'ui-Input_autocomplete __autocompleteFixed __labelFloated', suggestionsClassName: 's-AutocompleteTree ui-Input_items __fixedList __open', suggestionClassName: 's-AutocompleteTree_b-Item __root __ab-test', currentSuggestionClassName: '__selected', highlightClassName: 's-AutocompleteTree_b-Highlighted', count: 6, customInput: isMobile ? 'textarea' : 'input', delay: 400, minChars: 1, httpCache: true, onChange: (suggestion) => this.handleSuggestionSelect(suggestion), filterFromBound: 'street', filterToBound: 'house', filterLocations: countryText && [{ country: countryText }], labelText: 'Улица, дом', selectOnBlur: field.value ? false : true, inputProps: {
                        disabled: disabled || this.isCountryBlank || this.isSettlementBlank,
                        onChange: (input) => this.handleChangeSearch(input)
                    }, defaultQuery: this.currentSavedAddressText, settlementQuery: settlementField === null || settlementField === void 0 ? void 0 : settlementField.value.toString(), onFetchSuggestions: (suggestions) => this.handleFetchSuggestions(suggestions) })),
                this.isCountryBlank && h(Tooltip, { label: '\u0421\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443' }),
                notice && h(Tooltip, { label: notice.message, className: notice.type === 'error' ? '__error' : undefined }))));
    }
}
function currentItem(field) {
    if (!field.value)
        return null;
    let currentItem = null;
    const recurse = (items) => {
        items.forEach(item => {
            if (item.id === field.value) {
                currentItem = item;
                return;
            }
            if (item.children) {
                recurse(item.children);
            }
        });
    };
    recurse(field.items);
    return currentItem;
}
const NAME_SUBSTITUTIONS = {
    'Саха /Якутия/': 'Саха (Якутия)'
};
function joinParts(parts) {
    return parts.reduce((acc, [type, name]) => {
        var _a;
        if (!(name || type))
            return acc;
        const humanType = type ? `${(_a = PART_SUBSTITUTIONS[type]) !== null && _a !== void 0 ? _a : `${type}.`} ` : '';
        const humanName = name ? NAME_SUBSTITUTIONS[name.toString()] || name : '';
        acc += `${acc ? ', ' : ''}${humanType}${humanName}`;
        return acc;
    }, '');
}
function buildDaData(suggestion) {
    if (!suggestion)
        return null;
    const data = suggestion.data;
    const { cityKladrId, postalCode, settlementKladrId, settlementType } = data;
    let { houseKladrId, houseFiasId } = data;
    let fakePart = undefined;
    if (!houseKladrId && data.house) {
        houseKladrId = data.kladrId;
        houseFiasId = data.fiasId;
        fakePart = data.house;
    }
    const idSettlementValid = settlementType !== 'р-н';
    const block = joinParts([[data.blockTypeFull, data.block]]);
    const parts = [
        [data.streetType, data.street],
        [data.houseType, block ? `${data.house} ${block}` : data.house]
    ];
    const idParts = [
        [ContentType.City, settlementKladrId && idSettlementValid ? settlementKladrId : cityKladrId],
        [ContentType.Building, [houseKladrId, houseFiasId, postalCode, fakePart].filter(item => !!item).join(':')]
    ];
    const text = joinParts(parts);
    const id = idParts.reduce((acc, [type, id]) => id ? `${acc}${acc ? ':' : ''}${type}/${id}` : acc, '');
    if (text) {
        return id;
    }
    else {
        return cityKladrId;
    }
}
