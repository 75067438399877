const FREE_ID = 'Free';
function removeFreeRecord(response) {
    var _a;
    if (Env.kladr.token)
        return response;
    const result = (_a = response.data) === null || _a === void 0 ? void 0 : _a.result;
    if (!Array.isArray(result))
        return response;
    return {
        ...response,
        data: { ...response.data, result: (response.data.result || []).filter(({ id }) => id !== FREE_ID) }
    };
}
export default [removeFreeRecord];
