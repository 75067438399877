var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { VisibilityObserver } from 'core/services/VisibilityObserver';
let default_1 = class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.visibilityObserver = new VisibilityObserver();
        this.firstVisible = false;
        this._bannerRefreshAvailable = false;
        this.handleVisible = () => {
            if (this.bannerRefreshAvailable && this.firstVisible) {
                googletag.pubads().refresh([this.googleBannerSlot]);
            }
            this.firstVisible = true;
        };
        this.handleUnvisible = () => {
            if (this.firstVisible && this.googleTagAvailable) {
                googletag.pubads().clear([this.googleBannerSlot]);
            }
        };
    }
    init() {
        this.visibilityObserver.observe(this.el, {
            onVisible: this.handleVisible,
            onUnvisible: this.handleUnvisible
        });
    }
    get googleTagAvailable() {
        return !!(googletag && googletag.pubads);
    }
    get bannerRefreshAvailable() {
        if (this._bannerRefreshAvailable)
            return true;
        if (this.googleTagAvailable && gptadslots[this.props.bannerSlot]) {
            this.googleBannerSlot = gptadslots[this.props.bannerSlot];
            return this._bannerRefreshAvailable = true;
        }
        return false;
    }
    destroy() {
        this.visibilityObserver.unobserve(this.el);
    }
};
default_1.shouldInit = () => !!window.googletag && !!window.gptadslots;
default_1 = __decorate([
    register('RotatableBanner')
], default_1);
export default default_1;
