var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import cond from 'core/utils/cond';
import delay from 'core/utils/delay';
import scrollWindowTo from 'core/utils/scrollWindowTo';
import isElementInViewport from 'core/utils/isElementInViewport';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            var _a;
            await this.itemListServiceInit();
            (_a = this.itemList) === null || _a === void 0 ? void 0 : _a.connect();
        };
        this.destroy = () => {
            var _a;
            (_a = this.itemList) === null || _a === void 0 ? void 0 : _a.disconnect();
            this.itemList = null;
        };
        this.itemListServiceInit = async () => {
            const { ItemList, UrlPlugin, ItemsPlugin, PaginatorPlugin } = await import(
            /* webpackChunkName: 'common__c-PaginatedItemList' */
            // @ts-ignore
            'core/services/InfinityList/lists/ItemList');
            const $items = $(this.el).find('%{c-PaginatedItemList/items}');
            const $paginator = $(this.el).find('%{w-Paginator}');
            this.itemList = ItemList({
                loadUrl: this.props.loadUrl,
                initialState: {
                    url: document.location.href,
                    items: this.props.items,
                    paginator: this.props.paginator
                },
                plugins: [
                    UrlPlugin(),
                    ItemsPlugin({
                        getSlotsForItems(items) {
                            return [{ $slot: $items, items }];
                        }
                    }),
                    PaginatorPlugin({
                        $slot: $paginator,
                        maxPagesRangeSize: 7
                    })
                ],
                callbacks: {
                    onScrollToTop: (position) => {
                        return new Promise(resolve => requestAnimationFrame(() => {
                            if (isElementInViewport(this.el))
                                return resolve();
                            const point = cond(position === 'top', () => $(this.el).offset().top - 10, position === 'items', () => $items.offset().top - 220);
                            scrollWindowTo(point, 700)
                                .then(() => delay(200))
                                .then(resolve);
                        }));
                    },
                    onBeforeLoad: () => {
                        this.el.classList.add('is-loading');
                    },
                    onAfterLoad: () => {
                        this.el.classList.remove('is-loading');
                    }
                }
            });
        };
    }
};
default_1 = __decorate([
    register('PaginatedItemList')
], default_1);
export default default_1;
