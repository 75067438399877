import NoticeService from 'core/services/NoticeService';
const ValidationErrors = {
    build(errors) {
        Object.entries(errors).forEach(([field, messages]) => {
            messages.forEach(message => {
                if (field === 'base') {
                    NoticeService.error(message);
                    return;
                }
                NoticeService.error(message, field);
            });
        });
    }
};
export default ValidationErrors;
