var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import Http from 'core/services/Http';
import { createVDOMRenderer, h } from 'core/services/VDOM';
import Store from 'core/services/Store';
// @ts-ignore
import FormAutocomplete from '../VDOMComponents/FormAutocomplete';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.input = this.findElement('%{c-CityAutocomplete/input}');
        this.init = () => {
            this.renderer.render(this.store.getState());
        };
        this.destroy = () => {
            this.renderer.destroy();
        };
        this.store = Store({
            props: {
                initialState: {
                    options: [],
                    choices: this.props.cities
                }
            },
            callbacks: {
                CALLBACK_AFTER_COMMIT: state => {
                    this.renderer.render(state);
                    this.input.value = state.choices.map(({ id }) => id).join(',');
                }
            }
        });
        this.onQueryCities = (query) => {
            return Http.get('/cities', {
                params: {
                    term: query
                }
            });
        };
        this.onAddChoice = (choice) => {
            this.store.commit(state => {
                return { ...state,
                    choices: [...state.choices, choice]
                };
            });
        };
        this.onRemoveChoice = (choice) => {
            this.store.commit(state => {
                return { ...state,
                    choices: state.choices.filter(({ id }) => choice.id !== id)
                };
            });
        };
        this.renderer = createVDOMRenderer(this.el, (state) => (h(FormAutocomplete, { title: this.props.title, placeholder: this.props.placeholder, multiple: this.props.multiple, choices: state.choices, onQuery: this.onQueryCities, onAddChoice: this.onAddChoice, onRemoveChoice: this.onRemoveChoice })));
    }
};
default_1 = __decorate([
    register('CityAutocomplete')
], default_1);
export default default_1;
