var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { documentDispatch } from 'core/utils/events';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleClick = (e) => {
            const target = e.target;
            switch (target === null || target === void 0 ? void 0 : target.dataset.el) {
                case 'c-Accordion/item/toggle':
                    this.onToggleItem(target);
                    break;
            }
        };
        this.onToggleItem = (target) => {
            if (this.props.single) {
                this.findElements('%{c-Accordion/item}').forEach((item) => {
                    item.classList.remove('is-opened');
                    item.classList.remove('__isOpened');
                });
            }
            const item = this.findClosest('%{c-Accordion/item}', target);
            item === null || item === void 0 ? void 0 : item.classList.toggle('is-opened');
            item === null || item === void 0 ? void 0 : item.classList.toggle('__isOpened');
            const dispatchEvent = target === null || target === void 0 ? void 0 : target.dataset.dispatchEvent;
            if (dispatchEvent) {
                documentDispatch(dispatchEvent);
            }
        };
    }
    get nativeListeners() {
        return [
            { event: 'click', handler: this.handleClick }
        ];
    }
};
default_1 = __decorate([
    register('Accordion')
], default_1);
export default default_1;
