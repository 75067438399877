import { Component } from 'preact';
import h, { ScopeName } from '../../cssScope';
export class Highlighted extends Component {
    render() {
        const { inputValue, text } = this.props;
        const html = getHtmlString(inputValue, text);
        if (!html)
            return h("span", null, text);
        return (h("span", { dangerouslySetInnerHTML: { __html: html } }));
    }
}
const makePattern = (word) => new RegExp(`${word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'i');
function getHtmlString(inputValue, text) {
    const words = inputValue
        .replace(/[,()]/g, '')
        .split(' ');
    let res = text;
    words.forEach((word) => {
        if (word === '')
            return;
        const query = makePattern(word).exec(text);
        if (!query)
            return;
        res = res
            .replace(new RegExp(query[0], 'g'), `<strong class='s-${ScopeName}_b-Highlighted'>${query[0]}</strong>`);
    });
    return res;
}
