/**
 * function for validate phone number
 * @param phone - phone number in format (+x (xxx) xx-xx-xx | xxxxxxxxxxx)
 */
export function validatePhone(phone) {
    if (phone && /^\+7\s*?\(?(7|8)+\d*/.test(phone)) {
        return false;
    }
    if (phone && /^\+375\s*?\(?(29|33|44|25|17)+\d*/.test(phone)) {
        return false;
    }
    if (phone && /^\+7\s*?\(?(6[0-9]{2}|7[0-9]{2})\)\s?[0-9]{7}/.test(phone)) {
        return false;
    }
    return true;
}
/**
 * function deletes 7 or 8 if the phone number starts with 78 or 77
 * @param phone - phone number in format (+x (xxx) xx-xx-xx | xxxxxxxxxxx)
 * @returns new number without 7 or 8 if it falls under the conditions
 */
export function formatPhone(phone) {
    if (!validatePhone(phone))
        return phone.replace(/8/g, '');
    return phone;
}
