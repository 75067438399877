var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h } from 'preact';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { VController, register } from 'core/services/Controller';
const Details = asyncComponent(() => import(/* webpackChunkName: "common_c-HeaderInfoDetails" */ './components/Details'), 'Details');
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.handleShowPreview = () => {
            this.el.classList.add('__preview');
        };
        this.handleHidePreview = () => {
            this.el.classList.remove('__preview');
        };
    }
    render() {
        return (h(Details, { viewType: this.props.viewType, onShowPreview: this.handleShowPreview, onHidePreview: this.handleHidePreview }));
    }
};
default_1 = __decorate([
    register('HeaderInfo')
], default_1);
export default default_1;
