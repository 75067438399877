var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.collapsibleItems = [...this.findElements('%{CollapsableCategoriesPreviews/item}.__hidden')];
        this.control = this.findElement('%{CollapsableCategoriesPreviews/control}');
        this.handleControlClick = () => {
            this.collapsibleItems.forEach((item) => {
                item.classList.remove('__hidden');
                item.classList.add('__shown');
            });
            if (!this.control)
                return;
            this.control.removeEventListener('click', this.handleControlClick);
            this.control.remove();
            this.control = null;
        };
    }
    init() {
        super.init();
        if (this.control)
            this.control.addEventListener('click', this.handleControlClick);
    }
    destroy() {
        if (this.control)
            this.control.removeEventListener('click', this.handleControlClick);
    }
};
default_1 = __decorate([
    register('CollapsableCategoriesPreviews')
], default_1);
export default default_1;
