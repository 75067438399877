import { parseSelector } from 'core/utils/DOMHelper';
/**
 * @deprecated
 */
export class EventManager {
    constructor(el) {
        this.el = el;
        this.events = [];
    }
    bindEvent(rawEvent, handler) {
        const event = this.parseEvent(rawEvent, handler);
        this.events.push(event);
        if (event.selector) {
            // http://youmightnotneedjquery.com/#delegate
            event.target.addEventListener(event.name, function (e) {
                for (let target = e.target; target && target !== event.target; target = target.parentElement) {
                    if (target.matches(event.selector)) {
                        event.handler.call(target, e);
                        break;
                    }
                }
            }, false);
        }
        else {
            event.target.addEventListener(event.name, event.handler);
        }
    }
    unbindEvents() {
        this.events.forEach((event) => {
            event.target.removeEventListener(event.name, event.handler);
        });
        this.events = [];
    }
    parseEvent(rawEvent, handler) {
        const [name, selector] = rawEvent.split(' on ');
        if (selector === 'document') {
            return { target: document, name, handler };
        }
        if (selector === 'window') {
            return { target: window, name, handler };
        }
        return {
            target: this.el,
            selector: selector ? parseSelector(selector) : selector,
            name,
            handler
        };
    }
}
