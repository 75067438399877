import { DeliveryDate } from './DeliveryDate';
import { AutoDeliveryDate } from './AutoDeliveryDate';
import { Comment } from './Comment';
import { ThankYou } from './ThankYou';
import { WaitContact } from './WaitContact';
import { PageName } from '../types';
export const links = {
    DeliveryDate: {
        component: DeliveryDate,
        nextPage: PageName.Comment
    },
    AutoDeliveryDate: {
        component: AutoDeliveryDate
    },
    Comment: {
        component: Comment,
        nextPage: PageName.ThankYou,
        prevPage: PageName.DeliveryDate
    },
    ThankYou: {
        component: ThankYou
    },
    WaitContact: {
        component: WaitContact
    }
};
