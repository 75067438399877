var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleDelete = () => {
            this.el.setAttribute('src', '');
        };
        this.handleUpload = ({ detail: { url } }) => {
            this.el.setAttribute('src', url);
        };
    }
    init() {
        document.addEventListener('avatarUploader.upload', this.handleUpload);
        document.addEventListener('avatarUploader.delete', this.handleDelete);
    }
};
default_1 = __decorate([
    register('Avatar')
], default_1);
export default default_1;
