import formatNumber from 'core/utils/number/format';
export function formatPrice(price, unit = 'Р') {
    return formatNumber(price, { unit });
}
export function truncateString(payload, size, continueSymbols = '...') {
    if (payload.length <= size + continueSymbols.length)
        return payload;
    return payload.substr(0, size) + continueSymbols;
}
export function formatAmount(amount) {
    return [amount, buildNumberPostfix(amount)].join(' ');
}
function buildNumberPostfix(amount, [form1, form2, form3] = ['товар', 'товара', 'товаров']) {
    const rest1 = Math.abs(amount) % 100;
    const rest2 = rest1 % 10;
    if (rest1 > 10 && rest1 < 20)
        return form3;
    if (rest2 > 1 && rest2 < 5)
        return form2;
    if (rest2 === 1)
        return form1;
    return form3;
}
