var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleToggle = () => {
            this.el.classList.toggle('__isOpened');
        };
        this.handleSave = (e) => {
            e.preventDefault();
            const link = document.createElement('a');
            link.setAttribute('href', this.props.originalUrl);
            link.setAttribute('download', '');
            link.click();
        };
    }
    get nativeListeners() {
        return [
            { selector: '%{c-PhotoModalInfo/toggle}', event: 'click', handler: this.handleToggle },
            { selector: '%{c-PhotoModalInfo/save}', event: 'click', handler: this.handleSave }
        ];
    }
};
default_1 = __decorate([
    register('PhotoModalInfo')
], default_1);
export default default_1;
