import { h } from 'preact'

let cache = {}

export const getScopedClass = (scopeName, className) => className
  .replace(/ScopeRoot/, `s-${scopeName}`)
  .replace(/sb-(\w+)/g, `s-${scopeName}_b-$1`)

export const createScope = scopeName => (tagName, options, ...content) => {
  if (options) {
    options.class = options.className || options.class

    if (options.class) options.class = getScopedClass(scopeName, options.class)
  }

  return h(tagName, options, ...content)
}

export function cssScope(scopeName, callback) {
  return callback(
    cache[scopeName] || (cache[scopeName] = createScope(scopeName))
  )
}
