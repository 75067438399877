import { h, Component } from 'preact';
import highlightWords from 'highlight-words';
export class HighlightWords extends Component {
    render() {
        const { text, words, highlightClassName, tagName = 'span' } = this.props;
        const query = typeof words === 'string' ? words : words.join(' ');
        const chunks = highlightWords({ text, query });
        return (h("span", { "aria-label": text }, chunks.map((chunk) => {
            if (!chunk.match) {
                return h("span", { key: chunk.key, "aria-hidden": true }, chunk.text);
            }
            const Component = tagName;
            return (h(Component, { key: chunk.key, className: highlightClassName, "aria-hidden": true }, chunk.text));
        })));
    }
}
