var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
import { ScrollDirectionWatcher } from 'core/services/ScrollDirectionWatcher';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleScrollTop = (e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
        this.handleShowFilter = () => {
            $(document).trigger('FloatActions:showFilter');
        };
        this.initScrollWatcher = () => {
            this._scrollWatcher = new ScrollDirectionWatcher({
                onScrollDown: this.showFloatActions,
                onScrollUp: this.hideFloatActions
            });
        };
        this.hideFloatActions = () => {
            this.el.classList.remove('__isActive');
        };
        this.showFloatActions = () => {
            this.el.classList.add('__isActive');
        };
    }
    init() {
        this.initScrollWatcher();
        this
            .bindEvent('click on %{FloatActions/toTop}', this.handleScrollTop)
            .bindEvent('click on %{FloatActions/filter}', this.handleShowFilter);
    }
    destroy() {
        this._scrollWatcher.destroy();
    }
};
default_1 = __decorate([
    register('FloatActions')
], default_1);
export default default_1;
