import { h, Component } from 'preact';
import { Text as UIText } from 'core/components/UI/Inputs/Text/Text';
export class Password extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (e) => {
            const target = e.target;
            this.props.onChange(target.value);
        };
    }
    render() {
        const { field: { key, value = '', label, required, tip, isLabelFixed = false }, type = 'password' } = this.props;
        return (h("div", null,
            h(UIText, { name: key, label: label, value: value, required: required, type: type, onChange: this.handleChange, tooltip: tip, isLabelFixed: isLabelFixed })));
    }
}
