import { h, Component } from 'preact';
import IMask from 'imask';
import { Text } from './Text';
import { formatPhone } from 'core/utils/formatPhone';
import { Countries, DEFAULT_COUNTRY_ID } from 'site/global/constants';
import { COUNTRY_ID_UPDATED_EVENT } from 'core/utils/events';
const MASK_MAPPING = {
    [Countries.Ru]: '+{7} 000 000 00 00',
    [Countries.By]: '+{375} 000 000 00 00',
    [Countries.Kz]: '+{7} 000 000 00 00'
};
export class Phone extends Component {
    constructor() {
        super(...arguments);
        this.countryId = DEFAULT_COUNTRY_ID;
        this.handleChange = () => {
            var _a, _b, _c;
            const { value, onChangeValue } = this.props;
            if (this.inputRef && this.maskRef) {
                this.maskRef.value = this.countryId === Countries.Ru ? formatPhone((_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.value) : (_b = this.inputRef) === null || _b === void 0 ? void 0 : _b.value;
            }
            const currentValue = ((_c = this.maskRef) === null || _c === void 0 ? void 0 : _c.unmaskedValue) || value;
            onChangeValue(currentValue);
        };
        this.handleRef = (el) => {
            this.inputRef = el;
            this.handlePropsUpdate();
        };
    }
    componentWillUnmount() {
        var _a;
        (_a = this.maskRef) === null || _a === void 0 ? void 0 : _a.destroy();
        this.maskRef = undefined;
    }
    componentDidMount() {
        document.addEventListener(COUNTRY_ID_UPDATED_EVENT, (e) => {
            const detail = e.detail;
            this.countryId = detail.id;
            this.handlePropsUpdate();
        });
    }
    componentDidUpdate() {
        this.handlePropsUpdate();
    }
    handlePropsUpdate() {
        var _a;
        if (this.inputRef) {
            (_a = this.maskRef) === null || _a === void 0 ? void 0 : _a.destroy();
            this.inputRef.value = this.props.value;
            const mask = MASK_MAPPING[this.countryId];
            this.maskRef = IMask(this.inputRef, { mask });
        }
    }
    render() {
        var _a;
        const { value, onChangeValue: _onChangeValue, ...props } = this.props;
        return (h(Text, Object.assign({ inputRef: this.handleRef, onInput: this.handleChange, isLabelFixed: Boolean(value || ((_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.value)) }, props)));
    }
}
