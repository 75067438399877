var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.init = async () => {
            var _a;
            await this.userListServiceInit();
            (_a = this.userList) === null || _a === void 0 ? void 0 : _a.connect();
        };
        this.destroy = () => {
            var _a;
            (_a = this.userList) === null || _a === void 0 ? void 0 : _a.disconnect();
            this.userList = null;
        };
        this.userListServiceInit = async () => {
            const { UserList, UrlPlugin, ItemsPlugin, FiltersPlugin, PaginatorPlugin } = await import(
            /* webpackChunkName: 'common__c-UserList' */
            // @ts-ignore
            'core/services/InfinityList/lists/UserList');
            const $paginator = $(this.el).find('%{w-Paginator}');
            const $items = $(this.el).find('%{c-UserList/items}');
            const filters = $(this.el).find('[data-filter]').get().map(el => ({
                ...$(el).data('filter'), $slot: $(el)
            }));
            this.userList = UserList({
                loadUrl: this.props.loadUrl,
                initialState: {
                    url: document.location.href,
                    items: this.props.items,
                    filters: this.props.filters,
                    paginator: this.props.paginator
                },
                plugins: [
                    UrlPlugin(),
                    ItemsPlugin({
                        getSlotsForItems(items) {
                            return [{ $slot: $items, items }];
                        }
                    }),
                    PaginatorPlugin({
                        $slot: $paginator,
                        maxPagesRangeSize: 7
                    }),
                    FiltersPlugin({
                        filters
                    })
                ],
                callbacks: {
                    onScrollToTop: () => {
                        this.el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    },
                    onBeforeLoad: () => {
                        this.el.classList.add('is-loading');
                    },
                    onAfterLoad: ({ meta: { titleText } }) => {
                        this.el.classList.remove('is-loading');
                        const userListTitle = this.findElement('%{c-UserList/title}');
                        if (userListTitle) {
                            userListTitle.textContent = titleText;
                        }
                    }
                }
            });
        };
    }
};
default_1 = __decorate([
    register('UserList')
], default_1);
export default default_1;
