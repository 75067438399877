var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// @ts-ignore
import FavoriteListService from 'core/services/FavoriteListService';
import { Controller, register } from 'core/services/Controller';
let default_1 = class extends Controller {
    constructor() {
        super(...arguments);
        this.handleAdded = () => {
            this.itemsCount += 1;
        };
        this.handleRemoved = () => {
            this.itemsCount -= 1;
        };
    }
    static shouldInit() {
        return Env.isLogged;
    }
    init() {
        var _a;
        this.itemsCount = ((_a = Env.user) === null || _a === void 0 ? void 0 : _a.favoriteItemsCount) || 0;
        this.el.classList.add('is-visible');
        FavoriteListService.on('add', this.handleAdded);
        FavoriteListService.on('remove', this.handleRemoved);
    }
    destroy() {
        FavoriteListService.off('add', this.handleAdded);
        FavoriteListService.off('remove', this.handleRemoved);
    }
    render() {
        this.el.setAttribute('data-items-count', this.itemsCount.toString());
    }
    set itemsCount(count) {
        this._itemsCount = count;
        this.render();
    }
    get itemsCount() {
        return this._itemsCount;
    }
};
default_1 = __decorate([
    register('FavoriteItemsCounter')
], default_1);
export default default_1;
