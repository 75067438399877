import waitPageLoaded from 'core/utils/waitPageLoaded'

waitPageLoaded().then(() => {
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productAddToCollection')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productAddToCart')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productAvailabilitySubscribe')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productChangeQuantityInCart')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productRemoveFromCart')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productTeaserClick')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productTeaserView')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productQuickView')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/postTeaserView')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/postTeaserClick')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/postScroll')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/userProfileUpdate')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/userSubscribe')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productDesignerPriceRequestSend')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/infoRequestSend')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/relatedProductsOpen')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/favoriteListAddObject')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/favoriteListRemoveObject')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/click')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productsFilter')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productsSorter')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productsTeasersPaginatorUsed')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productsTeasersPaginatorViewed')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productPreorderOpened')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productMetricLinkClick')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/productDescriptionView')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/cartView')
  import(/* webpackChunkName: 'common/bootstrap/gtmHandlers' */'core/services/GTM/handlers/checkout')
})
